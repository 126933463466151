import { desktopBreakpoint, tabletBreakpoint } from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    resultGrid: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        marginTop: 30,
        [theme.breakpoints.down(desktopBreakpoint)]: {
            alignItems: "center",
            flexDirection: "column"
        },
        [theme.breakpoints.up(desktopBreakpoint)]: {
            justifyContent: "center",
            flexDirection: "column"
        }
    },
    marginTop40: {
        marginTop: 40
    },
    dealerSearchFilterWrapper: {
        padding: "20px",
        marginTop: 20,
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.up(desktopBreakpoint)]: {
            justifyContent: "flex-start",
            padding: "0px"
        }
    },
    padding5: {
        padding: 5
    },
    resultCard: {
        width: "345px",
        padding: "16px"
    },
    blackH2: {
        color: "#2D2D2D",
        fontFamily: "Rubik",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "40px",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            textAlign: "center",
            fontSize: "20px",
            lineHeight: "26.8px"
        }
    },
    bold: {
        fontWeight: "bold"
    },
    underline: {
        width: "fit-content",
        [theme.breakpoints.up(desktopBreakpoint)]: {
            display: "inline-block",
            position: "relative",
            "&:after": {
                content: "''",
                position: "absolute",
                width: "100%",
                transform: "scaleX(0)",
                height: "2px",
                bottom: 0,
                left: 0,
                backgroundColor: "#F09057",
                transformOrigin: "bottom right",
                transition: "transform 0.25s ease-out"
            },
            "&:hover:after": {
                transform: "scaleX(1)",
                transformOrigin: "bottom left"
            }
        },
        [theme.breakpoints.down(desktopBreakpoint)]: {
            borderBottom: "2px solid #F09057"
        }
    }
}))
