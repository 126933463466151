import { tabletBreakpoint } from "../../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    wrapper: {
        position: "relative",
        display: "flex",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            width: "95%"
        }
    },
    dropdownButton: {
        display: "flex",
        gap: "8px",
        flex: "0 0 100%",
        textDecoration: "none",
        border: 0,
        backgroundColor: "transparent",
        alignItems: "center",
        cursor: "pointer",
        fontFamily: "Arial",
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.25px",
        justifyContent: "space-between",
        "&:hover .buttonRightWrapper": {
            background: "blue"
        },
        "&:disabled": {
            opacity: 0.8,
            cursor: "auto"
        },
        [theme.breakpoints.down(tabletBreakpoint)]: {
            paddingTop: "10px",
            paddingBottom: "10px",
            width: "100%"
        }
    },
    buttonLeftWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "10px"
    },
    buttonRightWrapper: {
        display: "flex",
        alignItems: "center"
    },
    iconWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "20px"
    },
    dropdown: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        position: "absolute",
        zIndex: 200,
        top: "100%",
        right: 0,
        marginTop: "6px",
        marginLeft: "-2px",
        padding: "30px",
        background: "#FFFFFF",
        width: "200px",
        border: "1px solid #D2D2D2",
        borderRadius: "6px",
        boxShadow: "0px 0px 10px 0px #2D2D2D1A",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            width: "unset",
            left: 0,
            right: 0
        }
    },
    truncatedSearchString: {
        maxWidth: "200px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "#2d2d2d"
    },
    clearButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: 0,
        width: "18px",
        height: "18px",
        padding: 0,
        borderRadius: "50%",
        backgroundColor: "transparent",
        cursor: "pointer",
        visibility: "hidden",
        "&:hover": {
            backgroundColor: "#EFEFEF"
        }
    },
    hover: {
        visibility: "visible"
    }
}))
