import useStyles from "./styles"
import NavButton from "./components/Navbutton"
import ImageDialog from "../ImageDialog/index"
import Banner from "../Products/Banner/Banner"
import { useLanguage } from "../../lib/hooks/languageHooks"
import theme from "../../theme"
import { tabletBreakpoint } from "../../lib/helper/viewportHelper"
import { strings as Localization } from "../../lib/Localization"
import React, { useEffect, useState } from "react"
import SwipeableViews from "react-swipeable-views"
import { Box, useMediaQuery } from "@mui/material"
import { NavLink } from "react-router-dom"

function SwipeableTextMobileStepper({
    product = {},
    isDialog,
    dialogIndex,
    listView, grid,
    productPage,
    onSelect,
    bannerArea,
    phasingOut,
    modTek,
    disableSwipe = false
}) {
    const classes = useStyles()
    const language = useLanguage()
    const { images, originalId } = product
    const [activeStep, setActiveStep] = useState(isDialog ? dialogIndex : 0)
    const [showImageDialog, setShowImageDialog] = useState(false)
    const isSmall = useMediaQuery(theme.breakpoints.down(tabletBreakpoint))

    useEffect(() => {
        if (!isDialog) { setActiveStep(0) }
    }, [product])

    const getImages = imgArr => {
        if (disableSwipe) return [`${product.originalId}.jpg`]

        const foundArr = imgArr ? imgArr.filter(x => {
            const regex = /^[0-9]*-([a-z]|[0-9]).[a-z]*$|^[0-9]*.[a-z]*$/
            return regex.exec(x)
        }) : []

        if (foundArr.length > 0) {
            const last = foundArr[foundArr.length - 1]
            foundArr.splice(foundArr.length - 1, 1)
            const returnArr = foundArr.sort()
            returnArr.splice(0, 0, last)

            const instructional = returnArr.filter(x => {
                const instructionRegex = /^[0-9]*-[0-9].[a-z]*$/
                return instructionRegex.exec(x)
            })

            // Put instructional stuff in the end.
            const main = returnArr.filter(x => !instructional.includes(x))
            return [...main, ...instructional.sort()]
        }
        return [`${product.originalId}.jpg`] // Return default image
    }

    const imagesReOrdered = getImages(images)
    const maxSteps = imagesReOrdered.length
    const buttonMinHeight = (!listView && !isSmall) ? 455 : "100%"

    const getSizeParam = () => {
        if (productPage) return ""
        if (modTek) return ""
        if (listView) {
            if (grid) {
                return "w450/"
            }
            return "w120/"
        }
        return ""
    }

    const getMaxHeight = () => {
        if (isDialog) return "none"
        if (productPage) return 480
        return 250
    }

    const handleNext = () => {
        if (activeStep + 1 > maxSteps - 1) {
            setActiveStep(0)
        } else {
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        }
    }

    const handleBack = () => {
        if (activeStep - 1 < 0) {
            setActiveStep(maxSteps - 1)
        } else {
            setActiveStep(prevActiveStep => prevActiveStep - 1)
        }
    }

    const handleStepChange = step => {
        setActiveStep(step)
    }

    const openImageDialog = () => {
        setShowImageDialog(true)
    }

    const closeImageDialog = () => {
        setShowImageDialog(false)
    }

    const onLinkClick = () => {
        if (productPage || !onSelect) return
        onSelect(product.id)
    }

    return (
        <Box className={classes.swipeableTextMobileStepper}>
            {!modTek && (
                <Box
                    sx={{ minWidth: bannerArea && bannerArea }}
                    className={classes.bannerBox}
                >
                    {!isDialog
                        && (
                            <>
                                {product.isNew && <Banner type="new" />}
                                {product.comingSoon && <Banner type="comingSoon" />}
                                {phasingOut && <Banner type="phasingOut" />}
                            </>
                        )}
                </Box>
            )}
            <Box
                id="swipableTextMobileStepper"
                className={classes.root}
            >
                <Box className={classes.swiperBox}>
                    {
                        (maxSteps > 1 && !modTek)
                        && (
                            <NavButton
                                onClick={handleBack}
                                minHeight={buttonMinHeight}
                                iconStyle={!listView ? { marginBottom: 14 } : null}
                                style={isDialog && { alignItems: "center" }}
                            />
                        )
                    }
                    <NavLink
                        to={`/${language}/product/${originalId}`}
                        className={classes.noDisplayLink}
                        onClick={onLinkClick}
                    >
                        {/* eslint-disable-next-line max-len */}
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                        <Box
                            style={{ cursor: !listView && !isDialog ? "zoom-in" : "pointer" }}
                            onClick={() => { if (!isDialog) openImageDialog() }}
                        >
                            <SwipeableViews
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                                containerStyle={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyItems: "center"
                                }}
                            >
                                {imagesReOrdered.map((image, index) => (
                                    <Box
                                        key={image}
                                        className={classes.imageBox}
                                    >
                                        <img
                                            className={classes.productImage}
                                            style={{ maxHeight: getMaxHeight() }}
                                            src={
                                                process.env.REACT_APP_IMAGE_URL
                                                + getSizeParam()
                                                + image
                                            }
                                            alt={`Brodit ${Localization.product}-${originalId}`}
                                            loading={index !== 0 ? "lazy" : "eager"}
                                        />
                                        {/* eslint-disable-next-line max-len */}
                                    </Box>
                                ))}
                            </SwipeableViews>
                        </Box>
                    </NavLink>
                    {
                        (maxSteps > 1 && !modTek)
                        && (
                            <NavButton
                                onClick={handleNext}
                                minHeight={buttonMinHeight}
                                right
                                iconStyle={!listView ? { marginBottom: 14 } : null}
                                style={isDialog && { alignItems: "center" }}
                            />
                        )
                    }
                </Box>
                <ImageDialog
                    open={showImageDialog}
                    index={activeStep}
                    handleClose={closeImageDialog}
                    product={product}
                />
            </Box>
        </Box>
    )
}

export default SwipeableTextMobileStepper
