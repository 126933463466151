import { extractSearchQueryFromUrlSearchParams } from "../helper/searchHelper";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useUrlQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

export const useUrlSearchQuery = urlSearchParams => useMemo(
    () => extractSearchQueryFromUrlSearchParams(urlSearchParams),
    [urlSearchParams]
)
