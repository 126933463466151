import useStyles from "../../styles"
import { Box, Skeleton } from "@mui/material"
import React from "react"

const MobileLoading = () => {
    const classes = useStyles()
    return (
        <Box className={classes.content}>
            <Box>
                <Box id="productMain" className={classes.justifyCenter}>
                    <Box id="productImageGrid" className={classes.justifyCenter}>
                        <Skeleton variant="rect" animation="wave" className={classes.bigLoading} />
                    </Box>
                </Box>
                <Box className={classes.tabRoot}>
                    <Skeleton variant="rect" animation="wave" height={580} />
                </Box>
            </Box>
        </Box>
    )
}

export default MobileLoading
