import TrackingButton from "./TrackingButton"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import getStatusText from "../functions"
import useStyles from "../styles"
import {
    Box, Paper, Typography
} from "@mui/material"
import classnames from "classnames"
import React from "react"
import { NavLink } from "react-router-dom"

const MobileOrderPaper = ({ orderData, getDateFormat, customerOrder }) => {
    const classes = useStyles()
    const language = useLanguage()
    const upperCase = true

    return (
        <Paper className={classes.root}>
            <Box className={classes.flexRow}>
                <Box className={classes.flexItem}>
                    <NavLink to={`/${language}/order/${customerOrder.OrderNumber}`} end>
                        <Typography>
                            {customerOrder.OrderNumber}
                        </Typography>
                    </NavLink>
                    <TrackingButton orderNumber={customerOrder.OrderNumber} shippingArray={customerOrder.Shipments} />
                </Box>
                <Box>
                    <Box className={classes.flexItem}>
                        <Typography className={classes.gray}>
                            {getDateFormat(customerOrder.OrderDate)}
                        </Typography>
                    </Box>

                    <Box className={classes.flexItem}>
                        <Box className={classnames(classes.flexColumn, classes.gray)}>
                            {getStatusText(customerOrder.Status, upperCase)}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className={classnames(classes.flexRow, classes.flexWrap)}>
                {orderData.map((object, index) => {
                    const header = object?.header.toUpperCase()
                    const data = object?.data.toString().toUpperCase()
                    return (
                        <Box className={classnames(classes.wrapItem)} key={index}>
                            <Typography variant="h6" className={classes.gray}>
                                {`${header}:`}

                            </Typography>
                            <Typography variant="h6">
                                {` ${data}`}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
        </Paper>
    )
}

export default MobileOrderPaper
