import { strings as Localization } from "../../../../lib/Localization"
import { useGetFilesByIdQuery } from "../../../../redux/services/broditWebApi"
import useStyles from "../styles"
import { Box, Typography } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"

export default function RelatedFiles({ id }) {
    const { data: files, isFetching } = useGetFilesByIdQuery(id)
    const relatedFilesPath = "/content/"
    const classes = useStyles()
    const hasFiles = files && files.length > 0

    return (
        <Box id="tabRelatedFiles" className={classes.column}>
            {
                isFetching
                && (
                    <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.grayText}
                    >
                        {Localization.loading}
                    </Typography>
                )
            }
            {
                !isFetching && hasFiles
                && (
                    <>
                        <Typography
                            variant="h5"
                            className={classes.grayText}
                        >
                            {Localization.pdfFiles}
                        </Typography>
                        <ul>
                            {
                                files.map(fileName => (
                                    <li>
                                        <Link
                                            className={classes.link}
                                            to={`${process.env.REACT_APP_URL}${relatedFilesPath}${fileName}`}
                                            target="_blank"
                                        >
                                            {fileName}
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </>
                )
            }
            {
                !isFetching && !hasFiles
                && (
                    <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.grayText}
                    >
                        {Localization.noRelatedFiles}
                    </Typography>
                )
            }
        </Box>
    )
}
