import DesktopProductInfo from "./DesktopProductInfo"
import LoadingView from "./LoadingView"
import MobileProductInfo from "./MobileProductInfo"
import NotFoundView from "./NotFoundView"
import Text from "./Text"
import { tabletBreakpoint } from "../../../../lib/helper/viewportHelper"
import { strings as Localization } from "../../../../lib/Localization"
import { useProduct, useProductsLoading } from "../../../../redux/hook/productHooks"
import { useIsUserAuthorized } from "../../../../redux/hook/userHooks"
import theme from "../../../../theme"
import useStyles from "../styles"
import CompatibilityInfo from "../view/CompabilityInfo"
import Instructions from "../view/Instructions"
import Materials from "../view/Materials"
import RelatedFiles from "../view/RelatedFiles"
import RelatedProducts from "../view/RelatedProducts"
import RelatedVideos from "../view/RelatedVideos"
import Specification from "../view/Specification"
import {
    useGetAltNameForProductsQuery,
    useGetFilesByIdQuery,
    useGetModTekProductsQuery,
    useGetRelatedProductsQuery,
    useGetRelatedVideosQuery,
    useGetTechnicalInformationForProductQuery
} from "../../../../redux/services/broditWebApi"
import { useLanguage } from "../../../../lib/hooks/languageHooks"
import { useParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { Box, useMediaQuery } from "@mui/material"

const ProductInfo = () => {
    const isSmall = useMediaQuery(theme.breakpoints.down(tabletBreakpoint))
    const classes = useStyles()
    const { id } = useParams()
    const product = useProduct(id)
    const [value, setValue] = useState(0)
    const loading = useProductsLoading()
    const isAuthorized = useIsUserAuthorized()
    const language = useLanguage()

    const { data: relatedProducts } = useGetRelatedProductsQuery(id)
    const { data: modTek } = useGetModTekProductsQuery(id)
    const { data: files } = useGetFilesByIdQuery(id)
    const { data: videos } = useGetRelatedVideosQuery(id)
    const { data: technicalInformation } = useGetTechnicalInformationForProductQuery(id)
    const { data: altNameList } = useGetAltNameForProductsQuery(id)

    const modTekInformation = modTek?.information

    const handleChange = (e, newValue) => {
        setValue(newValue)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        // Reset page
        setValue(0)
        window.scrollTo(0, 0)
    }, [id])

    const tabList = [
        {
            title: Localization.tabInformation,
            component: (
                <Box className={classes.column} id="tabInfo">
                    <Text
                        text={
                            technicalInformation
                                ? technicalInformation
                                    ?.find(ti => ti.languageShort === language)
                                    ?.htmlText
                                : product?.techincalInformation
                        }
                        technicalInformation
                    />
                    <Materials productId={product?.originalId} />
                </Box>
            ),
            visible: technicalInformation?.length > 0
        },
        {
            title: Localization.tabRelatedProducts,
            component: <RelatedProducts id={id} />,
            visible: relatedProducts?.length > 0
        },
        {
            title: Localization.tabSpecification,
            component: <Specification id={id} />,
            visible: true
        },
        {
            title: Localization.tabCompability,
            component: <CompatibilityInfo id={id} />,
            visible: product?.compatibleModels?.length > 0
        },
        {
            title: Localization.tabInstructions,
            component: <Instructions images={product?.images} text={product?.instruction} />,
            visible: product?.images?.length > 0 && product?.instruction?.length > 0
        },
        {
            title: Localization.tabRelatedFiles,
            component: <RelatedFiles id={id} />,
            visible: files?.length > 0
        },
        {
            title: Localization.video,
            component: <RelatedVideos id={id} data={videos} />,
            visible: videos?.length > 0
        }
    ].filter(tab => tab.visible)

    const handleShowMoreCompsClick = e => {
        e.preventDefault()
        setValue(tabList.findIndex(tab => tab.title === Localization.tabCompability))
    }

    return (
        <div className={classes.productInfoWrapper}>
            {!product && loading && <LoadingView />}
            {!product && !loading && <NotFoundView productId={id} />}
            {
                product
                && (
                    isSmall
                        ? (
                            <MobileProductInfo
                                modTek={modTek}
                                modTekId={id}
                                tabList={tabList}
                                handleChange={handleChange}
                                handleShowMoreCompsClick={handleShowMoreCompsClick}
                                value={value}
                                isAuthorized={isAuthorized}
                                classes={classes}
                                product={product}
                                modTekInformation={modTekInformation}
                                altNameList={altNameList}
                            />
                        )
                        : (
                            <DesktopProductInfo
                                modTek={modTek}
                                modTekId={id}
                                tabList={tabList}
                                handleChange={handleChange}
                                handleShowMoreCompsClick={handleShowMoreCompsClick}
                                value={value}
                                isAuthorized={isAuthorized}
                                classes={classes}
                                product={product}
                                modTekInformation={modTekInformation}
                                altNameList={altNameList}
                            />
                        )
                )
            }
        </div>
    )
}

export default ProductInfo
