import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga4"

function RouteChangeTracker() {
    const location = useLocation()

    useEffect(() => {
        ReactGA.set({ page: location.pathname + location.search })
        ReactGA.send({ hitType: "pageview", pathname: location.pathname + location.search })
    }, [location])

    return null
}

export default RouteChangeTracker
