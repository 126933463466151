import { tabletBreakpoint } from "../../../lib/helper/viewportHelper"
import { resetCartStep } from "../../../redux/cartSlice"
import { useCustomerOrder } from "../../../redux/hook/customerOrderHooks"
import { resetOrder } from "../../../redux/monitorSlice"
import theme from "../../../theme"
import MobileOrderInfo from "../../CustomerOrders/OrderInfo/MobileOrderInfo"
import OrderInfo from "../../CustomerOrders/OrderInfo/OrderInfo"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect } from "react"
import { CircularProgress, useMediaQuery } from "@mui/material"

const OrderConfirmation = () => {
    const dispatch = useDispatch()
    const cart = useSelector(state => state.cart)
    const customerOrder = useCustomerOrder(cart.order.number)
    const isSmall = useMediaQuery(theme.breakpoints.down(tabletBreakpoint))

    useEffect(() => () => {
        // Cleanup when navigating away from cart.
        dispatch(resetCartStep())
        dispatch(resetOrder())
    }, [dispatch])

    if (customerOrder === undefined) return <CircularProgress />

    return (
        isSmall
            ? <MobileOrderInfo customerOrder={customerOrder} isOrderConfirmation />
            : <OrderInfo customerOrder={customerOrder} isOrderConfirmation />
    )
}

export default OrderConfirmation
