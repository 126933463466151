import useStyles from "../styles"
import theme from "../../../theme"
import { productViewBreakpoint } from "../../../lib/helper/viewportHelper"
import {
    Box, TableCell, TableRow, useMediaQuery
} from "@mui/material"
import React from "react"

const ComparisonTableSection = ({ informations, text }) => {
    const classes = useStyles()
    const isMobile = useMediaQuery(theme.breakpoints.down(productViewBreakpoint))

    return (
        isMobile
            ? (
                <TableRow style={{ display: "flex", flexDirection: "column" }}>
                    <TableCell className={classes.tableCell}>
                        {text}
                    </TableCell>
                    <Box style={{ display: "flex", width: "100%" }}>
                        {informations.map((info, i) => (
                            <TableCell
                                key={`top-info-${i}`}
                                className={classes.informations}
                                style={{ width: "50%", textAlign: "center" }}
                            >
                                {info}
                            </TableCell>
                        ))}
                    </Box>
                </TableRow>
            )
            : (
                <TableRow className={classes.fullWidth}>
                    <TableCell className={classes.tableCell}>
                        {text}
                    </TableCell>
                    {informations.map((info, i) => (
                        <TableCell key={`bottom-info-${i}`} className={classes.informations}>
                            {info}
                        </TableCell>
                    ))}
                </TableRow>
            )
    )
}

export default ComparisonTableSection
