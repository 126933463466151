import { setShouldSyncFavorites } from "../../../redux/favoriteSlice"
import { useIsUserAuthorized, useUserAuthToken } from "../../../redux/hook/userHooks"
import { useGetFavoriteQuery, useUpdateFavoriteMutation } from "../../../redux/services/favoriteApi"
import { useDispatch, useSelector } from "react-redux"
import { useState, useEffect } from "react"

const FavoriteHandler = () => {
    const dispatch = useDispatch()
    const authToken = useUserAuthToken()
    const isAuthorized = useIsUserAuthorized()
    const favorite = useSelector(state => state.favorite)
    const [savedFavoriteTimeout, setSavedFavoriteTimeout] = useState(null)
    const saveTimeoutDuration = 1000

    const [updateFavoriteOnServer, _result] = useUpdateFavoriteMutation()
    const {
        isLoading: isLoadingFavorite
    } = useGetFavoriteQuery(
        { authToken },
        { skip: !isAuthorized }
    )

    useEffect(() => {
        if (favorite.shouldSync) {
            if (savedFavoriteTimeout) {
                clearTimeout(savedFavoriteTimeout)
                setSavedFavoriteTimeout(null)
            }

            if (isAuthorized && !isLoadingFavorite) {
                setSavedFavoriteTimeout(
                    setTimeout(
                        () => {
                            updateFavoriteOnServer({
                                authToken,
                                favorite
                            })
                        },
                        saveTimeoutDuration
                    )
                )
            }

            dispatch(setShouldSyncFavorites(false))
        }
    }, [favorite, isAuthorized, authToken, isLoadingFavorite, favorite.shouldSync])

    return null
}

export default FavoriteHandler
