import useStyles from "./styles"
import React from "react"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import PersonOutlineIcon from "@mui/icons-material/PersonOutline"
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined"
import { Avatar } from "@mui/material"

const OrderStepIcon = ({ active, completed, icon }) => {
    const classes = useStyles()
    const icons = {
        1: <ShoppingCartIcon />,
        2: <PersonOutlineIcon />,
        3: <ThumbUpOutlinedIcon />
    }

    return (
        <Avatar
            className={`${classes.root} ${active ? classes.active : ""} ${completed ? classes.completed : ""}`}
        >
            {icons[String(icon)]}
        </Avatar>
    )
}

export default OrderStepIcon
