import useStyles from "./styles"
import { strings as Localization } from "../../lib/Localization"
import { acceptCookies } from "../../redux/gdprSlice"
import useCookiesConsent from "../../redux/hook/gdprHooks"
import React from "react"
import {
    Snackbar,
    Link,
    Button,
    Typography,
    Paper,
    FormControlLabel,
    Checkbox,
    Box
} from "@mui/material"
import { useDispatch } from "react-redux"

const GDPRWarning = () => {
    const [hasReadTerms, setHasReadTerms] = React.useState(false)
    const dispatch = useDispatch()
    const hasAllowedCookies = useCookiesConsent()
    const classes = useStyles()

    const handleAccept = () => {
        dispatch(acceptCookies())
    }

    const handleCheckboxChanged = event => {
        setHasReadTerms(event.target.checked)
    }

    return (
        <Box id="GDPR">
            <Snackbar
                open={!hasAllowedCookies}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                key="bottomright"
            >
                <Paper className={classes.container}>
                    <Box>
                        <Typography id="cookiesReadMore">
                            {Localization.thisSiteUsesCookies}
                            {" "}
                            <Link
                                href={`${process.env.REACT_APP_IMAGE_URL}239791.pdf`}
                                target="_blank"
                            >
                                {Localization.readMoreInThePrivacyPolicy}
                            </Link>
                        </Typography>
                        <br />
                        <Typography className={classes.maxWidth600}>
                            {Localization.asLoggedInUserYouCanWithdraw}
                        </Typography>
                    </Box>
                    <Box className={classes.buttonBox}>
                        <FormControlLabel
                            id="acceptCookies"
                            control={(
                                <Checkbox
                                    checked={hasReadTerms}
                                    onChange={handleCheckboxChanged}
                                    name="hasReadTerms"
                                />
                            )}
                            label={Localization.IHaveReadAndAcceptTheTerms}
                        />
                        <Box className={classes.buttonContainer}>
                            <Button
                                id="acceptCookiesButton"
                                onClick={handleAccept}
                                variant="contained"
                                color={hasReadTerms ? "primary" : "secondary"}
                                size="large"
                                disabled={!hasReadTerms}
                            >
                                {Localization.iAccept}

                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Snackbar>
        </Box>
    )
}

export default GDPRWarning
