import { makeStyles } from "@mui/styles"

export default makeStyles(() => ({
    row: {
        display: "flex",
        justifyContent: "center",
        width: "80%"
    },
    fullWidth: {
        width: "100%"
    }
}))
