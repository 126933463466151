import theme from "../../theme"
import { desktopBreakpoint, mobileBreakpoint, mobileMenuBreakpoint } from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(() => ({
    main: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        [theme.breakpoints.down(desktopBreakpoint)]: {
            width: "100%"
        }
    },
    section1: {
        maxWidth: "688px",
        height: "auto",
        backgroundColor: "#FFF",
        paddingBottom: "50px",
        paddingTop: "40px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            paddingTop: "20px",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "40px"
        }
    },
    section1Wrapper: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        flex: ""
    },
    section2: {
        width: "80%",
        height: "auto",
        flexShrink: 0,
        backgroundColor: "#FFF",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingLeft: "10%",
        paddingRight: "10%",
        paddingBottom: "75px",
        gap: 50,
        [theme.breakpoints.down(mobileBreakpoint)]: {
            width: "100%",
            paddingBottom: "unset",
            paddingLeft: "unset",
            paddingRight: "unset"
        }
    },
    section3: {
        backgroundColor: "#F8F8F8",
        width: "100%",
        paddingTop: "75px",
        paddingBottom: "75px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            paddingTop: "40px",
            paddingBottom: "40px"
        }
    },
    section3Wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
        width: "80%"
    },
    whiteText: {
        color: "#FFF",
        fontFamily: "Arial",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "16px", /* 88.889% */
        letterSpacing: "0.5px"
    },
    whiteLargerText: {
        color: "#FFF",
        fontFamily: "Arial",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "24px", /* 120% */
        letterSpacing: "0.5px",
        [theme.breakpoints.down(desktopBreakpoint)]: {
            textAlign: "center",
            /* Mobil/P */
            fontSize: "14px",
            lineHeight: "20px" /* 142.857% */
        }
    },
    whiteH2Roboto: {
        color: "#FFF",
        fontFamily: "Roboto",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "123.688%" /* 37.106px */,
        [theme.breakpoints.down(desktopBreakpoint)]: {
            textAlign: "center",
            /* Mobil/H CTA */
            fontSize: "25px",
            lineHeight: "normal"
        }
    },
    blackH1: {
        color: "#2D2D2D",
        fontFamily: "Rubik",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "40px",
        marginTop: "40px",
        marginBottom: "45px",
        textAlign: "center",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            textAlign: "center",
            fontSize: "25px",
            marginTop: "20px",
            lineHeight: "26.8px",
            marginBottom: "unset"
        }
    },
    blackH2: {
        color: "#2D2D2D",
        fontFamily: "Rubik",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "40px",
        marginTop: 0,
        textAlign: "center",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            textAlign: "center",
            fontSize: "20px",
            lineHeight: "26.8px",
            marginTop: 0,
            marginBottom: "10px"
        },
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            marginTop: "20px",
            marginBottom: "20px"
        }
    },
    blackText: {
        color: "#2D2D2D",
        fontFamily: "Arial",
        fontSize: "17px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "24px", /* 142.857% */
        letterSpacing: "0.5px",
        textAlign: "center",
        width: "100%",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            textAlign: "left",
            fontSize: "14px",
            lineHeight: "20px"
        }
    },
    fatBlackText: {
        color: "#2D2D2D",
        fontFamily: "Arial",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "16px", /* 114.286% */
        letterSpacing: " 0.5px",
        "& .MuiTypography-root": {
            marginBottom: "10px"
        }
    },
    anchor: {
        color: "#2D2D2D",
        fontFamily: "Arial",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "16px",
        letterSpacing: "0.5px",
        textDecoration: "none",
        borderBottom: "1px solid transparent",
        "&:hover": {
            borderBottom: "1px solid #F09057"
        }
    },
    blackH3: {
        flex: "0 0 100%",
        color: "#332D2D",
        /* M3/title/medium */
        fontFamily: "Rubik",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "32px",
        letterSpacing: "0.15px",
        textAlign: "center",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            fontWeight: 500,
            textAlign: "left"
        }
    },
    card: {
        width: "100%",
        margin: "10px",
        maxWidth: "350px",
        padding: "0px",
        borderRadius: "6px",
        boxShadow: "0px 0px 20px 0px #2D2D2D1A"
    },
    cardContent: {
        paddingTop: "8px",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "20px"
    },
    cardTitle: {
        color: "#2D2D2D",
        fontFamily: "Rubik",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "32px" /* 177.778% */
    },
    cardText: {
        color: "#2D2D2D",
        fontFamily: "Arial",
        fontSize: "17px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "24px",
        letterSpacing: "0.5px",
        marginBottom: "10px"
    },
    alignCenter: {
        alignSelf: "center"
    },
    link: {
        color: "black",
        "&:link": {
            color: "black"
        },
        "&visited": {
            color: "black"
        }
    },
    textWrapper: {
        flex: "0 0 50%",
        display: "flex",
        flexWrap: "wrap",
        marginTop: "40px",
        marginBottom: "50px",
        alignItems: "center",
        justifyContent: "center",
        "&:last-child": {
            marginBottom: "unset"
        },
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            flex: "0 1 100%",
            padding: "20px",
            marginTop: "20px"
        }
    },
    adImageWrapper: {
        flex: "0 0 40%",
        padding: "16% 10% 10% 10%",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            flex: "0 0 100%",
            padding: "unset"
        }
    },
    adImage: {
        width: "100%",
        borderRadius: "5px"
    },
    hero: {
        display: "flex",
        width: "90%",
        justifyContent: "center",
        marginBottom: "20px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            width: "100%",
            marginTop: "20px"
        }
    },
    youtube: {
        aspectRatio: "16 / 9",
        flex: "0 1 1400px"
    },
    adWrapper: {
        display: "flex",
        "&:nth-child(odd)": {
            flexDirection: "row-reverse"
        },
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            flexWrap: "wrap"
        }
    },
    paragraph: {
        display: "flex",
        flex: "0 0 100%",
        flexWrap: "wrap"
    }
}))
