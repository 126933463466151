/* eslint-disable import/no-cycle */
import TabPanel from "./TabPanel"
import SwipeableTextMobileStepper from "../../../SwipableView"
import ProductCard from "../../ProductCard/ProductCard"
import RelatedProducts from "../view/RelatedProducts"
import { isObjectOnlyNull } from "../../../../lib/helper/commonHelper"
import {
    AppBar, Box, Tab, Tabs
} from "@mui/material"
import React from "react"

const DesktopProductInfo = (
    {
        id,
        tabList,
        handleChange,
        handleShowMoreCompsClick,
        value,
        isAuthorized,
        classes,
        product = {},
        modTek = {},
        modTekId,
        modTekInformation = {},
        altNameList = []
    }
) => (
    <Box className={classes.content}>
        <Box id="productMain" className={classes.productMain}>
            <Box id="productImageGrid" className={classes.halfWidth}>
                <SwipeableTextMobileStepper product={product} productPage />
            </Box>
            <Box className={classes.halfWidth} display="flex">
                <ProductCard
                    product={product}
                    handleShowMoreCompsClick={handleShowMoreCompsClick}
                    grid
                    backGroundColor="#f0f0f0"
                    showSearchDealerLink={!isAuthorized}
                    modTekInformation={modTekInformation}
                    altNameList={altNameList}
                />
                {
                    !isObjectOnlyNull(modTek)
                    && (
                        <RelatedProducts
                            id={id}
                            modTek={modTek}
                            modTekId={modTekId}
                            modTekInformation={modTekInformation}
                        />
                    )
                }
            </Box>
        </Box>
        <Box className={classes.tabRoot}>
            <AppBar position="static" className={classes.appBar}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Product information tabs"
                    className={classes.tabs}
                >
                    {
                        tabList.map((data, index) => (
                            <Tab
                                className={classes.tab}
                                disableRipple
                                label={data.title.toUpperCase()}
                                {...{
                                    id: `simple-tab-${index}`,
                                    "aria-controls": `simple-tabpanel-${index}`
                                }}
                                key={index}
                            />
                        ))
                    }
                </Tabs>
            </AppBar>
            <Box className={classes.panelBox}>
                {
                    tabList.map((data, index) => (
                        <TabPanel
                            value={value}
                            index={index}
                            key={index}
                        >
                            {data.component}
                        </TabPanel>
                    ))
                }
            </Box>
        </Box>
    </Box>
)

export default DesktopProductInfo
