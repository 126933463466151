/* eslint-disable no-restricted-syntax */
export const mailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const removeTrailingSlash = str => str.replace(/\/+$/, "")

/**
 * Moves the items containing the substring the top of the list.
 * @param {*} a The first string.
 * @param {*} b The second string.
 * @param {*} substringToTop The substring to search for.
 * @returns The new order represented as an integer.
 */
export const moveToTopComparer = (a, b, substringToTop, fullString) => {
    const partialString = JSON.stringify(fullString.split(" ").splice(0, 3))
    const partialA = JSON.stringify(a.split(" ").splice(0, 3))
    const partialB = JSON.stringify(b.split(" ").splice(0, 3))

    if (fullString === a) {
        return [true, -1]
    }

    const aIncludesWord = a.includes(substringToTop) || partialString === partialA
    const bIncludesWord = b.includes(substringToTop) || partialString === partialB

    if (aIncludesWord && bIncludesWord) return [true, 0]
    if (aIncludesWord) {
        return [true, -1]
    } if (bIncludesWord) {
        return [true, 1]
    }
    return [false, 0]
}

/**
 * Forces all strings that contain the words in the list to the top.
 * Starts with the first item. Will only move to the next word if
 * the previous word is present in the string.
 * @param a The first string.
 * @param b The second string.
 * @param words The words (in order) to be pushed to the top.
 * @returns A list sorted where the item containing the most
 * words will be moved to the top.
 */
export const moveWordsToTopComparer = (a, b, words) => {
    const fullString = words.join(" ").trimEnd()
    const results = words.map(word => moveToTopComparer(a, b, word, fullString))

    let resultingOrder = 0
    results.every(result => {
        const [foundWord, order] = result
        if (!foundWord) {
            resultingOrder = 0
            return false // Breaks the loop.
        }
        if (order !== 0) {
            resultingOrder = order
            return false // Breaks the loop.
        }

        return true // Continues the loop.
    })

    return resultingOrder
}

export const isProductIdRegex = /([0-9]{6})/

export const removeKey = (obj, keyRoRemove) => {
    if (!obj.hasOwnProperty(keyRoRemove)) {
        console.warn("key doesn't exsist in obj")
        return obj
    }
    const { [keyRoRemove]: _foo, ...objWithRestKeys } = obj
    return objWithRestKeys
}

export const isObjectOnlyNull = obj => {
    for (const key of Object.keys(obj)) {
        if (obj[key] !== null) {
            return false
        }
    }

    return true
}

function groupingBy(array, key) {
    return array.reduce((result, obj) => {
        (result[obj[key]] = result[obj[key]] || []).push(obj)
        return result
    }, {})
}

export const groupBy = (arr, func) => groupingBy(arr, func)

export const findObjectById = (obj, targetId) => {
    // Base case: if the current object has the target ID, return the object
    if (obj.id === targetId) {
        return obj
    }

    // Check if the current object has collections
    if (obj.collections && obj.collections.length > 0) {
        // Iterate through collections and recursively search for the target ID
        for (const collection of obj.collections) {
            const foundObject = findObjectById(collection, targetId)
            // If the target ID is found, return the object
            if (foundObject) {
                return foundObject
            }
        }
    }

    // If the target ID is not found in the current object or its collections, return null
    return null
}
