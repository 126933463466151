import TrackingButton from "./TrackingButton"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import getStatusText from "../functions"
import useStyles from "../styles"
import { Box, Paper, Typography } from "@mui/material"
import React from "react"
import { NavLink } from "react-router-dom"

const DesktopOrderPaper = ({ orderData, getDateFormat, customerOrder }) => {
    const classes = useStyles()
    const language = useLanguage()

    return (
        <Paper className={classes.root}>
            <Box className={classes.flexRow}>
                <Box className={classes.flexItem}>
                    <Typography>
                        {getDateFormat(customerOrder.OrderDate)}
                    </Typography>
                </Box>
                <Box className={classes.flexItem}>
                    <NavLink to={`/${language}/order/${customerOrder.OrderNumber}`} end>
                        <Typography>
                            {customerOrder.OrderNumber}
                        </Typography>
                    </NavLink>
                </Box>
                <Box className={classes.flexItem}>
                    <Box className={classes.flexColumn}>
                        {getStatusText(customerOrder.Status)}
                    </Box>
                </Box>
                <TrackingButton orderNumber={customerOrder.OrderNumber} shippingArray={customerOrder.Shipments} />
                {orderData.map((title, index) => (
                    <Box className={classes.flexItem} key={index}>
                        <Typography>
                            {title}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Paper>
    )
}

export default DesktopOrderPaper
