/* eslint-disable import/no-cycle */
import useStyles from "./styles"
import DynamicContent from "../DynamicContentPage/DynamicContent/DynamicContent"
import { strings as Localization } from "../../lib/Localization"
import { closeWelcome } from "../../redux/userSlice"
import useWindowDimensions from "../../redux/hook/windowHooks"
import { useGetDynamicPageQuery } from "../../redux/services/contentApi"
import React, { useMemo } from "react"
import {
    Popover, Box, IconButton, Tooltip
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useDispatch, useSelector } from "react-redux"

const WelcomeSplash = () => {
    const dispach = useDispatch()
    const classes = useStyles()
    const handleClose = () => {
        dispach(closeWelcome())
    }

    const POPOVER_WIDTH = 650
    const windowDimensions = useWindowDimensions()
    const showWelcome = useSelector(state => state.user.welcomeSplashVisible)
    const { data } = useGetDynamicPageQuery({ id: "welcome" })
    // Om visible inte finns i datan så lyssnar vi bara på showWelcome, annars har data.visible prio
    const isVisible = useMemo(
        () => (
            data?.visible === undefined
                ? showWelcome
                : data.visible && showWelcome
        ),
        [data, showWelcome]
    )

    return (
        <Popover
            open={isVisible}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={{
                top: Math.ceil(windowDimensions.height * 0.1),
                left: (windowDimensions.width / 2) - POPOVER_WIDTH / 2
            }}
            classes={{
                root: classes.root,
                paper: classes.paper
            }}
        >
            <Box className={classes.flex}>
                <Box className={classes.flexGrow1} />
                <Box className={classes.spacing1}>
                    <Tooltip title={Localization.close}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Box id="welcome-popover-content" className={classes.spacing2}>
                <DynamicContent type="welcome" />
            </Box>
        </Popover>
    )
}

export default WelcomeSplash
