import { makeStyles } from "@mui/styles"

export default makeStyles({
    pagination: {
        "& .Mui-selected": {
            background: "none",
            textDecoration: "underline",
            textUnderlineOffset: "0.2rem",
            fontWeight: "bold"
        }
    },
    select: {
        "&:before": {
            borderBottom: "none"
        },
        "&:after": {
            borderBottom: "none"
        }
    }
})
