import funnelIcon from "./funnel.svg"
import React from "react"
import { Icon } from "@mui/material"

const FunnelIcon = () => (
    <Icon>
        <img alt="filter icon" src={funnelIcon} height={20} width={20} style={{ color: "gray" }} />
    </Icon>
)

export default FunnelIcon
