import { useLanguage } from "../../lib/hooks/languageHooks"
import React from "react"
import { Helmet } from "react-helmet"

function ProductDevelopmentMetaDescription() {
    const language = useLanguage()

    const title = language === "sv"
        ? "Produktutveckling - Brodit"
        : "Product Development - Brodit"
    const description = language === "sv"
        ? "I de fall då vårt befintliga sortiment inte räcker för att tillgodose kundens behov, har vi möjlighet att skapa en skräddarsydd monteringslösning."
        : "In cases where our existing range is insufficient to meet the customer's needs, we have the opportunity to create a customized mounting solution."

    const keywords = language === "sv"
        ? "Produktutveckling, Brodit"
        : "Product Development, Brodit"

    return (
        <Helmet>
            <title>{title}</title>
            <meta
                name="description"
                content={description}
            />
            <meta
                name="keywords"
                content={keywords}
            />
        </Helmet>
    )
}

export default ProductDevelopmentMetaDescription
