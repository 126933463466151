import useStyles from "./styles"
import { strings as Localization } from "../../../../lib/Localization"
import { Typography } from "@mui/material"
import React from "react"

export function ListProductNumber({ originalId }) {
    const classes = useStyles()
    return (
        <div>
            <Typography
                variant="h5"
                className={classes.heading}
                gutterBottom
            >
                {Localization.itemNumber}
            </Typography>
            <Typography variant="subtitle1">
                {originalId}
            </Typography>
        </div>
    )
}

export function GridProductNumber({ originalId }) {
    return (
        <Typography variant="subtitle1">
            {Localization.itemNumber}
            {" "}
            {originalId}
        </Typography>
    )
}
