/* eslint-disable import/prefer-default-export */
import LocalizedStrings from "react-localization"

export const strings = new LocalizedStrings({
    en: {
        onlyOnlineShops: "See only online shops",
        findLocalRetailer: "Find your local retailer",
        viaOurDistributors: "Brodit products are available via our distributors.",
        pdfFiles: "PDF-files",
        compatibleProducts: "Compatible models",
        back: "Go back",
        showSearch: "Show search area",
        hideSearch: "Hide search area",
        tabInformation: "Information",
        moreInformation: "More information",
        tabSpecification: "Specification",
        tabCompability: "Fits for",
        tabInstructions: "Instructions",
        tabRelatedProducts: "Accessories",
        tabRelatedFiles: "Documents",
        noRelatedFiles: "There are no documents for this product",
        noAccessories: "There are no accessories for this product.",
        thereAreNoVideosForThisProduct: "There are no videos for this product.",
        itemNumber: "Item no",
        loadingMaterial: "Loading material",
        features: "Features",
        fasteners: "Fasteners",
        compabilities: "Fits the following models",
        thisProductIsNotModelSpecific: "This product is not model specific",
        loadingCompabilities: "Loading compabilities",
        loadingProperties: "Loading properties",
        properties: "Properties",
        propertiesNoneExist: "No properties apply to this product.",
        instructions: "Instructions for",
        chargingSpecification: "Charging specifications",
        error: "Error",
        unknownErrorCreatingOrder: "Unknown error creating order.",
        showMore: "Show more",
        showLess: "Show less",
        noMaterialsAssigned: "No features are assigned to this product.",
        comingSoon: "Coming soon",
        loadingProduct: "Loading product",
        createLeaflet: "Create leaflet",
        print: "Print",
        compare: "Compare",
        isComparing: "is being compared",
        share: "Share",
        loading: "Loading",
        image: "Image",
        addToCart: "Add to cart",
        eanNumber: "EAN-number",
        productInformation: "Product information",
        addToFavorites: "Add to favorites",
        removeFromFavorites: "Remove from favorites",
        yourFavorites: "My favorites",
        newProduct: "New product",
        pickedByPro: "Picked By Pro",
        newProductsForDevices: "New products for devices",
        newProductsForVehicles: "New products for vehicles",
        more: "More",
        moreSmall: "...more",
        close: "Close",
        seeAll: "Show all",
        hits: "hits",
        toggleLanguage: "Toggle language",
        user: "User",
        cart: "Cart",
        toggleView: "Toggle view",
        logIn: "Sign in",
        email: "E-mail",
        password: "Password",
        repeatPassword: "Repeat password",
        changePassword: "Change password",
        forgotPassword: "Forgot password",
        passwordsMustMatch: "The passwords must match",
        forgotPasswordNotification: "A mail for resetting the password has been sent to the given email if it exist in our system. The link is vaild for 30min.",
        checkYourLoginDetails: "Check your login details and try again.",
        product: "Product",
        theProduct: "The product",
        hasBeenAddedToCart: "has been added to the cart.",
        addressDetails: "Address details",
        confirmation: "Confirmation",
        yourCart: "Your cart",
        checkOrderDetails: "Please check your order carefully to make sure everything is correct.",
        summary: "Summary",
        numberOfOrderLines: "Number of order lines",
        totalQuantity: "Total quantity",
        total: "Total",
        emptyCart: "Empty cart",
        addNote: "Add note",
        note: "Note",
        quantity: "Order qty",
        qty: "Qty",
        price: "Price",
        backToShop: "Back to shop",
        backToCart: "Back to cart",
        toCheckout: "To checkout",
        submitOrder: "Submit order",
        myDetails: "My details",
        customerNumber: "Customer number:",
        name: "Name:",
        messageToBrodit: "Message to Brodit",
        purchaseOrder: "Purchase order | Marking",
        orderPlacedBy: "Order placed by | Reference",
        emailForOrderConfirmation: "E-mail (for order confirmation)",
        standardShippingAddress: "Standard shipping address",
        billingAddress: "Billing address",
        orderConfirmation: "Order confirmation",
        thankYouForOrder: "Thank you for ordering from Brodit!",
        orderIsProcessing: "Your order is now being processed. You will receive a confirmation via e-mail soon.",
        orderNumber: "Order number",
        orderDate: "Order date",
        orderEmail: "Order e-mail",
        shippingAddress: "Shipping address",
        reference: "Reference", // Kanske inte nödvändig, ett datafält egentligen
        shop: "Shop",
        shopViaReseller: "You can buy Brodit's products via reseller",
        findReseller: "Find a reseller",
        becomeReseller: "Become a distributor",
        new: "New",
        news: "News",
        showMoreNews: "Older news",
        couldNotFindTheSelectedNewsArticle: "Could not find the selected news article",
        newHolders: "New Holders",
        newProclip: "New ProClip",
        newsLetter: "Newsletter",
        aboutBrodit: "About Brodit",
        broditsStory: "Brodit's story",
        broditsMovie: "Brodit's movie",
        cookies: "Cookies",
        values: "Values",
        policies: "Policies",
        integrityPolicy: "Integrity policy",
        contact: "Contact",
        contactBrodit: "Contact Brodit",
        hello: "Hello",
        contactBroditVia: "Contact Brodit via ",
        ifYouWantToChangeAnyInformation: " if you wish to change any information.",
        hereIsYourUserInfo: "Here is your user info.",
        workAtBrodit: "Work at Brodit",
        findBrodit: "Find Brodit",
        noArticlesInCart: "You have no articles in the cart.",
        yourOrders: "My orders",
        yourReference: "Your reference",
        registered: "Registered",
        printed: "Printed",
        deliveryTimeCannotBeConfirmed: "Delivery time cannot be confirmed",
        processing: "Processing",
        partiallyDelivered: "Partly dispatched",
        deliveryComplete: "Dispatched",
        date: "Date",
        order: "Order",
        orderWasPlaced: "Order is placed, please check your email for order confirmation.",
        status: "Status",
        track: "Track",
        marking: "Marking",
        numberOfProducts: "Number of products",
        trackShipment: "Track shipment",
        backToOrderList: "Back to order list",
        sentQuantity: "Sent quantity",
        anErrorOccuredTryRelogging: "An error occured. Try logging in again.",
        published: "Published",
        toggleSortOrder: "Toggle sort order",
        sort: "Sort by",
        searchOnDevice: "Choose device",
        searchOnVehicle: "Choose vehicle",
        searchOnModel: "Choose model",
        searchOnModelYears: "Choose year",
        device: "Device",
        vehicle: "Vehicle",
        showImages: "Show more images",
        howToInstall: "How to install",
        phone: "Phone",
        fax: "Fax",
        thisSiteUsesCookies: "This site uses Cookies.",
        readMore: "Read more",
        readMoreInThePrivacyPolicy: "Read more in the Privacy policy.",
        iAccept: "I accept",
        IHaveReadAndAcceptTheTerms: "I have read and agree to the terms.",
        iHaveReadAndAgreeTo: "I have read and agree to",
        broditABsConditionsAndTermsOfSale: "Brodit ABs conditions and terms of sale",
        information: "Information",
        companyAdress: "Company",
        purchase: "Purchase",
        delivery: "Delivery",
        validate: "Validate",
        cancel: "Cancel",
        register: "Register",
        backwards: "Back",
        next: "Next",
        companyName: "Company name",
        organisationNumber: "Organisation number",
        website: "Website",
        contactPerson: "Contact person",
        phonenumber: "Phone number",
        streetAddress: "Street address",
        postalCode: "Postal code",
        town: "Town",
        boxDeliveryAddress: "Box delivery address",
        deliveryAddress: "Delivery address",
        postalAddress: "Postal address",
        companySignatory: "Company signatory",
        attachUpToDateRegistrationCertificateFromBolagsverketAsAFile: "Attach up to date registration certificate from Bolagsverket (PRV) as a file in PDF-format.",
        chooseAFile: "Choose a file",
        noFileHasBeenSelected: "No file has been selected.",
        pleaseAcceptTheTermsAndConditionsAboveToContinue: "Please accept the terms and conditions above to continue.",
        pleaseFillInThisCheckBox: "Please fill in this check box.",
        sendApplication: "Send application",
        forParcelDelivery: "For parcel delivery",
        forInvoiceThroughPdfBroditOnlySendsInvoicesThroughEmail: "for invoice in PDF format (Brodit only sends invoices through email)",
        yourApplicationHasBeenSent: "Your application has been sent.",
        aProblemOccuredWhileProccessingYourApplication: "A problem occured while proccessing your application.",
        pleaseTryAgainLaterOrContactBroditsSupportIfTheProblemPersists: " Please try again later or contact Brodit if the problem persists.",
        couldNotConnectToTheServer: "Could not connect to the server.",
        aProblemOccuredWhileProccessingYourApplicationPleaseTryAgainLaterOrContactBroditsSupportIfTheProblemPersists: "A problem occured while proccessing your application. Please try again later or contact Brodits support if the problem persists.",
        models: "Model",
        modelYears: "Model year",
        otherModels: "Other models",
        products: "Products",
        allProducts: "All products",
        loadMore: "Load more",
        searchResults: "Search results",
        youSearchedFor: "You searched for",
        search: "Search",
        searchUpperCase: "SEARCH",
        searchForProducts: "Search for products",
        premiereNewOnlineShop: "Premiere new online shop",
        beforeYouLogInInfo: "Updated shop requires updated password... Reset your password before logging in the first time (use the feature below). Thank you!",
        returnNote: "Return note",
        logOut: "Sign out",
        year: "Year",
        chooseYear: "CHOOSE YEAR",
        deselectAll: "DESELECT ALL",
        showOrHideFilters: "Show or hide filters",
        yourEmail: "Your e-mail",
        yourName: "Your name",
        receiversEmail: "Receivers e-mail",
        message: "Message",
        shareThis: "Share this",
        orderByFile: "Order by file",
        orderByFileInfoParagraph1: "For easier order placement you can upload an order-file into our web shop. You can export the file from your own system, and then upload it directly into your cart. This saves you time and eliminates type errors!",
        orderByFileInfoParagraph2: "Specifications: The file must be a comma separated text file. Usually, it is easiest to save your file as a csv-file directly from Excel or similar applications, but any text file will work as long as the numbers are delimited by commas or semi-colons. The file should contain item numbers and quantities only (Column A: Item no, Column B: Quantity)",
        upload: "Upload",
        clearYourCartFirst: "You already have items in your cart. Please clear it to continue.",
        orderWasImported: "Your order is now imported to the cart.",
        errorReadingOrderFile: "There was an error reading the order import file. Please try another file.",
        clear: "Clear",
        chosenFile: "Chosen file",
        notIncludedArticlesMessage: "The following articles are not found or not for sale",
        couldNotBePlacedInCart: "could not be placed in cart, it is not for sale or does not exist.",
        yourPasswordHasBeenChanged: "Your password has been changed.",
        ifYouHaveProblemsWithYourCartResetIt: "If you have problems with your cart, you can click here to reset it.",
        youHaveSharedTheProduct: "You have shared the product.",
        somethingWentWrongWhenSharingProduct: "Something went wrong when sharing the product.",
        recaptchaError: "Something went wrong with Recaptcha.",
        unknownError: "An unknown error occured.",
        articleCouldNotBeAddedQuantityZero: "The article cannot be added with quantity zero.",
        experiencingProblemsWithCart: "Experiencing problems with the cart? ",
        clickHere: "Click here",
        toResetYourCart: " to reset (empty) it.",
        endingSoon: "Ending Soon",
        chooseCity: "CHOOSE CITY",
        shopOnline: "Shop online",
        notFound: "Not found",
        thisPageWasNotFoundGoBackTo: "This page was not found. Go to ",
        home: "Mounting platform specialist!",
        phasingOut: "Few left",
        brands: "Brand",
        showAmount: "Show amount",
        yourSearchGenerated: "Your search generated",
        account: "Account",
        fileDownload: "File download",
        yourAccount: "My account",
        language: "Language",
        english: "English",
        swedish: "Swedish",
        german: "German",
        includePrices: "Include prices",
        updatesOnly: "Updates only",
        newItems: "New items",
        changedItems: "Changed items",
        deletedItems: "Deleted items",
        isDeleted: "Is deleted",
        isDiscontinuedAndNoLongerAvailable: "is discontinued and is no longer available",
        removeAll: "Remove all",
        removeItem: "Remove item",
        youMustChooseAType: "You must choose a type of data.",
        formatShouldBeISO8601: "Format should be YYYY-MM-DD.",
        download: "Download",
        errorWhenAttemptingToDownloadFile: "Error when attempting to download file.",
        type: "Type",
        noContentForTheSelectedLanguage: "No content for the selected language.",
        fromDate: "From date",
        webshopForBroditResellers: "Online shop for Brodit distributors",
        continueShopping: "Continue shopping",
        toSellBroditProducts: "To sell Brodit products",
        retailerApplicationText: `Brodit is represented worldwide via experienced
      and valued distributors. For unrepresented countries, there’s an opening
      for companies who are passionate about service, quality and innovative
      ideas. If that applies to you, please tell us about your current
      business areas as well as your visions for Brodit products in your
      country in an e-mail to exportsales@brodit.se. Thank you!`,
        thisProductIsNotAvailableOrder: "This item is included in your order but you can only see the item number, you can not see the product name or what it fits for.",
        country: "Country",
        useTemporaryAddress: "Send this order to a temporary shipping address",
        zipAndCity: "Zip and city",
        address: "Address",
        productNotFound: "Product not found",
        weCouldNotFindThisProductEtc: "We could not find this product. The product does not exist.",
        forInstanceKarlsborg: "I.e. \"54634 Karlsborg\"",
        asLoggedInUserYouCanWithdraw: "You can withdraw your consent at any time at your user profile or on the Privacy page under Documents.",
        ifYouWantToWithdrawConsent: "If you want to withdraw your consent to cookies, click ",
        here: "here",
        youMustThenAcceptTheTermsAgain: "You must then accept the privacy terms again to be able to sign in.",
        tryResettingPasswordAgain: "It seems like the token is not valid. Try resetting your password again.",
        fileDownloadIsNotAvailable: "File download is under development and is not available for now.",
        displaying: "Displaying",
        outOf: "out of",
        results: "results",
        apiKey: "API key",
        badRequestFileDownload: "Error in input when downloading file. Is API key in correct format?",
        unAuthorizedFileDownload: "Error trying to verify API key when downloading file. Check with Brodit that you are allowed to download file.",
        compareProducts: "Compare products",
        noProductsToCompare: "No products has been added for compare",
        description: "Description",
        placement: "Placement",
        fitsFor: "Fits for",
        technicalInformation: "Technical information",
        dimensionsAndWeight: "Dimensions & weight",
        width: "Width",
        height: "Height",
        depth: "Depth",
        weight: "Weight",
        volume: "Volume",
        yes: "Yes",
        no: "No",
        compareMaxThreeArticles: "You can compare up to three articles at a time.",
        compareMaxTwoArticles: "You can only compare two articles",
        toComparison: "To comparison",
        notApplicable: "Not applicable",
        clearAllProducts: "Clear all products",
        endComparison: "End comparison",
        thisProductIsNoLongerAvailable: "This product is no longer available.",
        parcel: "Parcel",
        orderSystemMaintenance: "Our order system is currently under maintenance. During this time you can't login and use some services on the site. Refresh the page in a while and try again. Sorry for the inconvenience.",
        systemMaintenanceTryAgainLater: "There is currently a system maintenance, please try again later.",
        theProductWasReplacedWithFollowingProductProducts: "The product was replaced by the following product(s).",
        wasReplacedByFollowingArticles: " was replaced by the following articles.",
        itWasReplacedByTheFollowingArticles: "It was replaced by the following articles: ",
        action: "Action",
        unregister: "Unregister",
        save: "Save",
        yourSubscriptionHasBeenUpdated: "Your newsletter subscription has been updated.",
        somethingWentWrong: "Something went wrong.",
        whereCanIBuy: "Where can I buy?",
        thePasswordMustAlsoComplyToTheFollowingRules: "The password must comply to the following rules:",
        containMinimumSixCharacters: "Contain minimum six characters",
        containUpperCaseCharacters: "Contain uppercase characters",
        containLowerCaseCharacters: "Contain lowercase characters",
        containANumber: "Contain a number",
        containAFigure: "Contain a special character",
        productIsNotAvailable: "Product is not available",
        withdrawingCookiesConsent: "Withdrawing cookies consent...",
        redirectingToStartPage: "Redirecting to start page...",
        video: "Video",
        startPage: "landing page",
        maximum30Letters: "This field can contain maximum 30 letters.",
        downloadCatalog: "Download catalog",
        homeSection1Titel: "Model specific Two-Part Solution",
        homeSection1Text: "Our ProClip and Device Holders are custom-designed for both your device and your specific vehicle model. The ProClip is seamlessly installed into the interior gaps without causing any damage or permanent modifications. Find the solution tailored to your needs using our search tool below. If you can't find what you're looking for, please contact us, and we'll be happy to assist you further.",
        findRightProclip: "Find the right ProClip for your vehicle",
        findRightHolder: "Holder for your device",
        homeSection2Titel: "Stronger Mounting Solutions",
        homeSection2Text: "Are you using a tablet, barcode scanner, payment terminal, or any other mobile device that needs to be mounted in a forklift, construction machine, or a truck? Whatever your needs are, we have a solution for you! With our wide range of products, we offer customized solutions for all types of environments, whether it's in the office, warehouse, or even in the air. We ensure that your devices are within reach, even under the most challenging conditions.",
        homeSectionPedestalHolderTitel: "Pedestal Mounts",
        homeSectionPedestalHolderText: "Modular, lightweight, and exceptionally strong - that's our range of aluminum pedestals designed for mounting heavier devices for everyday use in demanding environments.",
        homeSectionPedestalHolderLinkText: "Learn more about pedestal mounts",
        homeSectionPipeHolderTitel: "Pipe Mounts",
        homeSectionPipeHolderText: "Robust Pipe Mounts that easily attach to pipes and bars. Their applications are versatile, ranging from trucks, forklifts, carts, boats to airplanes.",
        homeSectionPipeHolderLinkText: "Learn more about pipe mounts",
        homeSectionTruckHolderTitel: "Forklift Mounts",
        homeSectionTruckHolderText: "Warehouse environments often involve heavy equipment and demanding conditions. For device mounting in these environments, sturdy mounts capable of withstanding stress and vibrations are required.",
        homeSectionTruckHolderLinkText: "Learn more about forklift mounts",
        homeSectionCustomSolutionsTitel: "Customized Solutions",
        homeSectionCustomSolutionsText: "At our factory in Karlsborg, Sweden, both development and manufacturing take place. If your company or operation has specific needs and requirements, our team of designers is ready to collaborate with you to create tailored solutions and products.",
        howIsItMade: "Read more",
        homeSection4Text: "The market introduces new vehicles and devices daily. For over four decades we've equipped ourselves with the knowledge and network required to rapidly develop, manufacture, and deliver products to a market that is constantly evolving.",
        deviceHolder: "Device Holders",
        proclip: "ProClip",
        seeProduct: "Show product",
        homeSection5Title: "Do you need help finding the right products?",
        homeSection5Text: "Contact us and describe your needs, and we'll assist you in finding the best solution for you!",
        proclipAndHolders: "ProClip and Device Holders",
        proclipSection1Title: "Model specific ProClip Mounts and Device Holders",
        proclipSection1Text: "Our two-part mounting solution provides you with a tailored device installation perfectly suited to your needs. One part consists of a Device Holder designed to fit your mobile phone, tablet, or other handheld device precisely. This is combined with a ProClip, which is a mount built specifically for your car model. The ProClip is installed in the car completely without screws, therefore causing no damage to the interior. Flexible, stable and secure - Simple!",
        proclipBrandsTitle: "We offer ProClips for vehicles from a wide range of brands, including:",
        deviceBrandsTitle: "We offer holders for devices from a wide range of brands, including:",
        proclipSection2ProclipText: "The ProClip serves as the mounting platform for the Device Holder. The purpose of our ProClip is to enable a stable and secure installation of the Device Holder in the vehicle, without the need for any permanent alterations such as drilling or screwing into the instrument panel. Instead, the ProClip is securely attached to the vehicle's existing gaps, panels, or seams on the dashboard. In many cases, it is further secured with double-sided adhesive tape specially designed not to leave any residue if you choose to remove or relocate the ProClip. Each ProClip is specifically designed for each car make and model, and the development of a new ProClip is a craftsmanship done on-site for each car and truck. Typically we develop a couple of ProClips for each vehicle model to offer a variety of mounting options.",
        proclipSection2HolderTitle: "Device Holders",
        proclipSection2HolderText: "The Device Holder is also specifically designed for your smartphone, GPS, or other mobile device. Various options are offered based on whether you have the device with or without a case, desire charging with a cable or wirelessly, or a passive holder without charging. In addition to our model specific device holders, we also offer adjustable holders that can be customized in height or width depending on the device you use it for.",
        proclipSection3ProclipBrands: "ProClip is available for all of these brands",
        proclipSection3ProclipDevices: "We offer Device Holders for a wide range of brands, including these",
        productDevSection1Title: "Customized solutions",
        productDevSection1Text: "In cases where our existing range is insufficient to meet the customer's needs, we have the opportunity to create a customized mounting solution.",
        productDevSection2Title: "Needs analysis",
        productDevSection2TextPart1: "The process begins with direct communication between you, our sales representatives, and product developers. Together, we identify needs and potential solutions.",
        productDevSection2TextPart2: "The needs can vary depending on how and where the device will be used. These needs include factors such as challenging environments, technical specifications, charging and data transfer requirements, and more.",
        productDevSection2Part3Title: "Cost estimation and prototype",
        productDevSection2TextPart3: "When we have achieved a common understanding of how the product should be designed, we present a development cost. If this cost is approved, we proceed to manufacture a prototype. The prototype can be a functional, CNC-machined version or, in some cases, a 3D-printed prototype.",
        contactUs: "Contact us",
        productDevSection3Text: "We do not impose any limitations on what a customer-specific project may entail; we have developed unique solutions for emergency services, food companies, the forestry industry, and more.",
        seeMore: "+ Show more",
        seeLess: "- Show less",
        addArticleToCart: "Add article to cart",
        megaMenu: "If your company or business has specific needs and requirements, our team of designers is ready to collaborate with you and create customized solutions and products.",
        examplesSpecialCharacters: "Examples of special characters: ",
        areYouSure: "Are you sure?",
        deleteAllConfirmation: "This will delete all products in the cart.",
        exVat: "excl. VAT",
        downloadComparisonPdf: "Download comparison as PDF",
        findProClip: "Choose a ProClip for your vehicle",
        findDeviceHolder: "Find your Device Holder",
        customerDetails: "Customer details",
        myPagesEmail: "E-mail:",
        refreshToContinue: "Refresh the page to continue.",
        adImageAlt: "Work ad",
        numbersOnly: "May only contain numbers",
        smsTracking: "SMS-tracking",
        cellNumber: "Cellphone number",
        homeSuctionTitle: "Suction Cup Mounts",
        homeSuctionText: "Quick and easy mounting on smooth surfaces. Enables flexible solutions.",
        homeSuctionLinkText: "Learn more about suction cup mounts",
        homeScreenTitle: "Workstation mounts",
        homeScreenText: "Ergonomic holders for a screen and keyboard, with options for fixed or foldable holders, suited for demanding environments.",
        homeScreenLinkText: "Learn more about workstation mounts",
        homeMoveClipTitle: "MoveClip",
        homeMoveClipText: "Allows easy switching between different holders on the same mounting location, and provides the ability to conveniently use the same holder in multiple places.",
        homeMoveClipLinkText: "Learn more about MoveClip",
        homeReadMoreAboutStrong: "Read more about our stronger mounting solutions",
        contactFullname: "Full name",
        contactCompany: "Company",
        contactPhone: "Phone",
        contactEmail: "Email",
        contactMessageTitle: "How can we help you? *",
        contactSend: "Send request",
        contactOfficeAndManufacturing: "Head office and manufacturing",
        contactMailSent: "Contact request has been sent.",
        newList: "Create a new list of favorites",
        productId: "Product number",
        errorCreatingFavoriteList: "En ny lista behöver namn och produkter för att kunna skapas. A new list needs a name and at least one product to be created.",
        myLists: "My lists:",
        selectLists: "Choose lists or create a new one",
        decreaseQuantity: "Decrease quantity",
        increaseQuantity: "Increase quantity",
        deleteList: "Delete list",
        editList: "Edit list",
        showUnlisted: "Show unlisted",
        showAllFavorites: "Show all favorites"
    },
    sv: {
        onlyOnlineShops: "Se enbart E-handel",
        findLocalRetailer: "Hitta din lokala återförsäljare",
        viaOurDistributors: "Brodits produkter kan köpas via våra återförsäljare.",
        pdfFiles: "PDF-filer",
        compatibleProducts: "Kompatibla modeller",
        back: "Bakåt",
        showSearch: "Visa sökfält",
        hideSearch: "Dölj sökfält",
        tabInformation: "Information",
        moreInformation: "More information",
        tabSpecification: "Specifikation",
        tabCompability: "Passar till",
        tabInstructions: "Instruktioner",
        tabRelatedProducts: "Tillbehör",
        tabRelatedFiles: "Dokument",
        noRelatedFiles: "Det finns inga dokument för den här produkten",
        noAccessories: "Det finns inga tillbehör för den här produkten",
        thereAreNoVideosForThisProduct: "Det finns inga videoklipp för den här produkten.",
        itemNumber: "Art. nr.",
        loadingMaterial: "Laddar material",
        features: "Funktioner",
        fasteners: "Fästen",
        compabilities: "Passar till följande modeller",
        thisProductIsNotModelSpecific: "Den här produkten är inte modellspecifik",
        loadingCompabilities: "Laddar kompabiliteter",
        loadingProperties: "Laddar egenskaper",
        properties: "Egenskaper",
        propertiesNoneExist: "Inga egenskaper gäller för denna produkt.",
        instructions: "Instruktioner för",
        chargingSpecification: "Laddningsspecifikationer",
        error: "Fel",
        unknownErrorCreatingOrder: "Okänt fel när ordern skapades.",
        showMore: "Visa fler",
        showLess: "Visa färre",
        noMaterialsAssigned: "Inga funktioner är knutna till den här produkten.",
        comingSoon: "Kommer snart",
        loadingProduct: "Laddar produkt",
        createLeaflet: "Skapa broschyr",
        print: "Skriv ut",
        compare: "Jämför",
        isComparing: "blir jämförd",
        share: "Dela",
        loading: "Laddar",
        image: "Bild",
        addToCart: "Lägg i varukorg",
        eanNumber: "EAN-nummer",
        productInformation: "Produktinformation",
        addToFavorites: "Lägg till som favorit",
        removeFromFavorites: "Ta bort från favoriter",
        yourFavorites: "Mina favoriter",
        newProduct: "Ny produkt",
        pickedByPro: "Bästsäljare",
        newProductsForDevices: "Nya produkter till enheter",
        newProductsForVehicles: "Nya produkter till fordon",
        more: "Mer",
        moreSmall: "...fler",
        close: "Stäng",
        seeAll: "Visa alla",
        hits: "träffar",
        toggleLanguage: "Växla språk",
        user: "Användare",
        cart: "Varukorg",
        toggleView: "Växla vy",
        logIn: "Logga in",
        email: "E-post",
        password: "Lösenord",
        repeatPassword: "Upprepa lösenordet",
        changePassword: "Ändra lösenord",
        forgotPassword: "Glömt lösenord",
        passwordsMustMatch: "Lösenorden måste matcha",
        forgotPasswordNotification: "Ett återställningsmail har skickats till den angivna e-posten om den finns i systemet. Länken är giltig i 30min.",
        checkYourLoginDetails: "Kontrollera dina login-uppgifter och prova igen.",
        product: "Artikel",
        theProduct: "Artikel",
        hasBeenAddedToCart: "har lagts i varukorgen",
        addressDetails: "Adressuppgifter",
        confirmation: "Bekräftelse",
        yourCart: "Din varukorg",
        checkOrderDetails: "Kontrollera din order noggrant för att vara säker på att allt stämmer.",
        summary: "Sammanställning",
        numberOfOrderLines: "Antal orderrader",
        totalQuantity: "Artiklar totalt",
        total: "Totalt",
        emptyCart: "Töm varukorg",
        addNote: "Lägg till anteckning",
        note: "Notering",
        quantity: "Order antal",
        qty: "Ant",
        price: "Pris",
        backToShop: "Tillbaka to affär",
        backToCart: "Tillbaka till varukorg",
        toCheckout: "Till kassan",
        submitOrder: "Skicka order",
        myDetails: "Mina uppgifter",
        customerNumber: "Kundnummer:",
        name: "Namn:",
        messageToBrodit: "Meddelande till Brodit",
        purchaseOrder: "Inköpsorder | Märkning",
        orderPlacedBy: "Order lagd av | Referens",
        emailForOrderConfirmation: "E-post (för orderbekräftelse)",
        standardShippingAddress: "Förvald leveransadress",
        billingAddress: "Fakturaadress",
        orderConfirmation: "Orderbekräftelse",
        thankYouForOrder: "Tack för att du beställer från Brodit!",
        orderIsProcessing: "Din order behandlas. Du kommer få en bekräftelse via e-post snart.",
        orderNumber: "Ordernummer",
        orderDate: "Orderdatum",
        orderEmail: "E-post för order",
        shippingAddress: "Leveransadress",
        reference: "Referens", // Kanske inte nödvändig, ett datafält egentligen
        shop: "Handla",
        shopViaReseller: "Du kan köpa Brodits produkter via återförsäljare",
        findReseller: "Hitta återförsäljare",
        becomeReseller: "Bli återförsäljare",
        new: "Nyhet",
        news: "Nyheter",
        showMoreNews: "Äldre nyheter",
        couldNotFindTheSelectedNewsArticle: "Kunde inte hitta den valda nyhetsartikeln",
        newHolders: "Nya hållare",
        newProclip: "Nya ProClip",
        newsLetter: "Nyhetsbrev",
        aboutBrodit: "Om Brodit",
        broditsStory: "Historien om Brodit",
        broditsMovie: "Filmen om Brodit",
        cookies: "Kakor",
        values: "Värdegrund",
        policies: "Policy",
        integrityPolicy: "Integritetspolicy",
        contact: "Kontakt",
        contactBrodit: "Kontakta Brodit",
        hello: "Hej",
        contactBroditVia: "Kontakta Brodit via ",
        ifYouWantToChangeAnyInformation: " om du vill ändra någon information.",
        hereIsYourUserInfo: "Här hittar du information kring din användare.",
        workAtBrodit: "Jobba på Brodit",
        findBrodit: "Hitta till Brodit",
        noArticlesInCart: "Du har inga artiklar i varukorgen.",
        yourOrders: "Mina beställningar",
        yourReference: "Er referens",
        registered: "Registrerad",
        printed: "Utskriven",
        deliveryTimeCannotBeConfirmed: "Leveranstid kan ej bekräftas",
        processing: "Bearbetas",
        partiallyDelivered: "Delvis skickad",
        deliveryComplete: "Skickad",
        date: "Datum",
        order: "Beställning",
        orderWasPlaced: "Ordern är lagd, vänligen kontrollera din e-post efter orderbekräftelsen.",
        status: "Status",
        track: "Spåra",
        marking: "Märkning",
        numberOfProducts: "Antal produkter",
        trackShipment: "Spåra leverans",
        backToOrderList: "Tillbaka till orderlistan",
        sentQuantity: "Skickat antal",
        anErrorOccuredTryRelogging: "Ett fel inträffade. Testa att logga in på nytt.",
        phone: "Telefon",
        fax: "Fax",
        published: "Publicerad",
        showImages: "Visa mer bilder",
        toggleSortOrder: "Växla sorteringsordning",
        sort: "Sortera på",
        searchOnDevice: "Välj enhet",
        searchOnVehicle: "Välj fordon",
        searchOnModel: "Välj Modell",
        searchOnModelYears: "Välj år",
        device: "Enhet",
        vehicle: "Fordon",
        howToInstall: "Så monterar du",
        thisSiteUsesCookies: "Denna webbplats använder Cookies.",
        readMore: "Läs mer",
        readMoreInThePrivacyPolicy: "Läs mer i vår integritetspolicy.",
        iAccept: "Jag accepterar",
        IHaveReadAndAcceptTheTerms: "Jag har läst och godkänner villkoren.",
        iHaveReadAndAgreeTo: "Jag har tagit del av och accepterat",
        broditABsConditionsAndTermsOfSale: "Brodit ABs försäljningsvillkor",
        information: "Information",
        companyAdress: "Företag",
        purchase: "Inköp",
        delivery: "Leveransadress",
        validate: "Validera",
        cancel: "Avbryt",
        backwards: "Tillbaka",
        next: "Nästa",
        companyName: "Företagsnamn",
        organisationNumber: "Organisationnummer",
        website: "Hemsida",
        contactPerson: "Kontaktperson",
        phonenumber: "Telefonnummer",
        streetAddress: "Gatuadress",
        postalCode: "Postnr",
        town: "Ort",
        boxDeliveryAddress: "Boxadress",
        deliveryAddress: "Leveransadress",
        postalAddress: "Postadress",
        companySignatory: "Firmatecknare",
        attachUpToDateRegistrationCertificateFromBolagsverketAsAFile: "Bifoga aktuellt registreringsbevis från Bolagsverket (PRV) som fil i PDF-format.",
        chooseAFile: "Välj fil",
        noFileHasBeenSelected: "Ingen fil har valts.",
        pleaseAcceptTheTermsAndConditionsAboveToContinue: "Vänligen acceptera vilkoren ovan för att fortsätta.",
        pleaseFillInThisCheckBox: "Vänligen fyll i denna kryssrutan.",
        sendApplication: "Skicka ansökan",
        forParcelDelivery: "För paketleverans",
        forInvoiceThroughPdfBroditOnlySendsInvoicesThroughEmail: "för faktura via PDF (Brodit skickar endast faktura via E-post)",
        yourApplicationHasBeenSent: "Din ansökan har skickats.",
        aProblemOccuredWhileProccessingYourApplication: "Ett problem uppstod när din ansökan skulle behandlas.",
        pleaseTryAgainLaterOrContactBroditsSupportIfTheProblemPersists: "Vänligen försök igen senare eller kontakta Brodit ifall problemet kvarstår.",
        couldNotConnectToTheServer: "Det gick inte att ansluta till servern.",
        aProblemOccuredWhileProccessingYourApplicationPleaseTryAgainLaterOrContactBroditsSupportIfTheProblemPersists: "Ett problem uppstod när din ansökan skulle behandlas. Vänligen försök igen senare eller kontakta Brodits support ifall problemet kvarstår.",
        models: "Modell",
        modelYears: "Årsmodell",
        otherModels: "Övriga modeller",
        products: "Produkter",
        allProducts: "Alla produkter",
        loadMore: "Ladda fler",
        searchResults: "Sökresultat",
        youSearchedFor: "Du sökte efter",
        search: "Sök",
        searchUpperCase: "SÖK",
        searchForProducts: "Sök efter produkter",
        premiereNewOnlineShop: "Premiär ny webshop",
        beforeYouLogInInfo: "Uppdaterad shop kräver uppdaterat lösenord! Återställ ditt lösenord innan du loggar in första gången (använd funktionen nedan). Tack!",
        returnNote: "Retursedel",
        logOut: "Logga ut",
        year: "År",
        chooseYear: "Välj år",
        deselectAll: "AVMARKERA ALLA",
        showOrHideFilters: "Visa eller dölj filter",
        yourEmail: "Din e-post",
        yourName: "Ditt namn",
        receiversEmail: "Mottagarens e-post",
        message: "Meddelande",
        shareThis: "Dela detta",
        orderByFile: "Order via fil",
        orderByFileInfoParagraph1: "För enklare orderläggning kan du läsa in en orderfil i vår webshop. Du kan exportera filen från ditt eget system, och ladda upp den direkt i din varukorg så slipper du knappa in ordern manuellt. Snabbt och bekvämt!",
        orderByFileInfoParagraph2: "Specifikationer: Filen skall vara en kommaseparerad textfil. Det enklaste är att välja att spara filen i csv-format i Excel eller liknande progam, men vilken textfil som helst fungerar så länge siffrorna är avgränsade med komma eller semikolon. Filen ska endast innehålla artikelnummer och antal (Kolumn A: Artikelnummer, Kolumn B: Antal).",
        upload: "Ladda upp",
        clearYourCartFirst: "Du har redan artiklar i varukorgen. Vänligen rensa den innan du fortsätter.",
        orderWasImported: "Ordern är nu importerad till varukorgen.",
        errorReadingOrderFile: "Det uppstod ett fel när orderfilen lästes in. Försök med en annan fil.",
        clear: "Rensa",
        chosenFile: "Vald fil",
        notIncludedArticlesMessage: "Följande artiklar kunde inte hittas eller är inte till försäljning",
        couldNotBePlacedInCart: "kunde inte läggas i varukorgen, den är inte till salu eller finns inte",
        yourPasswordHasBeenChanged: "Ditt lösenord har ändrats.",
        ifYouHaveProblemsWithYourCartResetIt: "Om du har problem med varukorgen kan du klicka här för att återställa den.",
        youHaveSharedTheProduct: "Du har delat produkten.",
        somethingWentWrongWhenSharingProduct: "Någonting gick fel när produkten skulle delas.",
        recaptchaError: "Någonting gick fel med Recaptcha.",
        unknownError: "Ett okänt fel uppstod.",
        articleCouldNotBeAddedQuantityZero: "Artikeln kan inte läggas till med noll i antal.",
        experiencingProblemsWithCart: "Upplever du problem med varukorgen? ",
        clickHere: "Klicka här",
        toResetYourCart: " för att återställa (tömma) den.",
        endingSoon: "Utgående",
        chooseCity: "VÄLJ STAD",
        shopOnline: "Handla online",
        notFound: "Sidan hittas inte",
        thisPageWasNotFoundGoBackTo: "Sidan kunde inte hittas. Gå till ",
        home: "Specialisten på monteringsplattformar!",
        phasingOut: "Få kvar",
        brands: "Märke",
        showAmount: "Visa antal",
        yourSearchGenerated: "Din sökning genererade",
        account: "Konto",
        fileDownload: "Filnedladdning",
        yourAccount: "Mitt konto",
        language: "Språk",
        english: "Engelska",
        swedish: "Svenska",
        german: "Tyska",
        includePrices: "Inkludera priser",
        updatesOnly: "Endast uppdateringar",
        newItems: "Nya produkter",
        changedItems: "Ändrade produkter",
        deletedItems: "Borttagna produkter",
        isDeleted: "Är borttagna",
        isDiscontinuedAndNoLongerAvailable: "har utgått ur sortimentet",
        removeAll: "Ta bort alla",
        removeItem: "Ta bort produkt",
        youMustChooseAType: "Du måste välja en typ av data.",
        formatShouldBeISO8601: "Formatet måste vara YYYY-MM-DD.",
        download: "Ladda ner",
        errorWhenAttemptingToDownloadFile: "Ett fel uppstod då filen skulle laddas ner.",
        type: "Typ",
        noContentForTheSelectedLanguage: "Inget innehåll för det valda språket.",
        fromDate: "Från datum",
        webshopForBroditResellers: "Webshop för Brodits återförsäljare",
        continueShopping: "Fortsätt handla",
        toSellBroditProducts: "Att sälja Brodits produkter",
        retailerApplicationText: `Brodit representeras i hela Sverige av kunniga och uppskattade återförsäljare.
    Om du brinner för service, kvalitet, problemlösning och vill arbeta aktivt med
    Brodits produkter, fyll gärna i dina uppgifter nedan så kontaktar vi dig. Tack!`,
        thisProductIsNotAvailableOrder: "Den här produkten är med på din order men du kan endast se artikelnumret, du kan inte se produktens namn eller vad den passar till.",
        country: "Land",
        useTemporaryAddress: "Skicka denna order till en tillfällig leveransadress. Kostnad 35 kr exkl moms.",
        zipAndCity: "Postnummer och ort",
        address: "Adress",
        productNotFound: "Produkten hittades inte",
        weCouldNotFindThisProductEtc: "Vi kunde inte hitta den här produkten. Produkten finns inte.",
        forInstanceKarlsborg: "T.ex. \"54634 Karlsborg\"",
        asLoggedInUserYouCanWithdraw: "Som inloggad användare kan du när som helst ångra ditt samtycke genom länken i din profil eller på sidan Integritet under Dokument.",
        ifYouWantToWithdrawConsent: "Om du vill ångra ditt samtycke till cookies kan du klicka ",
        here: "här",
        youMustThenAcceptTheTermsAgain: "Du måste då åter acceptera villkoren för att kunna använda sidan i inloggat läge.",
        tryResettingPasswordAgain: "Det verkar som länken inte stämmer. Försök återställa ditt lösenord igen.",
        fileDownloadIsNotAvailable: "Filnedladdning är under utveckling och är därför inte tillgängligt just nu.",
        displaying: "Visar",
        outOf: "av",
        results: "resultat",
        apiKey: "API nyckel",
        badRequestFileDownload: "Fel i indata vid filnedladdning. Är API-nyckeln i korrekt format?",
        unAuthorizedFileDownload: "Fel vid verifiering av API-nyckel. Kontrollera med Brodit att du är behörig för filnedladdning.",
        compareProducts: "Jämför produkter",
        noProductsToCompare: "Inga artiklar har lagts till i jämförelse",
        description: "Beskrivning",
        placement: "Placering",
        fitsFor: "Passar till",
        technicalInformation: "Teknisk information",
        dimensionsAndWeight: "Dimensioner & vikt",
        width: "Bredd",
        height: "Höjd",
        depth: "Djup",
        weight: "Vikt",
        volume: "Volym",
        yes: "Ja",
        no: "Nej",
        compareMaxThreeArticles: "Du kan jämföra upp till tre artiklar samtidigt.",
        compareMaxTwoArticles: "Du kan endast jämföra två artiklar samtidigt.",
        toComparison: "Till jämförelsen",
        notApplicable: "Inte tillämpbar",
        clearAllProducts: "Rensa alla produkter",
        endComparison: "Avsluta jämförelse",
        thisProductIsNoLongerAvailable: "Denna produkt är inte längre tillgänglig.",
        parcel: "Försändelse",
        orderSystemMaintenance: "Vårt ordersystem underhålls för närvarande. Under denna tid kan du inte logga in och använda vissa tjänster på webbplatsen. Uppdatera sidan om ett tag och försök igen. Vi beklagar om detta orsakar problem.",
        systemMaintenanceTryAgainLater: "För närvarande systemunderhåll, prova igen senare.",
        theProductWasReplacedWithFollowingProductProducts: "Produkten är ersatt med följande produkt(er).",
        wasReplacedByFollowingArticles: " har blivit ersatt av följande artiklar.",
        itWasReplacedByTheFollowingArticles: "Den är ersatt av följande artiklar: ",
        action: "Åtgärd",
        register: "Prenumerara",
        unregister: "Avprenumerera",
        save: "Spara",
        yourSubscriptionHasBeenUpdated: "Din nyhetsprenumeration har uppdaterats.",
        somethingWentWrong: "Någonting gick fel.",
        whereCanIBuy: "Var kan jag köpa?",
        thePasswordMustAlsoComplyToTheFollowingRules: "Lösenorder måste uppfylla följande krav: ",
        containMinimumSixCharacters: "Innehålla minst sex tecken",
        containUpperCaseCharacters: "Innehålla versaler",
        containLowerCaseCharacters: "Innehålla gemener",
        containANumber: "Innehålla en siffra",
        containAFigure: "Innehålla minst ett specialtecken",
        productIsNotAvailable: "Produkten finns inte tillgänglig",
        withdrawingCookiesConsent: "Ångrar samtycke till cookies...",
        redirectingToStartPage: "Omdirigeras till startsidan...",
        video: "Video",
        startPage: "start sidan",
        maximum30Letters: "Det här fältet kan innehålla maximalt 30 tecken.",
        downloadCatalog: "Ladda ner katalog",
        homeSection1Titel: "Modellspecifik tvådelslösning",
        homeSection1Text: "Våra ProClip och enhetshållare är tillverkade modellspecifikt för både enhet och fordon. ProClip monteras naturligt i interiörens springor helt utan skada eller permanenta ändringar. Sök efter vår lösning för just dina behov med hjälp av vårt sökverktyg här nedan. Hittar du inte det du söker så kontakta oss så hjälper vi dig vidare.",
        findRightProclip: "Hitta rätt ProClip för din bil",
        findRightHolder: "Hållare för din enhet",
        homeSection2Titel: "Starkare monteringslösningar",
        homeSection2Text: "Använder du en surfplatta, streckkodsläsare, betalterminal eller annan mobil enhet som behöver monteras i en truck, entreprenadmaskin eller lastbil? Vi har en lösning för alla dina behov! Med vårt breda sortiment erbjuder vi anpassade lösningar för alla typer av miljöer. Oavsett om det är på kontoret, lagret eller i luften så säkerställer vi att dina enheter alltid är inom räckhåll, även under de mest utmanande förhållandena.",
        homeSectionPedestalHolderTitel: "Piedestalfästen",
        homeSectionPedestalHolderText: "Modulärt, lättviktigt och mycket starkt. - Det är vårt sortiment av aluminiumpiedestaler för montering av tyngre enheter vid vardagligt bruk i krävande miljöer.",
        homeSectionPedestalHolderLinkText: "Mer om piedestalfästen",
        homeSectionPipeHolderTitel: "Rörfästen",
        homeSectionPipeHolderText: "Robusta rörfästen som enkelt monteras på rör och stänger. Användningsområdena är många. Lastbilar, truckar, vagnar, båtar och flygplan är exempel på där de används.",
        homeSectionPipeHolderLinkText: "Mer om rörfästen",
        homeSectionTruckHolderTitel: "Truckfästen",
        homeSectionTruckHolderText: "Lagermiljöer innefattar ofta tung utrustning och krävande förhållanden. För enhetsmontering i dessa miljöer krävs rejäla fästen som klarar av påfrestningar och vibrationer.",
        homeSectionTruckHolderLinkText: "Mer om truckfästen",
        homeSectionCustomSolutionsTitel: "Skräddarsydda lösningar",
        homeSectionCustomSolutionsText: "I vår fabrik i  Karlsborg sker både utveckling och tillverkning. Om ditt företag eller din verksamhet har särskilda behov och krav så är vårt team av konstruktörer redo för att samarbeta med er och skapa skräddarsydda lösningar och produkter.",
        howIsItMade: "Såhär går det till",
        homeSection4Text: "Marknaden introducerar dagligen nya fordon och enheter. Vi har under fyra decennier rustat oss med den kunskap och det nätverk som krävs för att snabbt utveckla, tillverka och leverera produkter till en marknad som är i ständig förändring.",
        deviceHolder: "Enhetshållare",
        proclip: "ProClip",
        seeProduct: "Visa produkt",
        homeSection5Title: "Behöver du hjälp med att hitta rätt produkter?",
        homeSection5Text: "Kontakta oss och beskriv dina behov så hjälper vi dig att hitta den bästa lösningen för just dig!",
        proclipAndHolders: "ProClip och Enhetshållare",
        proclipSection1Title: "Modellspecifika ProClipfästen och enhetshållare",
        proclipSection1Text: "Vår tvådelade monteringslösning ger dig en skräddarsydd enhetsmontering som passar perfekt för dina behov. Ena delen är en enhetshållare skapad för att passa just din mobil, surfplatta eller annan handhållen enhet, denna kombineras med en ProClip som är ett fäste byggt specifikt för din bilmodel. ProClip monteras i bilen helt utan skruv och gör därmed ingen skada på interiören.  Flexibelt, stabilt och säkert - Enkelt!",
        proclipSection2ProclipText: "ProClipen är monteringsplattformen för enhetshållaren, syftet med vår ProClip är att kunna montera enhetshållaren i fordonet stabilt och säkert, helt utan att göra permanenta ändringar i bilen så som att borra och skruva i instrumentpanelen. En ProClip klickas istället fast i bilens befintliga springor, paneler eller skarvar på instrumentbrädan. I många fall säkras den sedan av en dubbelhäftande tejp som är speciellt framtagen för att inte lämna några spår efter sig om man vill ta ur eller flytta en ProClip. En ProClip är framtagen specifikt för varje bil- och årsmodell och framtagningen av en ny ProClip är ett hantverk om görs på plats i varje bil och lastbil. Oftast tar vi fram ett par ProClip till varje fordonsmodell för att kunna erbjuda en rad olika placeringar att välja mellan.",
        proclipSection2HolderTitle: "Enhetshållare",
        proclipBrandsTitle: "Vi erbjuder ProClip för fordon från ett brett urval av märken, bland annat:",
        deviceBrandsTitle: "Vi erbjuder hållare för enheter från ett brett urval av varumärken, bland annat:",
        proclipSection2HolderText: "Enhetshållaren är också designad specifikt för din smartphone, GPS eller annan mobil enhet. Olika val erbjuds utifrån om du har enheten med eller utan skal, önskar laddning med kabel eller trådlöst eller en passiv hållare om du inte önskar laddning. Utöver våra modellspecifika enhetshållare erbjuder vi även justerbara hållare som kan anpassas i höjd eller bredd beroende på vilken enhet du vill använda den till.",
        proclipSection3ProclipBrands: "ProClip finns för alla dessa fordonsmärkena",
        proclipSection3ProclipDevices: "Vi har Enhetshållare för bland annat dessa varumärken",
        productDevSection1Title: "Skräddarsydda lösningar",
        productDevSection1Text: "I de fall då vårt befintliga sortiment inte räcker för att tillgodose kundens behov, har vi möjlighet att skapa en skräddarsydd monteringslösning.",
        productDevSection2Title: "Behovsanalys",
        productDevSection2TextPart1: "Processen inleds med en direkt kommunikation mellan dig, våra säljare och produktutvecklare. Tillsammans identifierar vi behov och potentiella lösningar.",
        productDevSection2TextPart2: " Behoven kan variera beroende på hur och var enheten ska användas. Dessa behov inkluderar faktorer som tuffa miljöer, tekniska specifikationer, laddnings- och dataöverföringskrav med mera.",
        productDevSection2Part3Title: "Kostnadsuppskattning och prototyp",
        productDevSection2TextPart3: "När vi har uppnått en gemensam bild av hur produkten ska utformas presenterar vi en utvecklingskostnad. Om denna kostnad godkänns så går vi vidare med att tillverka en prototyp. Prototypen kan vara en skarp, CNC-bearbetad version eller i vissa fall, en 3D-utskriven prototyp.",
        contactUs: "Kontakta oss",
        productDevSection3Text: "Vi sätter inga begränsningar på vad ett kundspecifikt projekt kan innebära, vi har tagit fram unika lösningar till både blåljusverksamhet, livsmedelsföretag, skogsindustrin med mera.",
        seeMore: "+ Visa fler",
        seeLess: "- Visa färre",
        addArticleToCart: "Lägg till artikel",
        megaMenu: "Om ditt företag eller din verksamhet har särskilda behov och krav så är vårt team av konstruktörer redo för att samarbeta med er och skapa skräddarsydda lösningar och produkter.",
        examplesSpecialCharacters: "Exempel på specialtecken: ",
        areYouSure: "Är du säker?",
        deleteAllConfirmation: "Detta kommer ta bort alla produkter i varukorgen.",
        exVat: "exkl. moms",
        downloadComparisonPdf: "Ladda hem jämförelse som PDF",
        findProClip: "Välj en ProClip till ditt fordon",
        findDeviceHolder: "Hitta din enhetshållare",
        customerDetails: "Kunduppgifter",
        myPagesEmail: "E-post:",
        refreshToContinue: "Ladda om sidan för att fortsätta.",
        adImageAlt: "Jobb annons",
        numbersOnly: "Får endast innehålla siffror",
        smsTracking: "SMS-avisering",
        cellNumber: "Mobilnummer",
        homeSuctionTitle: "Sugproppsfästen",
        homeSuctionText: "Snabb och enkel montering på släta ytor. Möjliggör flexibla lösningar.",
        homeSuctionLinkText: "Mer om sugproppsfästen",
        homeScreenTitle: "Skärm och Tangentbordshållare",
        homeScreenText: "Ergonomiska hållare för skärm och tangentbord, med alternativ för fast eller vikbar hållare, anpassade för krävande miljöer.",
        homeScreenLinkText: "Mer om skärm och tangentbordshållare",
        homeMoveClipTitle: "MoveClip",
        homeMoveClipText: "Möjliggör enkla byten mellan olika hållare på samma monteringsplats, samt ger möjlighet att smidigt använda samma hållare på flera platser.",
        homeMoveClipLinkText: "Mer om MoveClip",
        homeReadMoreAboutStrong: "Läs mer om vårt starkare sortiment",
        contactFullname: "För- och efternamn",
        contactCompany: "Företag",
        contactPhone: "Telefon",
        contactEmail: "Epost",
        contactMessageTitle: "Vad kan vi hjälpa dig med? *",
        contactSend: "Skicka förfrågan",
        contactOfficeAndManufacturing: "Huvudkontor och tillverkning",
        contactMailSent: "Kontaktförfrågan har skickats.",
        newList: "Skapa ny favoritlista",
        productId: "Artikelnummer",
        errorCreatingFavoriteList: "En ny lista behöver namn och produkter för att kunna skapas.",
        myLists: "Mina listor:",
        selectLists: "Välj listor eller skapa en ny",
        decreaseQuantity: "Minska antal",
        increaseQuantity: "Öka antal",
        deleteList: "Ta bort lista",
        editList: "Redigera lista",
        showUnlisted: "Visa olistade",
        showAllFavorites: "Visa alla favoriter"
    }
})
