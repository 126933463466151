import {
    desktopBreakpoint, midSizeBreakpoint, mobileMenuBreakpoint
} from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    wrapper: {
        display: "flex",
        maxWidth: "1110px",
        flexWrap: "wrap",
        justifyContent: "center"
    },
    title: {
        flex: "0 0 100%",
        fontFamily: "Rubik",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "40px",
        textAlign: "center",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            marginTop: "30px"
        }
    },
    list: {
        display: "grid",
        justifyContent: "space-between",
        flex: "0 0 100%",
        gridTemplateColumns: "350px 350px 350px",
        [theme.breakpoints.down(midSizeBreakpoint)]: {
            gridTemplateColumns: "300px 300px 300px",
            justifyContent: "center",
            gap: "20px"
        },
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            gridTemplateColumns: "345px",
            justifyContent: "center",
            gap: "30px"
        },
        [theme.breakpoints.down(390)]: {
            gridTemplateColumns: "100%"
        }
    },
    item: {
        display: "flex",
        flexDirection: "column",
        borderRadius: "6px",
        boxShadow: "rgba(45, 45, 45, 0.1) 0px 0px 20px 0px",
        overflow: "hidden",
        textDecoration: "none",
        backgroundColor: "#FFFFFF",
        "&:visited": {
            color: "inherit"
        }
    },
    imageWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flex: "0 0 220px",
        backgroundColor: "#FFFFFF",
        [theme.breakpoints.down(midSizeBreakpoint)]: {
            flex: "0 0 188px"
        },
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            flex: "0 0 220px"
        }
    },
    image: {
        width: "100%"
    },
    bottomWrapper: {
        display: "flex",
        flex: "1 0 auto",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingTop: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "20px",
        backgroundColor: "#FFFFFF"
    },
    textWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    cardTitle: {
        flex: "0 0 100%",
        fontFamily: "Rubik",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "32px",
        color: "#2D2D2D",
        margin: 0,
        textDecoration: "none"
    },
    cardUnderTitle: {
        fontFamily: "Rubik",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "32px",
        color: "#2D2D2D",
        margin: 0
    },
    anchor: {
        marginTop: "8px",
        fontFamily: "Arial",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "16px",
        letterSpacing: "0.5px",
        display: "inline-block",
        position: "relative",
        color: "#2D2D2D"
    },
    isActive: {
        "&:after": {
            transform: "scaleX(1)",
            transformOrigin: "bottom left"
        }
    },
    buttonWrapperRow: {
        display: "flex",
        justifyContent: "center",
        flex: "0 0 100%"
    },
    seeMoreButton: {
        fontFamily: "Arial",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "16px",
        letterSpacing: "0.5px",
        textAlign: "left",
        marginTop: "10px",
        textTransform: "none"
    },
    newsAnchor: {
        position: "relative",
        fontFamily: "Arial",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "16px",
        letterSpacing: "0.5px",
        textAlign: "left",
        color: "#2D2D2D",
        textDecoration: "none",
        marginTop: "30px",
        display: "inline-block",
        [theme.breakpoints.up(desktopBreakpoint)]: {
            "&:after": {
                content: "''",
                position: "absolute",
                width: "100%",
                transform: "scaleX(0)",
                height: "2px",
                bottom: 0,
                left: 0,
                backgroundColor: "#F09057",
                transformOrigin: "bottom right",
                transition: "transform 0.25s ease-out"
            },
            "&:hover:after": {
                transform: "scaleX(1)",
                transformOrigin: "bottom left"
            }
        },
        [theme.breakpoints.down(desktopBreakpoint)]: {
            textDecoration: "2px underline #F09057"
        },
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            marginTop: "10px"
        }
    }
}))
