/* eslint-disable no-restricted-globals */
import useStyles from "./styles"
import { formatAmount, formatPrice } from "../../../lib/helper/numberHelper"
import { strings as Localization } from "../../../lib/Localization"
import { deleteCartRow, editCartNote, setQuantity } from "../../../redux/cartSlice"
import { useProduct, useProductsLoading } from "../../../redux/hook/productHooks"
import FavoriteButton from "../../Favorites/FavoriteButton"
import Banner from "../../Products/Banner/Banner"
import CompatibleModels from "../../Products/ProductInfo/components/CompatibleModels/CompatibleModels"
import {
    Accordion, AccordionDetails, AccordionSummary,
    Avatar, Box, Divider, IconButton, Paper,
    TextField, Tooltip, Typography,
    Skeleton
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import classnames from "classnames"
import React from "react"
import { useDispatch } from "react-redux"

const MobileProductCardOrder = ({
    id, price, currency, quantity, deliveredQuantity, isModifiable, index, text
}) => {
    const classes = useStyles()
    const product = useProduct(id)
    const loading = useProductsLoading()
    const dispatch = useDispatch()

    const quantityChanged = e => {
        const n = e.target.value !== "" ? e.target.value : 0
        const isNumber = !isNaN(parseFloat(n)) && isFinite(n)
        if (!isNumber) return
        const parsed = parseInt(n, 10)
        dispatch(setQuantity(parsed, index))
    }

    const deleteProduct = () => {
        dispatch(deleteCartRow(index))
    }

    const noteChanged = e => {
        const t = e.target.value
        dispatch(editCartNote(t, index))
    }

    // Product is not yet loaded..
    if (product === undefined && loading) {
        return (
            <Skeleton variant="rect" animation="wave" className={classes.mobileLoadingAnimation} />
        )
    }

    // When product is ordered but cannot be found return card with info
    if (product === undefined) {
        return (
            <Paper className={classes.root}>
                <div className={classes.flexRow}>
                    <div className={classes.flexItem} />
                    <div className={classes.flexItem}>
                        <div className={classes.flexColumn}>
                            <Avatar
                                slotProps={{
                                    img: {
                                        loading: "lazy"
                                    }
                                }}
                                src={`${process.env.REACT_APP_IMAGE_URL}w120/${id}.jpg`}
                                // Todo: src={`${process.env.REACT_APP_IMAGE_URL}${id}.jpg?height=100`}
                                variant="square"
                                classes={{
                                    root: classes.avatarLarge,
                                    img: classes.avatarImg
                                }}
                            />
                            <Typography variant="caption">
                                {Localization.itemNumber}
                                {" "}
                                {id}
                            </Typography>
                        </div>
                    </div>
                    <div className={classnames(classes.flexItem, classes.basis20)}>
                        <Typography variant="caption">
                            {Localization.thisProductIsNotAvailableOrder}
                        </Typography>
                        <div className={classes.flexColumn} />
                    </div>
                    <div className={classnames(classes.bottomContent, classes.flexItem)}>
                        <Typography>{text}</Typography>
                    </div>
                    <div className={classnames(classes.bottomContent, classes.flexItem)}>
                        <Typography>{formatAmount(quantity)}</Typography>
                    </div>
                    {deliveredQuantity !== undefined ? (
                        <div className={classnames(classes.bottomContent, classes.flexItem)}>
                            <Typography>{formatAmount(deliveredQuantity) || "0"}</Typography>
                        </div>
                    ) : (null)}
                    <div className={classnames(classes.bottomContent, classes.flexItem)}>
                        <Typography>{formatPrice(price)}</Typography>
                    </div>
                    <div className={classnames(classes.bottomContent, classes.flexItem)}>
                        <Typography>{formatPrice(price * quantity)}</Typography>
                    </div>
                    <div className={classnames(classes.bottomContent, classes.flexItem)}>
                        {isModifiable
                            ? (
                                <>
                                    <FavoriteButton productId={id} />
                                    <Tooltip title={Localization.removeItem}>
                                        <IconButton
                                            onClick={deleteProduct}
                                            className={classes.padding4}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )
                            : null}
                    </div>
                </div>
            </Paper>
        )
    }

    // Product was found, return a cart
    return (
        <Paper className={classes.root}>
            <Box className={classes.flexColumn}>
                <Box className={classes.mobileProductCardHeader}>
                    <Box
                        className={classnames(classes.flexRow, classes.flexItem)}
                        style={{
                            display: (product.isNew === false && product.comingSoon === false)
                                ? "none"
                                : "flex"
                        }}
                    >
                        {product.isNew
                            ? <Banner type="new" />
                            : null}
                        {product.comingSoon
                            ? <Banner type="comingSoon" />
                            : null}
                        { /* <Banner type='bestseller' /> */ /* Not in first iteration.. */}
                    </Box>
                    <Box className={classes.flexItem}>
                        <Box className={classes.flexColumn}>
                            <Avatar
                                slotProps={{
                                    img: {
                                        loading: "lazy"
                                    }
                                }}
                                src={`${process.env.REACT_APP_IMAGE_URL}w120/${id}.jpg`}
                                alt={`Brodit ${Localization.product}-${product.originalId}`}
                                // todo: w120 path for src={`${process.env.REACT_APP_IMAGE_URL}${id}.jpg?height=100`}
                                variant="square"
                                classes={{
                                    root: classes.avatarLarge,
                                    img: classes.avatarImg
                                }}
                            />
                            <Typography variant="caption">
                                {Localization.itemNumber}
                                {" "}
                                {product.originalId}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.flexItem}>
                        <Typography
                            variant="h5"
                            className={classnames(classes.heading, classes.marginBottom1)}
                        >
                            {product.name}
                        </Typography>
                        <Typography variant="h5" className={classes.heading}>
                            {Localization.note}
                            :
                        </Typography>
                        <TextField
                            value={text || ""}
                            disabled={!isModifiable}
                            onChange={noteChanged}
                        />
                    </Box>
                </Box>
                <Accordion className={(classes.root)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.accordionSummary}
                    >
                        <p className={classes.marginNone}>{Localization.moreInformation}</p>
                    </AccordionSummary>
                    <AccordionDetails
                        className={classnames(classes.marginNone, classes.flexColumn)}
                    >
                        <Typography variant="h5" className={classes.heading}>
                            {Localization.description}
                            :
                        </Typography>
                        <Typography component="p" variant="caption">
                            {product.description}
                        </Typography>
                        <Typography variant="h5" className={classes.heading}>
                            {Localization.compatibleProducts}
                            :
                        </Typography>
                        <CompatibleModels
                            compatibleModels={product.compatibleModels}
                            handleShowMoreCompsClick={null}
                        />
                    </AccordionDetails>
                </Accordion>
                <Box className={classes.mobilePriceBox}>
                    <Box>
                        <TextField
                            inputProps={{ style: { textAlign: "center" } }}
                            className={classes.mobileTextField}
                            value={quantity}
                            disabled={!isModifiable}
                            onChange={quantityChanged}
                            size="small"
                        />
                    </Box>
                    {deliveredQuantity !== undefined ? (
                        <Box className={classnames(classes.bottomContent, classes.flexItem)}>
                            <Typography>{formatAmount(deliveredQuantity) || "0"}</Typography>
                        </Box>
                    ) : (null)}
                    <Box className={classnames(classes.bottomContent, classes.flexItem)}>
                        <Typography variant="h5" className={classes.heading}>
                            {Localization.price}
                            :
                            {" "}
                            {formatPrice(price)}
                            {" "}
                            {currency}
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <Box className={classnames(classes.flexRow, classes.flexEnd)}>
                    <Box className={classnames(classes.bottomContent)}>
                        <Typography variant="h5" className={classes.heading}>
                            {Localization.total}
                            :
                            {" "}
                            {formatPrice(price * quantity)}
                            {" "}
                            {currency}
                        </Typography>
                    </Box>
                    <Box className={classnames(classes.bottomContent)}>
                        {isModifiable
                            ? (
                                <>
                                    <FavoriteButton productId={product.originalId} />
                                    <Tooltip title={Localization.removeItem}>
                                        <IconButton
                                            onClick={deleteProduct}
                                            className={classes.padding4}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )
                            : null}
                    </Box>
                </Box>
            </Box>
        </Paper>
    )
}

export default MobileProductCardOrder
