import { mobileBreakpoint, tabletBreakpoint } from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    youtubeVideoWrapper: {
        [theme.breakpoints.down(tabletBreakpoint)]: {
            flex: "0 0 70%"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            flex: "0 0 100%"

        },
        flex: "0 0 45%"
    }
}))
