export const getVehicleBrandsList = () => [
    { name: "Volvo", term: null },
    { name: "Mercedes-Benz", term: "Mercedes%20Benz" },
    { name: "Ford", term: null },
    { name: "Audi", term: null },
    { name: "BMW", term: null },
    { name: "Volkswagen", term: null },
    { name: "Tesla", term: null },
    { name: "Bentley", term: null },
    { name: "Toyota", term: null },
    { name: "Hyundai", term: null },
    { name: "Subaru", term: null },
    { name: "Suzuki", term: null },
    { name: "Chevrolet", term: null },
    { name: "Dodge", term: null },
    { name: "Lamborghini", term: null },
    { name: "Ineos", term: null },
    { name: "Mazda", term: null },
    { name: "Chrysler", term: null }
]

export const getDeviceBrandsList = () => [
    { name: "Samsung", term: null },
    { name: "Apple", term: null },
    { name: "Honeywell", term: null },
    { name: "Huawei", term: null },
    { name: "Garmin", term: null },
    { name: "OnePlus", term: null },
    { name: "ICOM", term: null },
    { name: "Google", term: null },
    { name: "Lenovo", term: null },
    { name: "HTC", term: null },
    { name: "TomTom", term: null },
    { name: "iZettle", term: null },
    { name: "Dell", term: null },
    { name: "Microsoft", term: null },
    { name: "Sonim", term: null },
    { name: "Nokia", term: null },
    { name: "Zodiac", term: null }
]
