export const formatPrice = price => {
    if (!price) return null

    // Adds spaces, commas and restrict the number of fraction digits.
    return price.toLocaleString("sv", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })
}

export const formatAmount = amount => {
    if (!amount) return null

    // Adds spaces, hide fraction digits if there are none present.
    return amount.toLocaleString("sv", {
        minimumFractionDigits: 0
    })
}
