import { CompareToggleButton, ShareButton, ReportButton } from "../../.."
import FavoriteButton from "../../../Favorites/FavoriteButton"
import { Grid, Box } from "@mui/material"
import React from "react"

export function GridButtons(props) {
    const {
        showFavorite, product, showShare, showCompare, compare, showReport
    } = props
    return (
        <>
            <Grid item>
                {showFavorite && <FavoriteButton productId={product?.originalId} />}
            </Grid>
            <Grid item>
                {showShare && <ShareButton productId={product?.originalId} />}
            </Grid>
            <Grid item>
                {showCompare && (
                    <CompareToggleButton
                        productId={product?.originalId}
                        isCompare={compare}
                    />
                )}
            </Grid>
            <Grid item>
                {showReport && <ReportButton productId={product?.originalId} />}
            </Grid>
        </>
    )
}

export function ListButtons(props) {
    const {
        showFavorite, product, showShare, showCompare, compare, showReport
    } = props

    return (
        <Box>
            {/* {props.showReport && <ReportButton productId={props.product?.originalId} />} */}
            {showFavorite && <FavoriteButton productId={product?.originalId} />}
            {showShare && <ShareButton productId={product?.originalId} />}
            {showCompare && (
                <CompareToggleButton
                    productId={product?.originalId}
                    isCompare={compare}
                />
            )}
            {showReport && <ReportButton productId={product?.originalId} />}
        </Box>
    )
}
