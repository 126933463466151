import useStyles from "./styles"
import useMaterial from "../../../../../redux/hook/materialHooks"
import NewlineText from "../Text/NewlineText"
import { strings as Localization } from "../../../../../lib/Localization"
import { Box, CircularProgress } from "@mui/material"
import Card from "@mui/material/Card"
import CardActionArea from "@mui/material/CardActionArea"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import React from "react"

export default function Material({ id }) {
    const classes = useStyles()
    const material = useMaterial(id) // lägg på lang

    if (material === undefined) return <CircularProgress />

    return (

        <Card className={classes.root}>
            <CardActionArea>
                <CardMedia title="Image">
                    <Box className={classes.mediaContainer}>
                        <img
                            loading="lazy"
                            src={`${process.env.REACT_APP_IMAGE_URL}w450/${material.originalId}.jpg`}
                            alt={`Brodit ${Localization.product}-${material.originalId}`}
                            className={classes.image}
                        />
                    </Box>
                </CardMedia>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        <span>{material.name}</span>
                    </Typography>
                    <NewlineText text={material.information} />
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
