// import { startGoogleAnalytics, stopGoogleAnalytics } from "../lib/helper/googleAnalyticsHelper"
import { createSlice } from "@reduxjs/toolkit"

const gdprSlice = createSlice({
    name: "gdpr",
    initialState: {
        hasAllowedCookies: false
    },
    reducers: {
        acceptCookies: state => {
            state.hasAllowedCookies = true
            // startGoogleAnalytics()
        },
        declineCookies: state => {
            state.hasAllowedCookies = false
            // stopGoogleAnalytics()
        }
    }
})

export default gdprSlice.reducer

export const { acceptCookies, declineCookies } = gdprSlice.actions
