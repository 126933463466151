import useStyles from "./styles"
import { mobileBreakpoint } from "../../lib/helper/viewportHelper"
import theme from "../../theme"
import { useMediaQuery, Pagination, PaginationItem } from "@mui/material"
import React from "react"

const PagePagination = ({ values, index, setIndex }) => {
    const handleSpecific = value => {
        setIndex(value)
        window.scrollTo(0, 0)
    }
    const classes = useStyles()
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint))

    return (
        <Pagination
            id="pagination"
            count={values.length}
            data-pages={values.length}
            page={Number(index) + 1}
            onChange={(_, page) => handleSpecific(page)}
            siblingCount={isMobile ? 0 : 1}
            renderItem={
                item => (
                    <PaginationItem
                        {...item}
                        page={values[item.page - 1]}
                    />
                )
            }
            className={classes.pagination}
        />
    )
}

export default PagePagination
