import { useCustomerOrder } from "../../../redux/hook/customerOrderHooks"
import OrderInfo from "../OrderInfo/OrderInfo"
import React from "react"
import { useParams } from "react-router-dom"
import { CircularProgress } from "@mui/material"

const CustomerOrderInfo = () => {
    const { order } = useParams()

    const customerOrder = useCustomerOrder(order)

    if (customerOrder === undefined) return <CircularProgress />

    return (
        <div>
            <OrderInfo customerOrder={customerOrder} isOrderConfirmation={false} />
        </div>
    )
}

export default CustomerOrderInfo
