import { useLanguage } from "../../lib/hooks/languageHooks"
import React from "react"
import { Helmet } from "react-helmet"

function SignInMetaDescription() {
    const language = useLanguage()

    const title = language === "sv"
        ? "Logga in"
        : "Sign in"

    return (
        <Helmet>
            <title>{title}</title>
            <meta
                name="description"
                content="Inloggningssida."
                lang="sv"
            />
            <meta
                name="description"
                content="Sign-in page."
                lang="en"
            />
        </Helmet>
    )
}

export default SignInMetaDescription
