// https://gist.github.com/vre2h/d620ad389580e788b7482245fa62b06c

function getCookie(cName) {
    if (document.cookie.length > 0) {
        let cStart = document.cookie.indexOf(`${cName}=`)
        if (cStart !== -1) {
            cStart = cStart + cName.length + 1
            let cEnd = document.cookie.indexOf(";", cStart)
            if (cEnd === -1) {
                cEnd = document.cookie.length
            }
            return decodeURIComponent(document.cookie.substring(cStart, cEnd))
        }
    }
    return ""
}

function createCookie(name, value, expireDate, maximumAge) {
    const expires = `; expires=${expireDate.toUTCString()}`
    const maxAge = `; max-age=${maximumAge}`
    document.cookie = `${name}=${encodeURIComponent(value)}${expires}${maxAge}; path=/`
}

export const loadCookie = key => {
    try {
        const serializedValue = getCookie(key)
        if (!serializedValue) return undefined
        return JSON.parse(serializedValue)
    } catch (err) {
        console.warn(err)
        return undefined
    }
}

export const saveCookie = (key, value) => {
    const validityInHours = 24
    const expireDate = new Date(Date.now() + (validityInHours * 60 * 60 * 1000))
    const maxAge = validityInHours * 60 * 60

    try {
        const serializedValue = JSON.stringify(value)
        createCookie(key, serializedValue, expireDate, maxAge)
    } catch (err) {
        console.warn(err)
    }
}

export function eraseCookie(name) {
    document.cookie = `${name}=; Max-Age=0; path=/`
}
