import { Box, Typography } from "@mui/material"
import React from "react"
import ReactHtmlParser from "react-html-parser"

export function GridDescription(props) {
    return (
        <Box>
            <Typography
                variant="body2"
                component="p"
                color="textPrimary"
                gutterBottom
            >
                {props?.description}
            </Typography>
            {
                props?.modTekInformation
                && (
                    <ModTekDescription modTekInformation={props?.modTekInformation} />
                )
            }
        </Box>
    )
}

export function ListDescription(props) {
    return (
        <Box>
            <Typography
                component="p"
                variant="caption"
            >
                {props?.description}
            </Typography>
            {
                props?.modTekInformation
                && (
                    <ModTekDescription modTekInformation={props?.modTekInformation} />
                )
            }
        </Box>
    )
}

export function ModTekDescription({ sideModule = false, modTekInformation = "" }) {
    return (
        sideModule
            ? (
                <Box>
                    <Typography
                        component="p"
                        variant="caption"
                    >
                        {ReactHtmlParser(modTekInformation)}
                    </Typography>
                </Box>
            )
            : (
                <Box marginTop="2rem">
                    <Typography
                        component="p"
                        variant="body2"
                    >
                        {ReactHtmlParser(modTekInformation)}
                    </Typography>
                </Box>
            )
    )
}
