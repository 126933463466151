import ExpandableHeader from "./ExpandableHeader"
import PropertiesList from "./PropertiesList"
import useStyles from "../../styles"
import { groupBy } from "../../../../../lib/helper/commonHelper"
import React, { useMemo, useState } from "react"
import { Box, List } from "@mui/material"

const PropertyGroup = ({
    groupName, properties, handleToggle, checkIfSelected
}) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const propertiesPerName = useMemo(
        () => groupBy(properties, property => property.name),
        [properties]
    )

    return (
        <Box flexDirection="column" display="flex">
            <ExpandableHeader open={open} setOpen={setOpen} text={groupName} />
            {
                open && (
                    <List className={classes.listRoot}>
                        {Object.entries(propertiesPerName).map(([name, props], index) => (
                            <PropertiesList
                                key={index}
                                title={name}
                                properties={props}
                                handleToggle={handleToggle}
                                checkIfSelected={checkIfSelected}
                            />
                        ))}
                    </List>
                )
            }
        </Box>
    )
}

export default PropertyGroup
