import { makeStyles } from "@mui/styles"

export default makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        justifyContent: "space-between",
        alignItems: "center"
    }
}))
