import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper
    },
    paper: {
        padding: theme.spacing(2)
    },
    avatarImg: {
        width: "100%",
        height: "auto",
        objectFit: "initial"
    },
    clearAllListItem: {
        margin: 0
    },
    clearAllPrimary: {
        fontSize: "0.8rem"
    },
    clearAllSecondary: {
        fontSize: "0.7rem"
    }
}))
