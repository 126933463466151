import Thumbnail from "./Thumbnail"
import NavigationButton from "./components/NavigationButton"
import useStyles from "./styles"
import { useGetContentQuery } from "../../redux/services/contentApi"
import React from "react"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"
import { Box, Skeleton } from "@mui/material"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

function ThumbnailStepper({ thumbnailsUrl, style, srcWidth }) {
    const [currentIndex, setCurrentIndex] = React.useState(0)
    const [isHovering, setIsHovering] = React.useState(false)
    const { data, isFetching } = useGetContentQuery(thumbnailsUrl)
    const thumbnails = data ?? []
    const classes = useStyles()

    const handleNext = () => {
        setCurrentIndex(previousIndex => (
            (previousIndex + 1) % thumbnails.length
        ))
    }

    const handleBack = () => {
        setCurrentIndex(previousIndex => (
            (previousIndex > 0)
                ? previousIndex - 1
                : thumbnails.length - 1
        ))
    }

    const handleStepChange = index => {
        setCurrentIndex(index)
    }

    const handleMouseEnter = () => setIsHovering(true)
    const handleMouseLeave = () => setIsHovering(false)
    const hideOnHover = { opacity: isHovering ? 1 : 0, transition: "opacity 0.5s" }

    return (
        (!isFetching && thumbnails)
            ? (
                <Box
                    className={classes.thumbNailStepper}
                    style={{ ...style }}
                >
                    <Box
                        className={classes.thumbNailStepperMain}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <NavigationButton
                            style={{ ...hideOnHover }}
                            onClick={handleBack}
                            id="backBtn"
                        />
                        <AutoPlaySwipeableViews
                            enableMouseEvents
                            axis="x"
                            index={currentIndex}
                            onChangeIndex={handleStepChange}
                            interval={4500}
                            id="autoPlaySwipeableViews"
                        >
                            {
                                thumbnails.map(thumbnailProps => {
                                    const idArray = thumbnailProps.content[0].image.split(".").at(0).split("/")
                                    const id = `${idArray.at(-2)}-${idArray.at(-1)}`
                                    return (
                                        <Thumbnail
                                            key={thumbnailProps.content[0].image}
                                            thumbNailId={`${id}-thumbnail`}
                                            {...thumbnailProps}
                                            loading={isFetching}
                                            width="100%"
                                            srcWidth={srcWidth}
                                        />
                                    )
                                })
                            }
                        </AutoPlaySwipeableViews>
                        <NavigationButton
                            id="nextBtn"
                            rightSide
                            onClick={handleNext}
                            style={{ ...hideOnHover }}
                        />
                    </Box>
                    <Box display="flex" id="btnList">
                        {
                            thumbnails.map((_, i) => {
                                const idArray = _.content[0].image.split(".").at(0).split("/")
                                const id = `${idArray.at(-2)}-${idArray.at(-1)}`
                                return (
                                    <Box
                                        key={_.content[0].image}
                                        id={id}
                                        className={classes.thumbNailIndicator}
                                        onClick={() => handleStepChange(i)}
                                    >
                                        <Box
                                            bgcolor={
                                                (i === currentIndex)
                                                    ? "primary.main"
                                                    : "text.disabled"
                                            }
                                            className={classes.thumbNailIndicatorCircle}
                                        />
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>
            )
            : <Skeleton style={{ height: "400px" }} />
    )
}

export default ThumbnailStepper
