/* eslint-disable no-plusplus */
export const modelNameCompare = (modelNameA, modelNameB) => {
    const a = modelNameA.toUpperCase()
    const b = modelNameB.toUpperCase()

    const expression = /(\D*)(\d*)/
    const aParts = a.split(expression).filter(Boolean)
    const bParts = b.split(expression).filter(Boolean)

    for (let i = 0; i < aParts.length && i < bParts.length; i++) {
        if (aParts[i].localeCompare(bParts[i]) !== 0) {
            const aPartAsInt = parseInt(aParts[i], 10)
            const bPartAsInt = parseInt(bParts[i], 10)
            // If one or more part is not an integer
            if (!aPartAsInt || !bPartAsInt) {
                return a.localeCompare(b)
            }

            const diff = aPartAsInt - bPartAsInt
            if (diff !== 0) {
                return diff
            }
        }
    }

    const compareNumber = `${a.length}`.localeCompare(`${b.length}`)
    return Math.sign(compareNumber)
}

export const sortModels = modelList => [...modelList].sort(
    (a, b) => modelNameCompare(a.name, b.name)
)
