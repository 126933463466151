import useStyles from "./styles"
import { strings as Localization } from "../../lib/Localization"
import LargeLogo from "../../assets/largelogo.png"
import React from "react"

const ErrorPage = () => {
    const classes = useStyles()

    return (
        <div className={classes.content}>
            <img className={classes.logo} alt="Brodit logotype" loading="lazy" src={LargeLogo} />
            <h2 className={classes.title}>{Localization.somethingWentWrong}</h2>
            <div className={classes.row}>{Localization.pleaseTryAgainLaterOrContactBroditsSupportIfTheProblemPersists}</div>
            <div className={classes.row}>{Localization.refreshToContinue}</div>
        </div>
    )
}

export default ErrorPage
