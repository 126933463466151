import { useUserAuthToken } from "./userHooks";
import { requestCustomerOrders, requestCustomerOrder } from "../thunk/customerOrderThunk";
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";

const useCustomerOrders = (offset, limit) => {
    const dispatch = useDispatch();
    const authToken = useUserAuthToken();

    useEffect(() => {
        const params = { authToken, offset, limit }
        dispatch(requestCustomerOrders(params));
    }, [offset, limit, authToken, dispatch]);

    return useSelector(state => state.customerOrder.list);
}

const useCustomerOrder = id => {
    const dispatch = useDispatch();
    const authToken = useUserAuthToken();

    useEffect(() => {
        const params = { authToken, id };
        dispatch(requestCustomerOrder(params));
    }, [id, dispatch]);

    return useSelector(state => state.customerOrder.list).find(x => x.OrderNumber === id);
}

export { useCustomerOrder, useCustomerOrders };
