import { makeStyles } from "@mui/styles"

const drawerWidth = "100%"

export default makeStyles(() => ({
    appMenu: {
        width: "auto"
    },
    navList: {
        width: drawerWidth
    },
    menuItem: {
        width: drawerWidth
    },
    menuItemIcon: {
        color: "#97c05c"
    },
    drawer: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "0",
        top: "auto",
        zIndex: -1,
        backgroundColor: "#FFFFFF",
        transition: "all 0.1s ease-in",
        boxShadow: "0px 0px 5px 0px rgba(45, 45, 45, 0.05)"
    },
    open: {
        transform: "translateY(99%)"
    },
    backButton: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#F8F8F8",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "20px",
        border: 0,
        cursor: "pointer",
        fontFamily: "Arial",
        fontSize: "14px",
        fontWeight: 400,
        letterSpacing: "0.25px",
        gap: "5px",
        color: "#2d2d2d"
    },
    collectionTitle: {
        margin: 0,
        textAlign: "right",
        paddingTop: "20px",
        paddingRight: "20px",
        paddingLeft: "20px",
        paddingBottom: "14px",
        fontFamily: "Arial",
        fontSize: "17px",
        fontWeight: 700,
        lineHeight: "20px",
        letterSpacing: "0.5px"
    },
    readMoreLink: {
        textDecoration: "none",
        padding: "10px 20px 10px 20px",
        background: "#F09057",
        fontFamily: "Arial",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "16px",
        letterSpacing: "0.5px",
        textAlign: "center",
        borderRadius: "4px",
        transition: "all 0.1s ease-in",
        color: "#2D2D2D",
        "&:hover": {
            backgroundColor: "#ef7830"
        }
    }
}))
