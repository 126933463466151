import useStyles from "./styles"
import { Box } from "@mui/material"
import React from "react"

function YoutubeVideo({
    link,
    ...props
}) {
    const classes = useStyles()

    return (
        <Box
            className={classes.youtubeVideoWrapper}
            {...props}
        >
            <iframe
                style={{
                    aspectRatio: "16 / 9",
                    width: "100%"
                }}
                title={link}
                width="auto"
                height="auto"
                loading="lazy"
                src={link}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </Box>
    )
}

export default YoutubeVideo
