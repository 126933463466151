import { tabletBreakpoint } from "../../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        justifyContent: "center",
        margin: theme.spacing(2)
    },
    "@keyframes rotate": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(180deg)"
        }
    },
    working: {
        animation: `$rotate 3000ms ${theme.transitions.easing.easeInOut} infinite`
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column",
        width: 350,
        marginBottom: 40,
        [theme.breakpoints.down(tabletBreakpoint)]: {
            width: "auto"
        }
    },
    column: {
        flex: "1 0 350px",
        display: "flex",
        flexDirection: "column"
    },
    addressWrapper: {
        padding: 20
    },
    half: {
        flex: "0 1 50%"
    },
    capitalized: {
        textTransform: "uppercase"
    },
    tempAddressLabel: {
        fontSize: 12
    },
    flexRow: {
        display: "flex",
        flexDirection: "row"
    },
    spaceEven: {
        justifyContent: "space-evenly"
    },
    full: {
        width: "100%"
    },
    gap1: {
        gap: "1rem"
    },
    userInfoWrapper: {
        display: "flex",
        flexWrap: "wrap",
        fontFamily: "Arial",
        gap: "30px"
    },
    space: {
        gap: "50px"
    }
}))
