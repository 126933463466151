import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    capitalized: {
        textTransform: "uppercase"
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column"
    },
    listArea: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2)
    },
    infoText: {
        width: 250
    },
    infoArea: {
        marginBottom: 50
    },
    flexRow: {
        display: "flex",
        flexDirection: "row"
    },
    flexItem: {
        flex: "1 1 0px"
    },
    headerRoot: {
        padding: 10
    },
    gray: {
        color: "#a3a3a3"
    },
    root: {
        padding: 10,
        marginBottom: 32,
        boxShadow: "none"
    },
    flexWrap: {
        flexWrap: "wrap",
        justifyContent: "space-between",
        gap: "2rem"
    },
    wrapItem: {
        marginBottom: "1rem",
        "& .MuiTypography-h6": {
            display: "inline"
        }
    },
    bold: {
        fontWeight: "bold"
    },
    textCenter: {
        alignItems: "center"
    }
}));
