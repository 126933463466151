import { setNextSnackPack } from "../../redux/userMessageSlice"
import React, { useEffect, useState } from "react"
import {
    Box, Button, Dialog, DialogActions, DialogContent, Snackbar,
    Alert
} from "@mui/material"

import { useDispatch, useSelector } from "react-redux"

const MessageNotification = () => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const snackPack = useSelector(state => state.userMessage.snackPack)
    const [messageInfo, setMessageInfo] = useState(undefined)
    const autoHideDuration = messageInfo?.autoHide ? 3500 : null

    useEffect(() => {
        if (snackPack.length && !messageInfo) {
            setMessageInfo({ ...snackPack[0] })
            dispatch(setNextSnackPack())
            setOpen(true)
        } else if (snackPack.length && messageInfo && open) {
            setOpen(false)
        }
    }, [snackPack, messageInfo, open, dispatch])

    const closeSnackbar = () => {
        setOpen(false)
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") return
        closeSnackbar()
    }

    const handleExited = () => {
        setMessageInfo(undefined)
    }

    const messageProps = {
        key: messageInfo?.key,
        open: open,
        onClose: handleClose,
        autoHideDuration
    }

    const transitionProps = {
        onExited: handleExited
    }

    return (
        <Box>
            {
                messageInfo?.type === "snackbar"
                && (
                    <Snackbar
                        TransitionProps={transitionProps}
                        {...messageProps}
                        style={{ top: 50 }}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                        <Alert
                            onClose={closeSnackbar}
                            severity={messageInfo?.severity}
                        >
                            {messageInfo?.message}
                        </Alert>
                    </Snackbar>
                )
            }
            {
                messageInfo?.type === "dialog"
                && (
                    <Dialog {...messageProps} scroll="paper">
                        <DialogContent>{messageInfo?.message}</DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary" autoFocus>
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
            }
        </Box>
    )
}

export default MessageNotification
