import { NavigationButtonWrapper } from "./NavigationButton"
import React from "react"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"
import { Box, Skeleton } from "@mui/material"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

function Stepper({
    style,
    index,
    setIndex,
    handleNext,
    handleBack,
    handleStepChange,
    pages,
    loading,
    showNavigationOnHover,
    positionNavigationOnTop,
    backButtonComponent,
    nextButtonComponent,
    disabledSwipe,
    autoplay = false,
    ...remainingProps
}) {
    const [isHovering, setIsHovering] = React.useState(false)

    const handleNavigateNext = () => {
        if (handleNext) handleNext()
        else {
            setIndex(previousIndex => (
                (previousIndex + 1) % pages.length
            ))
        }
    }

    const handleNavigateBack = () => {
        if (handleBack) handleBack()
        else {
            setIndex(previousIndex => (
                (previousIndex > 0)
                    ? previousIndex - 1
                    : pages.length - 1
            ))
        }
    }

    const handleNavigateStepChange = newStep => {
        if (handleStepChange) handleStepChange(newStep)
        else setIndex(newStep)
    }

    const handleMouseEnter = () => setIsHovering(true)
    const handleMouseLeave = () => setIsHovering(false)

    return (
        (!loading)
            ? (
                <Box
                    style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        position: "relative",
                        ...style
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <NavigationButtonWrapper
                        positionOnTop={positionNavigationOnTop}
                        onClick={handleNavigateBack}
                        isHovering={isHovering}
                        showNavigationOnHover={showNavigationOnHover}
                        customComponent={backButtonComponent}
                    />
                    <AutoPlaySwipeableViews
                        style={{
                            flexGrow: 1
                        }}
                        autoplay={autoplay}
                        enableMouseEvents
                        axis="x"
                        index={index}
                        onChangeIndex={handleNavigateStepChange}
                        interval={4500}
                        disabled={disabledSwipe}
                        {...remainingProps}
                    >
                        {pages}
                    </AutoPlaySwipeableViews>
                    <NavigationButtonWrapper
                        positionOnTop={positionNavigationOnTop}
                        onClick={handleNavigateNext}
                        isHovering={isHovering}
                        showNavigationOnHover={showNavigationOnHover}
                        customComponent={nextButtonComponent}
                        rightSide
                    />
                </Box>
            )
            : <Skeleton style={{ ...style }} />
    )
}

export default Stepper
