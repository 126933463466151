import DynamicContent from "./DynamicContent/DynamicContent"
import { desktopBreakpoint } from "../../lib/helper/viewportHelper"
import { Container, useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import { useParams } from "react-router-dom"

const DynamicContentPage = ({ type }) => {
    const { type: parameterType } = useParams()
    const theme = useTheme()
    const selectedType = parameterType ?? type
    const isDesktop = useMediaQuery(t => t.breakpoints.up(desktopBreakpoint))

    return (
        <main>
            <Container
                style={{
                    backgroundColor: theme.palette.background.default,
                    padding: 0,
                    minHeight: isDesktop ? 600 : 0
                }}
            >
                <DynamicContent
                    type={selectedType}
                    style={{
                        maxWidth: 800
                    }}
                />
            </Container>
        </main>
    )
}

export default DynamicContentPage
