/* eslint-disable import/prefer-default-export */
import { selectById } from "../adapter/materialAdapter"
import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const internalLangConvert = lang => (lang === "en" ? "EN1" : "SV1")

const fetchMaterial = async (id, lang) => {
    const a = await axios.get(
        `/api/ProductInfo/MaterialDetails?materialId=${id}&lang=${internalLangConvert(lang)}`
    )
    return a.data
}

export const requestMaterial = createAsyncThunk(
    "material/fetchById",
    async params => {
        const { id, lang } = params
        return fetchMaterial(id, lang)
    },
    {
        condition: (params, { getState }) => {
            const { id, lang } = params
            const existing = selectById(getState(), `${lang}-${id}`)
            return !existing
        }
    }
)
