import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        minHeight: 450
    },
    paper: {
        marginTop: theme.spacing(4)
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main
    },
    paperPadding: {
        padding: theme.spacing(3)
    },
    paperFlex: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    capitalized: {
        textTransform: "uppercase"
    },
    textAlignCenter: {
        textAlign: "center"
    },
    removeAutofillBg: {
        "& input": {
            "&:-webkit-autofill": {
                "-webkit-box-shadow": "0 0 0 1000px white inset",
                "-webkit-text-fill-color": theme.palette.text.primary
            },
            "&:-webkit-autofill:hover": {
                "-webkit-box-shadow": "0 0 0 1000px white inset",
                "-webkit-text-fill-color": theme.palette.text.primary
            },
            "&:-webkit-autofill:focus": {
                "-webkit-box-shadow": "0 0 0 1000px white inset",
                "-webkit-text-fill-color": theme.palette.text.primary
            },
            "&:-webkit-autofill:active": {
                "-webkit-box-shadow": "0 0 0 1000px white inset",
                "-webkit-text-fill-color": theme.palette.text.primary
            }
        }
    }
}));
