import { tabletBreakpoint } from "../../../../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    root: {
        width: "100%",
        marginBottom: theme.spacing(5),
        boxShadow: "none",
        "&:hover > *": {
            pointerEvents: "none"
        }
    },
    mediaContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        display: "flex",
        justifyContent: "center"
    },
    textBody: {
        lineHeight: "1.8"
    },
    image: {
        height: 200,
        [theme.breakpoints.down(tabletBreakpoint)]: {
            height: "auto",
            maxWidth: "100%"
        }
    }
}))
