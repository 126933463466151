import { useLanguage } from "../../../lib/hooks/languageHooks"
import { useIsUserAuthorized } from "../../../redux/hook/userHooks"
import { mobileMenuBreakpoint } from "../../../lib/helper/viewportHelper"
import { styled } from "@mui/styles"
import React, { useEffect, useState } from "react"
import InfoIcon from "@mui/icons-material/Info"

const MessageBoxBase = styled("div")(({ theme }) => (
    {
        flex: "0 1 1600px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        padding: "16px 20px",
        backgroundColor: "#FFFFFF",
        color: "#2D2D2D",
        fontFamily: "Arial",
        gap: "5px",
        marginTop: "16px",
        borderRadius: "10px",
        border: "1px solid #f09057",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            flex: "0 1 80%"
        }
    }
))

const Title = styled("div")(() => (
    {
        flex: "0 0 100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "17px",
        fontWeight: 700,
        gap: "8px"
    }
))

const Paragraph = styled("p")(() => (
    {
        flex: "0 0 100%",
        fontSize: "14px",
        margin: "5px 0"
    }
))

const MessageBox = () => {
    const language = useLanguage()
    const isAuthorized = useIsUserAuthorized()
    const [message, setMessage] = useState(null)

    const fetchAnnouncementMessage = async () => {
        const response = await fetch(`/files/DynamicPages/AnnouncementMessage-${language}.json`)
        return response.json()
    }

    useEffect(() => {
        let isMounted = true

        const fetchMessage = async () => {
            const result = await fetchAnnouncementMessage()
            if (isMounted) setMessage(result)
        }

        fetchMessage()

        return () => {
            isMounted = false
        }
    }, [language])

    return isAuthorized && message && message.enabled ? (
        <MessageBoxBase>
            <Title>
                <InfoIcon />
                {message.title}
            </Title>
            {message.text && message.text.map((text, i) => <Paragraph key={`msg-text-${i}`}>{text}</Paragraph>)}
        </MessageBoxBase>
    ) : null
}

export default MessageBox
