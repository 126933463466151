import { createSlice } from "@reduxjs/toolkit"

export const cartSlice = createSlice({
    name: "cart",
    initialState: {
        order: {
            step: 0,
            number: ""
        },
        items: [],
        shouldSync: false
    },
    reducers: {
        // Set to a determined quantity (needed in cart view)
        setQty: (state, action) => ({
            ...state,
            items: state.items.map((item, i) => (
                i === action.payload.index ? { ...item, quantity: action.payload.quantity } : item
            )),
            shouldSync: true
        }),
        add: (state, action) => ({
            ...state,
            items: [...state.items, { id: action.payload.id, quantity: action.payload.quantity }],
            shouldSync: true
        }),
        setItems: (state, action) => {
            state.items = action.payload
            state.shouldSync = true
        },
        del: (state, action) => ({
            ...state,
            items: [...state.items.filter((element, index) => index !== action.payload)],
            shouldSync: true
        }),
        empty: state => ({
            ...state,
            items: [],
            shouldSync: true
        }),
        editNote: (state, action) => ({
            ...state,
            items: state.items.map((item, i) => (
                i === action.payload.index ? { ...item, note: action.payload.text } : item
            )),
            shouldSync: true
        }),
        setShouldSync: (state, action) => {
            state.shouldSync = action.payload
        },
        nextOrderStep: state => ({
            ...state,
            order: {
                step: state.order.step + 1,
                number: state.order.number
            }
        }),
        prevOrderStep: state => ({
            ...state,
            order: {
                step: state.order.step - 1,
                number: state.order.number
            }
        }),
        putOrderId: (state, action) => ({
            ...state,
            order: {
                step: state.order.step,
                number: action.payload
            }
        }),
        resetOrderStep: state => ({
            ...state,
            order: {
                step: 0,
                number: state.order.number // Varför behålla egentligen
            }
        }),
        batchAdd: (state, action) => ({
            ...state,
            items: action.payload.map(item => ({ id: item.article, quantity: item.quantity })),
            shouldSync: true
        })
    }
})

export default cartSlice.reducer

const {
    add, setItems, setShouldSync, del, empty,
    setQty, nextOrderStep, prevOrderStep, putOrderId,
    resetOrderStep, editNote, batchAdd
} = cartSlice.actions

export const addToCart = (id, quantity) => dispatch => dispatch(
    add({
        id,
        quantity
    })
)

export const setCartItems = items => dispatch => dispatch(
    setItems(items)
)

export const setShouldSyncCart = shouldSync => dispatch => dispatch(
    setShouldSync(shouldSync)
)

export const setQuantity = (quantity, index) => dispatch => dispatch(
    setQty({
        quantity,
        index
    })
)

export const deleteCartRow = index => dispatch => dispatch(
    del(index)
)

export const editCartNote = (text, index) => dispatch => dispatch(
    editNote({
        text,
        index
    })
)

export const emptyCart = () => dispatch => dispatch(empty())

export const nextCartStep = () => dispatch => dispatch(nextOrderStep())

export const prevCartStep = () => dispatch => dispatch(prevOrderStep())

export const setOrderId = id => dispatch => dispatch(putOrderId(id))

export const resetCartStep = () => dispatch => dispatch(resetOrderStep())

export const batchAddToCart = items => dispatch => dispatch(batchAdd(items))
