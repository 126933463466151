import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    listItem: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        width: "100%",
        textDecoration: "none",
        color: theme.palette.common.black,
        textAlign: "end"
    }
}))
