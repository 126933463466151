import { requestCollectionsForBrand } from "./thunk/collectionThunk";
import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "collection",
    initialState: [],
    reducers: {},
    extraReducers: builder => {
        builder.addCase(requestCollectionsForBrand.fulfilled, (state, action) => action.payload);
    }
});

export default slice.reducer;
