import React, { forwardRef } from "react"
import { NavLink } from "react-router-dom"
import { ListItem } from "@mui/material"

const createNavLinkComponent = link => forwardRef((props, ref) => <NavLink to={link} {...props} ref={ref} />)

const AppMenuItemComponent = ({ className, link, children }) => {
    if (!link || typeof link !== "string") {
        return (
            <ListItem button className={className}>
                {children}
            </ListItem>
        )
    }

    const NavLinkComponent = createNavLinkComponent(link)

    return (
        <ListItem
            button
            className={className}
            component={NavLinkComponent}
        >
            {children}
        </ListItem>
    )
}

export default AppMenuItemComponent
