/* eslint-disable import/no-extraneous-dependencies */
import { broditWebApi } from "./services/broditWebApi"
import api from "./middleware/api"
import { loadReducers } from "./localStorage"
import { rootReducer } from "./rootReducer"
import { contentApi } from "./services/contentApi"
import { cartApi } from "./services/cartApi"
import { favoriteApi } from "./services/favoriteApi"
import { priceApi } from "./services/priceApi"
import { productInfoApi } from "./services/productInfoApi"
import { setupListeners } from "@reduxjs/toolkit/query"
import { configureStore } from "@reduxjs/toolkit"
import thunk from "redux-thunk"

import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["compareProduct"]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    preloadedState: {
        ...loadReducers()
    },
    middleware: getDefaultMiddleware => [
        ...getDefaultMiddleware({
            serializableCheck: false
        }).concat([
            broditWebApi.middleware,
            contentApi.middleware,
            cartApi.middleware,
            favoriteApi.middleware,
            priceApi.middleware,
            productInfoApi.middleware
        ]),
        thunk,
        api
    ]
})

const persistor = persistStore(store)

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors (@reduxjs/toolkit/query)
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export { store, persistor }
