import { useLoadPrices } from "./priceHooks"
import { sendGAEventProductSearch } from "../../lib/helper/googleAnalyticsHelper"
import { useLanguage } from "../../lib/hooks/languageHooks"
import { selectAll, selectById } from "../adapter/productAdapter"
import { requestProduct, requestProducts, requestFilteredProducts } from "../thunk/productThunk"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"

const useProduct = id => {
    const dispatch = useDispatch()
    const lang = useLanguage()

    useEffect(() => {
        const params = { id, lang }
        dispatch(requestProduct(params))
    }, [id, lang, dispatch])

    const product = useSelector(state => selectById(state, `${lang}-${id}`))
    useLoadPrices(
        product?.originalId
            ? [product.originalId]
            : []
    )

    return product
}

const useProducts = ids => {
    const dispatch = useDispatch()
    const lang = useLanguage()

    useEffect(() => {
        const params = { ids, lang }
        dispatch(requestProducts(params))
    }, [ids, lang, dispatch])

    const products = useSelector(state => selectAll(state))

    useLoadPrices(ids.map(item => item.id))

    return products
}

/**
 * Fetches and returns the products that matched the given filters.
 * @param {*} filters A search query object. (Refer to the backend model
 *  for exact values. Accepts the structure specified in the searchReducer)
 * @param {*} pagination A pagination object containing offset and limit.
 * @returns The language specific products that matched the search query.
 */
const useFilteredProducts = (filters, pagination) => {
    const dispatch = useDispatch()
    const lang = useLanguage()

    useEffect(() => {
        const promise = dispatch(requestFilteredProducts({ filters, pagination, lang }))
        sendGAEventProductSearch()

        return () => {
            promise.abort()
        }
    }, [filters, pagination, lang, dispatch])

    const products = useSelector(
        state => (
            selectAll(state)
                .filter(
                    product => state.product.lastProductFetch.includes(product.originalId)
                ).filter(
                    product => product.id.startsWith(lang)
                )
        )
    )
    useLoadPrices(products.map(product => product.originalId))
    return products
}

const useLastFetchedProductProperties = () => useSelector(
    state => state.product.lastFetchProperties
)

const useProductsLoading = () => useSelector(state => state.product.loading)

const useLastFetchItemCount = () => useSelector(state => state.product.lastFetchItemCount)

export {
    useProduct,
    useProducts,
    useFilteredProducts,
    useLastFetchedProductProperties,
    useLastFetchItemCount,
    useProductsLoading
}
