import NavButtonIcon from "./NavButtonIcon"
import useStyles from "../styles"
import { Button } from "@mui/material"
import React from "react"

const NavButton = ({
    onClick, minHeight, right, iconStyle, style
}) => {
    const classes = useStyles()
    return (
        <Button
            style={{
                minHeight: minHeight,
                ...style
            }}
            onClick={onClick}
            classes={{ root: classes.buttonRoot }}
        >
            <NavButtonIcon right={right} style={iconStyle} />
        </Button>
    )
}

export default NavButton
