import DesktopOrderPaper from "./DesktopOrderPaper"
import MobileOrderPaper from "./MobileOrderPaper"
import { tabletBreakpoint } from "../../../lib/helper/viewportHelper"
import theme from "../../../theme"
import { useMediaQuery } from "@mui/material"
import React from "react"

const CustomerOrder = ({ customerOrder, headerList }) => {
    const isSmall = useMediaQuery(theme.breakpoints.down(tabletBreakpoint))

    const getDateFormat = d => {
        const date = new Date(d)
        return date.toLocaleDateString()
    }

    // slice tar allt i listan efter index 4
    const mobileHeaderList = headerList.slice(4)

    const orderData = [
        { header: `${mobileHeaderList[0]}`, data: customerOrder.YourOrderNumber ?? "" },
        { header: `${mobileHeaderList[1]}`, data: customerOrder.YourReference ?? "" },
        { header: `${mobileHeaderList[2]}`, data: customerOrder.TotalOrderLines ?? "" },
        { header: `${mobileHeaderList[3]}`, data: customerOrder.TotalProducts ?? "" },
        { header: `${mobileHeaderList[4]}`, data: customerOrder.TotalSum ?? "" }
    ]

    return (
        isSmall
            ? <MobileOrderPaper orderData={orderData} getDateFormat={getDateFormat} customerOrder={customerOrder} />
            : <DesktopOrderPaper orderData={orderData.map(({ data }) => data)} getDateFormat={getDateFormat} customerOrder={customerOrder} />
    )
}

export default CustomerOrder
