/* eslint-disable react/destructuring-assignment */
import { Box } from "@mui/material"
import React from "react"

const ShowMore = props => {
    const { showAll, open } = props
    const items = (open || showAll) ? props.children : []
    return (
        <Box>
            {items}
        </Box>
    )
}

export default ShowMore
