import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import remarkGfm from "remark-gfm"
import { Box, Typography } from "@mui/material"

const components = {
    h1: ({ _node, ...elementProps }) => (
        <Typography variant="h2" style={{ marginBottom: 10 }} {...elementProps} />
    ),
    h2: ({ _node, ...elementProps }) => (
        <Typography variant="h2" style={{ marginBottom: 10 }} {...elementProps} />
    ),
    h3: ({ _node, ...elementProps }) => (
        <Typography variant="h3" style={{ marginBottom: 10 }} {...elementProps} />
    ),
    h4: ({ _node, ...elementProps }) => (
        <Typography variant="h4" style={{ marginBottom: 10 }} {...elementProps} />
    ),
    h5: ({ _node, ...elementProps }) => (
        <Typography variant="h5" {...elementProps} />
    ),
    p: ({ _node, ...elementProps }) => (
        <Typography style={{ padding: "5px 5px 5px 5px", textAlign: "justify" }} variant="body2" {...elementProps} />
    ),
    img: ({ _node, ...elementProps }) => (
        <Box style={{ width: "100%" }}>
            <img alt="" style={{ width: "100%" }} {...elementProps} />
        </Box>
    )
}

const StyledMarkdown = ({ style, ...remainingProps }) => (
    <Box style={style}>
        <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}
            components={components}
            {...remainingProps}
        />
    </Box>
)

export default StyledMarkdown
