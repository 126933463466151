import { makeStyles } from "@mui/styles"

export default makeStyles(() => ({
    content: {
        display: "flex",
        fontFamily: "Arial",
        backgroundColor: "#F8F8F8",
        marginTop: "30px",
        padding: "30px",
        borderRadius: "10px",
        maxWidth: "400px",
        flexWrap: "wrap",
        justifyContent: "center"
    },
    logo: {
        width: "200px"
    },
    title: {
        flex: "0 0 100%",
        marginTop: "40px",
        textAlign: "center"
    },
    row: {
        flex: "0 0 100%",
        marginTop: "5px",
        marginBottom: "5px",
        textAlign: "center"
    },
    button: {
        marginTop: "40px",
        borderRadius: "5px",
        backgroundColor: "#f09057",
        border: 0,
        padding: "5px 20px 5px 20px",
        fontWeight: 600,
        transition: "all 0.1s ease-in",
        textDecoration: "none",
        color: "#2d2d2d",
        "&:hover": {
            backgroundColor: "#ef7830"
        }
    }
}))
