import { Box } from "@mui/material"
import React from "react"

const TabPanel = props => {
    const {
        children, value, index, ...other
    } = props

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </Box>
    )
}

export default TabPanel
