import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    "@keyframes rotate": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(180deg)"
        }
    },
    working: {
        animation: `$rotate 1s ${theme.transitions.easing.easeInOut} infinite`
    },
    buttonRoot: {
        minWidth: "auto"
    },
    outlinedSmall: {
        padding: "1px 9px"
    },
    qtyField: {
        "& .MuiOutlinedInput-inputMarginDense": {
            paddingTop: 5,
            paddingBottom: 5
        }
    },
    input: {
        fontSize: "1rem!important"
    },
    textField: {
        height: "31px",
        "& .MuiInput-root": {
            paddingBottom: "0px!important"
        },
        "& .MuiOutlinedInput-inputAdornedEnd": {
            paddingRight: "0px!important"
        },
        "& .MuiOutlinedInput-inputMarginDense": {
            paddingTop: "0px!important",
            paddingBottom: "0px!important"
        },
        "& .MuiOutlinedInput-input": {
            padding: "0px!important"
        },
        "& .MuiInputBase-inputMarginDense": {
            paddingTop: "0px!important"
        },
        "& .MuiInputBase-input": {
            padding: "0px!important",
            paddingLeft: "4px!important",
            textAlign: "center",
            height: "29px",
            width: 70
        }
    },
    quickshopWrapper: {
        display: "flex",
        position: "fixed",
        bottom: "20px",
        left: "20px",
        right: "20px",
        height: "40px",
        borderRadius: "20px 20px 20px 20px",
        backgroundColor: "transparent",
        alignItems: "center",
        justifyContent: "flex-end",
        overflow: "hidden"
    },
    hide: {
        visibility: "hidden"
    },
    quickshopButton: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
        backgroundColor: "#F09057",
        justifySelf: "flex-end",
        borderRadius: "50%",
        border: 0,
        cursor: "pointer",
        transition: "0.1s all ease-in"
    },
    quickshopContent: {
        fontFamily: "rubik",
        display: "flex",
        alignItems: "center",
        height: "100%",
        width: 0,
        backgroundColor: "#F09057",
        transition: "0.2s all ease-out",
        borderRadius: "20px",
        overflow: "hidden",
        paddingLeft: "20px",
        gap: "5px",
        maxWidth: "300px"
    },
    open: {
        width: "100%",
        paddingRight: "40px"
    },
    roundButton: {
        fontFamily: "rubik",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        appearance: "none",
        color: "white",
        borderRadius: "50%",
        fontSize: "16px",
        width: "20px",
        height: "20px",
        backgroundColor: "transparent",
        border: 0,
        fontWeight: 700,
        cursor: "pointer",
        "&:disabled": {
            opacity: 0.8,
            cursor: "auto"
        }
    },
    quickshopInput: {
        flex: "1 1 auto",
        fontFamily: "rubik",
        fontSize: "16px",
        border: 0,
        padding: "4px",
        borderRadius: "2px",
        width: 0,
        "&::placeholder": {
            color: "#2d2d2d"
        },
        "&::-webkit-outer-spin-button": {
            appearance: "none"
        },
        "&::-webkit-inner-spin-button": {
            appearance: "none"
        },
        "&:disabled": {
            opacity: 0.8
        }
    },
    quantityInput: {
        fontFamily: "rubik",
        fontSize: "16px",
        width: "20px",
        textAlign: "center",
        padding: "4px",
        borderRadius: "2px",
        border: 0,
        appearance: "none",
        "&::-webkit-outer-spin-button": {
            appearance: "none"
        },
        "&::-webkit-inner-spin-button": {
            appearance: "none"
        },
        "&:disabled": {
            opacity: 0.8
        }
    },
    submitButton: {
        display: "flex",
        fontFamily: "rubik",
        fontSize: "16px",
        backgroundColor: "white",
        border: 0,
        borderRadius: "5px",
        color: "#2d2d2d",
        cursor: "pointer",
        padding: "5px",
        "&:disabled": {
            opacity: 0.8,
            cursor: "auto"
        }
    }
}))
