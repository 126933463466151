import { mobileBreakpoint } from "../../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    root: {
        padding: 10,
        marginBottom: 10,
        boxShadow: "none"
    },
    avatarLarge: {
        width: 100,
        height: 100,
        marginBottom: theme.spacing(1)
    },
    heading: {
        fontWeight: "bold",
        fontSize: 14
    },
    bottomContent: {
        display: "flex",
        alignItems: "center"
    },
    flexRow: {
        display: "flex",
        flexDirection: "row"
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column"
    },
    flexItem: {
        flex: "1 1 0px"
    },
    avatarImg: {
        width: "100%",
        height: "auto",
        objectFit: "initial"
    },
    capitalized: {
        textTransform: "uppercase"
    },
    accordionSummary: {
        margin: 0,
        height: 36,
        minHeight: 0,
        "& div": {
            margin: 0,
            minHeight: 0
        }
    },
    basis20: {
        flexBasis: "20%"
    },
    padding4: {
        padding: 4
    },
    textField: {
        width: "75px"
    },
    mobileTextField: {
        width: "75px",
        marginRight: "1rem"
    },
    loadingAnimation: {
        width: "100%",
        height: 100
    },
    mobileLoadingAnimation: {
        width: "100%",
        height: "350px"
    },
    flexEnd: {
        justifyContent: "flex-end"
    },
    mobileProductCardHeader: {
        display: "flex",
        flexDirection: "row",
        maxHeight: "50%",
        overflowY: "scroll",
        overflowX: "hidden",
        marginBottom: "1rem",
        gap: "1rem"
    },
    moreInfo: {
        padding: 0,
        marginBottom: 0
    },
    mobilePriceBox: {
        display: "flex",
        flexDirection: "row",
        maxHeight: "50%",
        overflowY: "scroll",
        marginBottom: "1rem"
    },
    marginBottom1: {
        marginBottom: "1rem"
    },
    marginNone: {
        margin: 0
    },
    marginRight24: {
        marginRight: "24px"
    },
    mobileCartItem: {
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "#FFFFFF",
        borderRadius: "5px",
        marginBottom: "10px",
        fontSize: "15px",
        fontFamily: "Arial",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            fontSize: "12px"
        }
    },
    mobileRow: {
        display: "flex",
        flex: "0 1 100%"
    },
    mobileImageWrapper: {
        display: "flex",
        flex: "1 1 200px",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px"
    },
    mobileTitleWrapper: {
        flex: "0 1 100%",
        padding: "10px"
    },
    mobileTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    spaceBetween: {
        justifyContent: "space-between"
    },
    mobilePriceLeft: {
        display: "flex",
        flex: "0 0 60%",
        alignItems: "center"
    },
    mobilePriceRight: {
        display: "flex",
        flex: "0 0 40%",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    mobilePadded: {
        padding: "10px"
    },
    mobileDescription: {
        marginTop: "10px"
    },
    mobileProductId: {
        fontSize: "14px"
    },
    mobileButtonWrapper: {
        display: "flex"
    },
    mobileProductImage: {
        width: "auto",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            width: "75px"
        }
    },
    mobileLink: {
        color: "#2d2d2d",
        textDecoration: "none",
        fontWeight: "600"
    },
    mobileAccordion: {
        flex: "0 1 100%",
        boxShadow: "none",
        backgroundColor: "#fbfbfb"
    },
    mobileNoteInput: {
        flex: "0 1 100%",
        backgroundColor: "#fbfbfb",
        color: "#2d2d2d",
        border: 0,
        padding: "16px"
    }
}))
