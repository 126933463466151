import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const fetchCustomerOrders = async (authToken, offset, limit) => {
    const a = await axios.get(
        `/api/ProductInfo/GetCustomerOrders?authToken=${authToken}&offset=${offset}&limit=${limit}`
    ) // offset and limit not used in backend, only last 20 is displayed
    return a.data
}

// Fetch customer orders, but do not save them in a adapter.
export const requestCustomerOrders = createAsyncThunk(
    "customerOrders/fetch",
    async (params, { rejectWithValue }) => {
        try {
            const { authToken, offset, limit } = params
            return await fetchCustomerOrders(authToken, offset, limit)
        } catch (err) {
            return rejectWithValue("Could not fetch customer order.")
        }
    }
)

const fetchCustomerOrder = async (authToken, id) => {
    const a = await axios.get(
        `/api/ProductInfo/GetCustomerOrder?orderNumber=${id}&authToken=${authToken}`
    )
    return a.data
}

export const requestCustomerOrder = createAsyncThunk(
    "customerOrders/fetchById",
    async (params, { rejectWithValue }) => {
        try {
            const { authToken, id } = params
            return await fetchCustomerOrder(authToken, id)
        } catch (err) {
            return rejectWithValue("Could not fetch customer orders.")
        }
    }
)
