import { prepareProducts } from "../../components/CompareProducts/helperFunctions"
import { replaceNameForBrandOther, restoreNameForBrandOther, sortBrands } from "../../lib/helper/brandHelper"
import { filterFaultyValues, isSearchQueryEmpty } from "../../lib/helper/searchHelper"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const productInfoApi = createApi({
    reducerPath: "productInfoApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api/ProductInfo/" }),
    endpoints: builder => ({
        getBrands: builder.query({
            query: ({ language, steering = null }) => (
                `GetBrands?language=${language}${steering ? `&steering=${steering}` : ""}`
            ),
            transformResponse: response => {
                const nameCorrectedBrands = replaceNameForBrandOther(response)
                const sortedBrands = sortBrands(nameCorrectedBrands)
                return sortedBrands
            }
        }),
        getProductsByFilter: builder.query({
            query: ({ filters, pagination, language }) => {
                // Filter out empty search keywords.
                const cleanFilters = filterFaultyValues(filters)

                // Prevent fetching when filter is empty.
                const isEmpty = isSearchQueryEmpty(cleanFilters)
                if (isEmpty) return null

                // Restore the original name of the "Other models" brand.
                if (cleanFilters.brands) {
                    cleanFilters.brands = restoreNameForBrandOther(cleanFilters.brands)
                }
                return {
                    url: "GetProductsByFilter",
                    method: "POST",
                    body: { ...cleanFilters, ...pagination, language }
                }
            },
            transformResponse: (response, _meta, arg) => {
                const productList = response?.pagedList?.response?.list ?? []
                const products = prepareProducts(productList, arg.language)
                return {
                    ...response,
                    products: products,
                    brands: replaceNameForBrandOther(response.brands)
                }
            }
        }),
        getModelYears: builder.query({
            query: args => ({
                url: "GetModelYears",
                method: "POST",
                body: {
                    brandIds: [args.brand],
                    modelIds: [args.model],
                    language: args.language,
                    steering: [args.steering]
                }
            }),
            transformResponse: response => response.response
        })
    })
})

export const { useGetBrandsQuery, useGetProductsByFilterQuery, useGetModelYearsQuery } = productInfoApi
