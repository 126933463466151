/* eslint-disable import/no-named-as-default */
import cartReducer from "./cartSlice"
import userReducer from "./userSlice"
import monitorReducer from "./monitorSlice"
import settingsReducer from "./settingsReducer"
import productReducer from "./productReducer"
import materialReducer from "./materialReducer"
import priceReducer from "./priceSlice"
import customerOrderReducer from "./customerOrderReducer"
import newsArticleReducer from "./newsArticleReducer"
import modelSlice from "./modelSlice"
import gdprReducer from "./gdprSlice"
import collectionReducer from "./collectionReducer"
import pageSlice from "./pageReducer"
import searchReducer from "./searchReducer"
import userMessageReducer from "./userMessageSlice"
import favoriteReducer from "./favoriteSlice"
import { broditWebApi } from "./services/broditWebApi"
import { contentApi } from "./services/contentApi"
import { cartApi } from "./services/cartApi"
import { favoriteApi } from "./services/favoriteApi"
import compareProductsReducer from "./compareProductsReducer"
import { priceApi } from "./services/priceApi"
import { productInfoApi } from "./services/productInfoApi"
import search2Reducer from "./search2Reducer"
import { combineReducers } from "@reduxjs/toolkit"

export const rootReducer = combineReducers({
    cart: cartReducer,
    user: userReducer,
    product: productReducer,
    material: materialReducer,
    monitor: monitorReducer,
    settings: settingsReducer,
    price: priceReducer,
    customerOrder: customerOrderReducer,
    newsArticle: newsArticleReducer,
    model: modelSlice,
    gdpr: gdprReducer,
    collection: collectionReducer,
    page: pageSlice,
    search: searchReducer,
    search2: search2Reducer,
    userMessage: userMessageReducer,
    favorite: favoriteReducer,
    compareProduct: compareProductsReducer,
    [broditWebApi.reducerPath]: broditWebApi.reducer,
    [contentApi.reducerPath]: contentApi.reducer,
    [cartApi.reducerPath]: cartApi.reducer,
    [favoriteApi.reducerPath]: favoriteApi.reducer,
    [priceApi.reducerPath]: priceApi.reducer,
    [productInfoApi.reducerPath]: productInfoApi.reducer
})

export default rootReducer
