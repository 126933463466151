const newsDateComparer = (a, b) => {
    const aDate = new Date(a.dateTime)
    const bDate = new Date(b.dateTime)

    if (aDate.getTime() === bDate.getTime()) return 0 // TODO: Lägg till en localeCompare här så att man först jämför datum och sedan på localeCompare för samma datum.
    return aDate < bDate ? 1 : -1
}

const sortNews = newsList => [...newsList].sort(
    (a, b) => newsDateComparer(a, b)
)

export { newsDateComparer, sortNews }
