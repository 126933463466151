import { useLanguage } from "../../lib/hooks/languageHooks"
import React from "react"
import { Helmet } from "react-helmet"

function CategoriesMetaDescription() {
    const language = useLanguage()

    const title = language === "sv"
        ? "Starka och modulära monteringslösningar - Brodit"
        : "Strong and Modular Mounting Solutions - Brodit"
    const description = language === "sv"
        ? "Med vårt breda utbud av starka monteringslösningar skapas enkelt förutsättningarna för effektivt och ergonomiskt arbete."
        : "With our wide range of strong mounting solutions, you can easily create conditions for efficient and ergonomic work."

    const keywords = language === "sv"
        ? "Piedestalfästen, Truckfästen, Rörfästen, MoveClip, Sugproppsfästen, Skärm och tangentbordshållare, Brodit"
        : "Pedestal Mounts, Forklift Mounts, Pipe Mounts, MoveClip, Suction Cup Mounts, Workstation Mounts, Brodit"

    return (
        <Helmet>
            <title>{title}</title>
            <meta
                name="description"
                content={description}
            />
            <meta
                name="keywords"
                content={keywords}
            />
        </Helmet>
    )
}

export default CategoriesMetaDescription
