import AppMenuItem from "./AppMenuItem/AppMenuItem"
import useStyles from "./styles"
import ArrowBack from "./assets/arrow-back.svg"
import { midSizeBreakpoint, tabletBreakpoint } from "../../lib/helper/viewportHelper"
import { useLanguage } from "../../lib/hooks/languageHooks"
import { strings as Localization } from "../../lib/Localization"
import theme from "../../theme"
import img from "../../assets/CustomizedSolutions2.jpg"
import {
    Box, List, Typography, useMediaQuery
} from "@mui/material"
import React, { useEffect, useState, useRef } from "react"
import { NavLink } from "react-router-dom"

// https://medium.com/@modularcoder/reactjs-multi-level-sidebar-navigation-menu-with-typescrip-materialui-251943c12dda
// TODO: Refactorera! Fett oklart vad som går fel men allt som ser konstigt ut är här enligt principen "dont fix what aint broken" och it aint broken :/
// https://medium.com/@modularcoder/reactjs-multi-level-sidebar-navigation-menu-with-typescrip-materialui-251943c12dda
const Menu2 = ({ onItemClick, onBackToParent = null, onClose }) => {
    const menuRef = useRef(null)
    const classes = useStyles()
    const [collectionStructure, setCollectionStructure] = useState(null)
    const [loading, setLoading] = useState(false)
    const lang = useLanguage()
    const [currentCollection, setCurrentCollection] = useState({ collections: [] })
    const [history, setHistory] = useState([])
    const isDesktop = useMediaQuery(() => theme.breakpoints.up(tabletBreakpoint))
    const small = useMediaQuery(() => theme.breakpoints.down(tabletBreakpoint))
    const midSize = useMediaQuery(() => theme.breakpoints.down(midSizeBreakpoint))

    const addParentToCollections = (collections, parent = null) => {
        if (!collections || collections.length === 0) {
            return []
        }

        return collections.map(collection => ({
            ...collection,
            parent,
            collections: addParentToCollections(collection.collections, collection)
        }))
    }

    const fetchCollectionStructure = async () => {
        setLoading(true)
        const response = await fetch(`/api/Collections/GetCollectionHierarchy?language=${lang}`)
        setLoading(false)
        return response.json()
    }

    const findCollectionById = (collection, id) => {
        if (!collection) {
            return null
        }

        if (collection.id === id) {
            return collection
        }

        if (collection.collections && collection.collections.length > 0) {
            for (const subCollection of collection.collections) {
                const result = findCollectionById(subCollection, id)
                if (result) {
                    return result
                }
            }
        }

        return null
    }

    const selectCollection = (collectionId, level) => {
        const selectedCollection = findCollectionById(collectionStructure, collectionId)
        if (!history.some(col => col.id === selectedCollection.id)) {
            setHistory(prevState => [...prevState.slice(0, level + 1), selectedCollection])
        }
        setCurrentCollection(selectedCollection)
    }

    useEffect(() => {
        fetchCollectionStructure().then(result => {
            const structuredResult = addParentToCollections([result])[0]
            setHistory([structuredResult])
            setCollectionStructure(structuredResult)
            setCurrentCollection(structuredResult)
        })
    }, [lang])

    const handleBackAction = () => {
        if (onBackToParent !== null && currentCollection.parent === null) {
            onBackToParent()
            return
        }
        selectCollection(currentCollection.parent.id)
    }

    useEffect(() => {
        const handleClickOutside = event => {
            if (menuRef.current
                && !menuRef.current.contains(event.target)
                && event.target.id !== "productButtonDesktop"
                && !event.target.classList.contains("MuiAutocomplete-option")
                && !event.target.classList.contains("MuiAutocomplete-listbox")) {
                onClose()
            }
        }

        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    const mobileLayout = (
        currentCollection && collectionStructure
            ? (
                <>
                    <button className={classes.backButton} type="button" onClick={handleBackAction}>
                        <img loading="lazy" src={ArrowBack} alt="Arrow back" />
                        {!!onBackToParent && !currentCollection.parent && <span>Tillbaka till huvudmeny</span>}
                        {currentCollection?.parent?.name}
                    </button>
                    <h4 className={classes.collectionTitle}>{currentCollection.name}</h4>
                    {
                        (loading && collectionStructure.collections.length <= 0)
                        && (
                            <Typography>
                                {Localization.loading}
                                ...
                            </Typography>
                        )
                    }
                    <List component="nav" className={classes.appMenu} disablePadding>
                        {
                            currentCollection.collections.map(collection => {
                                if (collection === undefined) return null
                                return (
                                    <AppMenuItem
                                        key={collection.id}
                                        id={collection.id}
                                        name={collection.name}
                                        collections={collection.collections}
                                        selectCollection={selectCollection}
                                        indent={0}
                                        onItemClick={onItemClick}
                                    />
                                )
                            })
                        }
                    </List>
                </>
            )
            : ""
    )

    const desktopLayout = (
        currentCollection && collectionStructure ? (
            <Box
                ref={menuRef}
                style={{
                    display: "flex",
                    paddingTop: 47,
                    paddingLeft: 47,
                    paddingRight: small ? "47px" : "",
                    paddingBottom: 47
                }}
            >
                {loading && collectionStructure.collections.length <= 0 && (
                    <Typography>
                        {Localization.loading}
                        ...
                    </Typography>
                )}
                {!midSize && (
                    <Box style={{ width: 287, marginRight: 80 }}>
                        <img loading="lazy" src={img} style={{ width: 287, height: 137, borderRadius: 6 }} alt="" />
                        <Typography
                            variant="h3"
                            style={{
                                fontFamily: "Arial",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                                textAlign: "left",
                                marginTop: 10
                            }}
                        >
                            {Localization.homeSectionCustomSolutionsTitel}
                        </Typography>
                        <Typography
                            style={{
                                textWrap: "wrap",
                                marginTop: 10,
                                marginBottom: 16,
                                fontFamily: "Arial",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "20px",
                                letterSpacing: "0.5px",
                                textAlign: "left"
                            }}
                        >
                            {Localization.megaMenu}
                        </Typography>
                        <NavLink
                            to={`/${lang}/product-development`}
                            onClick={onItemClick}
                            className={classes.readMoreLink}
                            style={{ textDecoration: "none", marginTop: "20px" }}
                            end
                        >
                            {Localization.readMore}
                        </NavLink>
                    </Box>
                )}
                <>
                    {
                        (loading && collectionStructure.collections.length <= 0)
                        && (
                            <Typography>
                                {Localization.loading}
                                ...
                            </Typography>
                        )
                    }
                    <Box style={{ display: "flex" }}>
                        {history.map((col, i) => (
                            <List key={col.id} component="nav" className={classes.appMenu} style={{ borderRight: "2px solid #F8F8F8" }} disablePadding>
                                {col.collections.map(collection => {
                                    if (collection === undefined) return null
                                    return (
                                        <AppMenuItem
                                            key={collection.id}
                                            id={collection.id}
                                            name={collection.name}
                                            collections={collection.collections}
                                            selectCollection={(id, depth) => selectCollection(id, depth)}
                                            level={i}
                                            indent={0}
                                            onItemClick={onItemClick}
                                            history={history}
                                        />
                                    )
                                })}
                            </List>
                        ))}
                    </Box>
                </>
            </Box>
        ) : ""
    )
    return isDesktop ? desktopLayout : mobileLayout
}

export default Menu2
