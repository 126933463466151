import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    content: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3)
    },
    root: {
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center"
    },
    active: {
        backgroundImage:
          "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
    },
    completed: {
        backgroundImage:
          "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)"
    },
    line: {
    },
    textFieldWrapper: {
        padding: "0 20px 20px 20px",
        display: "flex",
        flexDirection: "column",
        gap: "10px"
    },
    textFieldWrapperNoPadding: {
        display: "flex",
        flexDirection: "column",
        gap: "10px"
    }
}));
