import { useLanguage } from "../../lib/hooks/languageHooks"
import React from "react"
import { Helmet } from "react-helmet"

function LandingPageMetaDescription() {
    const language = useLanguage()

    const title = language === "sv"
        ? "Brodit | Monteringslösningen för din enhet"
        : "Brodit | Mounting solutions for your device"
    const description = language === "sv"
        ? "Brodit tillverkar en världsledande tvådelslösning för modellspecifik montering av din enhet i ditt fordon. Vilken enhet som helst, var som helst! Funktion | Kvalitet | Säkerhet."
        : "Brodit is a world-leading manufacturer of model specific mounting solutions for your device. Any device, anywhere! Function | Quality | Safety."

    const keywords = language === "sv"
        ? "Brodit, monteringslösningar, Truckfästen, Rörfästen, Piedestalfästen, ProClip, enhetshållare"
        : "Brodit,  Mounting Solutions, Forklift Mounts, Pipe Mounts, Pedestal Mounts, ProClip, Device Holders"

    return (
        <Helmet htmlAttributes={{ lang: language }}>
            <title>{title}</title>
            <meta
                name="description"
                content={description}
            />
            <meta
                name="keywords"
                content={keywords}
                lang="sv"
            />
        </Helmet>
    )
}

export default LandingPageMetaDescription
