import ComparisonHeader from "./ComparisonHeader"
import ProductComparisonSections from "./ProductComparisonSections"
import { productTransformation } from "../helperFunctions"
import useStyles from "../styles"
import { Box } from "@mui/material"
import React from "react"

const ProductComparisonTable = ({ products }) => {
    const classes = useStyles()

    return (
        <Box className={classes.result}>
            <ComparisonHeader products={products} />
            <ProductComparisonSections sections={productTransformation(products)} />
        </Box>
    )
}

export default ProductComparisonTable
