import { useLanguage } from "../../lib/hooks/languageHooks"
import { strings as Localization } from "../../lib/Localization"
import {
    Box, TextField, Autocomplete
} from "@mui/material"
import React from "react"

const DealerSearchFilter = props => {
    const language = useLanguage()
    const {
        data, countryDealers,
        filter, onChangeCountryDealers,
        onChangeDealerLocations
    } = props
    const dealerLocations = Array.from(new Set(data.map(dealer => dealer.place))).filter(dealerplace => dealerplace !== "").sort()
    const dealerCountries = countryDealers.map(x => x.Country)

    return (
        language === "en"
            ? (
                <Autocomplete
                    id="countryDealers"
                    options={dealerCountries}
                    style={{ width: 300 }}
                    renderInput={params => <TextField {...params} label="CHOOSE COUNTRY" />}
                    onChange={onChangeCountryDealers}
                    value={filter.country}
                />
            )
            : (
                <Box style={{ display: "flex", flexDirection: "column", gap: 6 }}>
                    <Autocomplete
                        id="dealerLocations"
                        options={dealerLocations}
                        style={{ width: 300 }}
                        renderInput={params => <TextField {...params} label={Localization.chooseCity} />}
                        onChange={onChangeDealerLocations}
                        value={filter.place}
                    />
                </Box>
            )
    )
}

export default DealerSearchFilter
