import { makeStyles } from "@mui/styles"

export default makeStyles(() => ({
    dialog: {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "32px"
    },
    dialogButtonWrapper: {
        display: "flex",
        gap: "5px"
    },
    dialogButton: {
        flex: "0 1 50%"
    },
    dialogTextWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "4px"
    }
}))
