import { eraseCookie, loadCookie, saveCookie } from "./cookieStorage"
import { createSlice } from "@reduxjs/toolkit"

export const userSlice = createSlice({
    name: "user",
    initialState: {
        isAuthorized: JSON.parse(sessionStorage.getItem("isAuthorized")) || false,
        authToken: null,
        user: {},
        addresses: {},
        welcomeSplashVisible: false,
        currency: null,
        steering: "1",
        selectedModTek: { id: "", step: "" }
    },
    reducers: {
        setUser: (state, action) => {
            const {
                user, addresses, currency, welcomeSplashVisible
            } = action.payload
            const { authToken, ...userData } = user
            const showWelcomeSplash = welcomeSplashVisible ?? true
            sessionStorage.setItem("isAuthorized", "true")
            saveCookie("user", {
                authToken: authToken,
                welcomeSplashVisible: showWelcomeSplash
            })

            state.authToken = authToken
            state.user = userData
            state.addresses = addresses
            state.currency = currency
            state.steering = user?.preferredSteering ? user.preferredSteering.toString() : "1"
            state.welcomeSplashVisible = showWelcomeSplash
            state.isAuthorized = true
        },
        closeWelcomeSplash: state => {
            saveCookie("user", { ...loadCookie("user"), welcomeSplashVisible: false })
            state.welcomeSplashVisible = false
        },
        signOut: state => {
            eraseCookie("user")

            state.authToken = null
            state.user = {}
            state.addresses = {}
            state.currency = null
            state.isAuthorized = false
            sessionStorage.removeItem("isAuthorized")
        },
        setSteering: (state, action) => {
            state.steering = action.payload
        },
        setSelectedModTek: (state, action) => {
            state.selectedModTek = action.payload
        }
    }
})

export default userSlice.reducer

export const {
    signOut: signOutAction,
    setUser,
    setSteering,
    setSelectedModTek,
    closeWelcomeSplash
} = userSlice.actions

export const signIn = user => dispatch => dispatch(setUser(user))
export const signOut = () => dispatch => dispatch(signOutAction())
export const changeSteering = steering => dispatch => dispatch(setSteering(steering))
export const changeSelectedModTek = selected => dispatch => dispatch(setSelectedModTek(selected))
export const closeWelcome = () => dispatch => dispatch(closeWelcomeSplash())
