import { restoreNameForBrandOther } from "../../lib/helper/brandHelper"
import { selectById } from "../adapter/modelAdapter"
import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const fetchModel = async id => {
    const response = await axios.get(`/api/v1/Models/${id}`)
    return response.data
}

const fetchModelsForBrand = async (brandId, language, steering = null) => {
    const [restoredBrandId] = restoreNameForBrandOther([brandId])
    const steeringParams = steering ? `&steerings=${steering}` : ""
    const response = await axios.get(
        `/api/v1/Brands/${restoredBrandId}/Models?language=${language}${steeringParams}`
    )
    return response.data
}

export const requestModel = createAsyncThunk(
    "model/fetchById",
    async params => {
        const { id } = params
        return fetchModel(id)
    },
    {
        condition: (params, { getState }) => {
            const { id } = params
            const existing = selectById(getState(), id)
            return !existing
        }
    }
)

export const requestModelsByBrand = createAsyncThunk(
    "model/fetchByBrand",
    async ({ brandId, language, steering = null }) => (
        fetchModelsForBrand(brandId, language, steering)
    )
)
