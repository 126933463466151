import countries from "./countries.json"
import useStyles from "./styles"
import { UserInfo } from "../.."
import { mailRegex } from "../../../lib/helper/commonHelper"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import { strings as Localization } from "../../../lib/Localization"
import {
    emptyCart, nextCartStep, prevCartStep, setOrderId
} from "../../../redux/cartSlice"
import { useIsUserAuthorized, useUserAuthToken } from "../../../redux/hook/userHooks"
import { setSnackbarMessage } from "../../../redux/userMessageSlice"
import {
    Box, Button, ButtonGroup, Collapse, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField, Alert
} from "@mui/material"
import { ArrowBack, ArrowForward, Loop } from "@mui/icons-material"

import axios from "axios"
import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"

const AddressButtons = ({ orderInProgress }) => {
    const classes = useStyles()
    const cart = useSelector(state => state.cart)
    const isAuthorized = useIsUserAuthorized()
    const dispatch = useDispatch()

    const handlePrev = () => {
        dispatch(prevCartStep())
    }

    const orderingAvailable = () => (cart.items.length > 0) && (isAuthorized === true) && cart.order.step === 1

    return (
        <Box className={classes.root}>
            <ButtonGroup>
                <Button
                    startIcon={<ArrowBack />}
                    onClick={handlePrev}
                    disabled={cart.order.step === 0}
                    variant="contained"
                    color="secondary"
                >
                    {Localization.backToCart}
                </Button>
                <Button
                    endIcon={!orderInProgress ? <ArrowForward /> : <Loop className={classes.working} />}
                    variant="contained"
                    id="submitOrder"
                    color="primary"
                    type="submit"
                    disabled={!orderingAvailable() || orderInProgress}
                >
                    {Localization.submitOrder}
                </Button>
            </ButtonGroup>
        </Box>
    )
}

const boxHeight = 15

const OrderAddresses = () => {
    const defaultValues = {
        yourOrderNumber: null, // Purchase order
        authToken: null,
        email: null,
        reference: null, // Order placed by
        rows: [],
        messageToBrodit: null,
        language: null,
        deliveryAddress: {},
        specialAddress: false,
        smsNumber: null
    }

    const classes = useStyles()
    const authToken = useUserAuthToken()
    const cart = useSelector(state => state.cart)
    const lang = useLanguage()
    const dispatch = useDispatch()
    const [orderInProgress, setOrderInProgress] = useState(false)
    const counriesForLang = countries[lang]

    const {
        register, resetField, handleSubmit, watch, setValue, formState: { errors }
    } = useForm({ defaultValues: defaultValues })

    const sms = watch("SMS")

    useEffect(() => {
        resetField("smsNumber")
    }, [sms])

    useEffect(() => {
        setValue("authToken", authToken)
        setValue("language", lang)
        setValue("rows", cart.items.map(item => ({
            type: 1, // Sätts alltid till 1 i backend (nu)
            articleNumber: item.id,
            quantity: item.quantity,
            comment: item.note
        })))
    }, [])

    const onSubmit = data => {
        // Delete the delivery adress if omitted, monitor wants this as null if no action is to be taken (= omitted)
        if (!watch("specialAddress")) {
            delete data.deliveryAddress
        }

        (async () => {
            setOrderInProgress(true)
            let response = null
            try {
                response = await axios.post(
                    "/api/ProductInfo/PlaceOrder",
                    data
                )
            } catch {
                dispatch(setSnackbarMessage(Localization.unknownErrorCreatingOrder, "error"))
                setOrderInProgress(false)
                return
            }

            const status = response.data.httpStatusCode // Statuskod från MonitorAPI, ej från WebAPI.

            setOrderInProgress(false)

            switch (status) {
                case 200:
                    dispatch(setSnackbarMessage(Localization.orderWasPlaced, "success"))
                    break
                case 400:
                    dispatch(setSnackbarMessage(Localization.unknownErrorCreatingOrder, "error"))
                    return
                case 401:
                    dispatch(setSnackbarMessage(Localization.unknownErrorCreatingOrder, "error"))
                    return
                default:
                    dispatch(setSnackbarMessage(Localization.unknownErrorCreatingOrder, "error"))
                    return
            }

            dispatch(setOrderId(response.data.response.OrderNumber))
            dispatch(nextCartStep())
            dispatch(emptyCart())
        })()
    }

    return (
        <form style={{ flex: "0 1 1600px" }} onSubmit={handleSubmit(onSubmit)}>
            <Box className={classNames(classes.flexRow, classes.space, classes.addressWrapper)}>
                <div className={classes.half}>
                    <Box className={classes.column}>
                        <TextField
                            id="messageToBrodit"
                            variant="standard"
                            label={Localization.messageToBrodit}
                            {...register("messageToBrodit")}
                        />
                        <Box height={boxHeight} />
                        <TextField
                            id="yourOrderNumber"
                            variant="standard"
                            label={Localization.purchaseOrder}
                            {...register("yourOrderNumber", { maxLength: 30 })}
                            error={Boolean(errors.yourOrderNumber)}
                        />
                        {Boolean(errors.yourOrderNumber) && <Alert severity="error">{Localization.maximum30Letters}</Alert>}
                        <Box height={boxHeight} />
                        <TextField
                            id="orderPlaceBy"
                            variant="standard"
                            required
                            label={Localization.orderPlacedBy}
                            {...register("reference")}
                            error={Boolean(errors.reference)}
                        />
                        <Box height={boxHeight} />
                        <TextField
                            id="email"
                            variant="standard"
                            required
                            label={Localization.emailForOrderConfirmation}
                            {...register("email", { required: true, validate: value => mailRegex.test(value) })}
                            error={Boolean(errors.email)}
                        />
                        <Box height={boxHeight} />
                        {lang === "sv"
                            && (
                                <>
                                    <FormControlLabel
                                        variant="standard"
                                        label={Localization.smsTracking}
                                        classes={{
                                            label: classes.tempAddressLabel
                                        }}
                                        control={(
                                            <Switch
                                                {...register("SMS")}
                                                color="primary"
                                            />
                                        )}
                                    />
                                    <Collapse in={Boolean(watch("SMS"))}>
                                        <Box className={classes.full}>

                                            <TextField
                                                error={Boolean(errors.smsNumber)}
                                                helperText={Localization.numbersOnly}
                                                variant="standard"
                                                fullWidth
                                                label={Localization.cellNumber}
                                                {...(watch("SMS") ? register("smsNumber", {
                                                    required: Boolean(watch("SMS")),
                                                    validate: value => /^[0-9]+$/.test(value)
                                                }) : {})}
                                            />

                                        </Box>
                                    </Collapse>
                                </>
                            )}
                        <FormControlLabel
                            variant="standard"
                            label={Localization.useTemporaryAddress}
                            classes={{
                                label: classes.tempAddressLabel
                            }}
                            control={(
                                <Switch
                                    {...register("specialAddress")}
                                    color="primary"
                                />
                            )}

                        />
                        <Collapse in={Boolean(watch("specialAddress"))}>
                            <Box className={classes.full}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    required={Boolean(watch("specialAddress"))}
                                    label={Localization.companyName}
                                    {...register("deliveryAddress.row1", { required: Boolean(watch("specialAddress")) })}
                                />
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    required={Boolean(watch("specialAddress"))}
                                    label={Localization.address}
                                    {...register("deliveryAddress.row2", { required: Boolean(watch("specialAddress")) })}
                                />
                                <Box height={boxHeight} />
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    {...register("deliveryAddress.row3")}
                                />
                                <Box height={boxHeight} />
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    required={Boolean(watch("specialAddress"))}
                                    label={Localization.zipAndCity}
                                    placeholder={Localization.forInstanceKarlsborg}
                                    {...register("deliveryAddress.row4", { required: Boolean(watch("specialAddress")) })}
                                />
                                <Box height={boxHeight} />
                                {lang !== "sv" && (
                                    <FormControl>
                                        <InputLabel>{Localization.country}</InputLabel>
                                        <Select
                                            variant="standard"
                                            fullWidth
                                            defaultValue="SE"
                                            {...register("deliveryAddress.row5", { required: Boolean(watch("specialAddress")) })}
                                        >
                                            {counriesForLang.map((country, i) => (
                                                <MenuItem key={i} value={country.key}>{country.value}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Box>
                        </Collapse>
                    </Box>
                </div>
                <div className={classes.half}>
                    <div className={classes.userInfoWrapper}>
                        <UserInfo />
                    </div>
                </div>
            </Box>
            <AddressButtons orderInProgress={orderInProgress} />
        </form>
    )
}

export default OrderAddresses
