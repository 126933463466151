import { setFavoriteProducts, setShouldSyncFavorites } from "../favoriteSlice"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { batch } from "react-redux"

export const favoriteApi = createApi({
    reducerPath: "favoriteApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api/favorite/" }),
    endpoints: builder => ({

        getFavorite: builder.query({
            query: ({ authToken }) => `GetFromAuthToken?authToken=${authToken}`,
            // transformResponse: (response) => response?.response,

            async onQueryStarted({ _authToken }, { dispatch, queryFulfilled }) {
                // Request has started.
                try {
                    const { data: favoriteFromServer } = await queryFulfilled
                    // Request succeeded.
                    // Batch prevents rerendering for each dispatch and
                    // instead only rerenders once.
                    batch(() => {
                        dispatch(setFavoriteProducts(
                            favoriteFromServer
                        ))
                        dispatch(setShouldSyncFavorites(false))
                    })
                } catch (err) {
                    // Request returned and error.
                }
            }
        }),

        updateFavorite: builder.mutation({
            query: ({ authToken, favorite }) => ({
                url: `UpdateFromAuthToken?authToken=${authToken}`,
                method: "POST",
                body: favorite
            })
            // transformResponse: (response) => response?.response,
        })
    })
})

export const { useGetFavoriteQuery, useUpdateFavoriteMutation } = favoriteApi
