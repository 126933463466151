import { makeStyles } from "@mui/styles"

export default makeStyles(() => ({
    root: {
        padding: 10
    },
    capitalize: {
        textTransform: "uppercase"
    },
    flexRow: {
        display: "flex",
        flexDirection: "row"
    },
    flexItem: {
        flex: "1 1 0px"
    },
    gray: {
        color: "#a3a3a3"
    },
    basis20: {
        flexBasis: "20%"
    },
    padding4: {
        padding: 4
    }
}))
