import { mailRegex } from "../../lib/helper/commonHelper"
import { useLanguage } from "../../lib/hooks/languageHooks"
import { strings as Localization } from "../../lib/Localization"
import { useRecaptchaSiteKey } from "../../redux/hook/settingsHooks"
import { setSnackbarMessage } from "../../redux/userMessageSlice"
import {
    Button,
    FormControl,
    TextField,
    Typography,
    useTheme
} from "@mui/material"
import SendIcon from "@mui/icons-material/Send"
import axios from "axios"
import React, { useEffect, useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"

const ForgotPasswordForm = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const lang = useLanguage()
    const recaptchaRef = useRef()
    const recaptchaSiteKey = useRecaptchaSiteKey()

    const defaultValues = {
        email: null,
        language: null,
        recaptcha: undefined
    }

    const {
        register, handleSubmit, watch, setValue, formState: { errors }, setError, clearErrors
    } = useForm({ defaultValues: defaultValues })

    const onExpired = () => {
        setValue("recaptcha", undefined)
    }

    const onChangeRecaptcha = value => {
        setValue("recaptcha", value)
    }

    const resetRecaptcha = () => {
        setValue("recaptcha", undefined)
        recaptchaRef.current.reset()
    }

    const onSubmit = data => {
        if (data.recaptcha === undefined) { // data.typeOfSubmit === undefined &&
            setError("recaptcha", {
                type: "manual",
                message: "Please verify that you are not a robot using Recaptcha!"
            })
            resetRecaptcha()
            setTimeout(() => {
                clearErrors()
            }, 250)
            return
        }

        (async () => {
            setLoading(true)
            let status = null
            try {
                if (data.recaptchaBypass === "true") {
                    status = 200
                } else {
                    const response = await axios.post(
                        `/api/Users/PasswordResetRequest?token=${data.recaptcha}`,
                        data
                    )
                    status = response.data.httpStatusCode // From internal api
                }
            } catch (error) {
                status = error?.response?.status
            }

            setLoading(false)

            switch (status) {
                case (200):
                    dispatch(setSnackbarMessage(Localization.forgotPasswordNotification, "success"))
                    break
                default:
                    dispatch(setSnackbarMessage(Localization.unknownError, "error"))
                    break
            }

            setTimeout(() => {
                resetRecaptcha()
            }, 250)
        })()
    }

    useEffect(() => {
        setValue("language", lang)
    }, [])

    return (
        <form onSubmit={handleSubmit(onSubmit)} id="sendMailForm">
            <FormControl
                fullWidth
            >
                <TextField
                    required
                    id="emailForm"
                    label={Localization.email}
                    variant="outlined"
                    style={{ marginBottom: theme.spacing(2) }}
                    {...register("email", { required: true, validate: value => mailRegex.test(value) })}
                    size="small"
                />
            </FormControl>

            {/* {(process.env.NODE_ENV === "development" || process.env.NODE_ENV === "testenvironment") && (
                <FormControl
                    fullWidth
                >
                    <TextField type="text" id="typeOfSubmit" name="typeOfSubmit" {...register("typeOfSubmit")} />
                    <TextField type="text" id="recaptchaBypass" name="recaptchaBypass" {...register("recaptchaBypass")} />
                </FormControl>
            )} */}

            <FormControl>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    style={{ marginTop: 20, marginBottom: 20 }}
                    sitekey={recaptchaSiteKey}
                    onExpired={onExpired}
                    onChange={onChangeRecaptcha}
                    hl={lang}
                />
            </FormControl>

            {Boolean(errors.recaptcha) && <Typography color="error">{Localization.pleaseFillInThisCheckBox}</Typography>}
            <FormControl
                fullWidth
            >
                <Button
                    id="sendMailBtn"
                    size="small"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading || !watch("recaptcha")}
                    endIcon={<SendIcon />}
                >
                    OK

                </Button>
            </FormControl>
        </form>
    )
}

export default ForgotPasswordForm
