import LoadingView from "./LoadingView"
import NotFoundView from "./NotFoundView"
import { useProductsLoading } from "../../../../redux/hook/productHooks"
import SwipeableTextMobileStepper from "../../../SwipableView"
import ProductCard from "../../ProductCard/ProductCard"
import RelatedProducts from "../view/RelatedProducts"
import { isObjectOnlyNull } from "../../../../lib/helper/commonHelper"
import {
    Accordion, AccordionDetails, AccordionSummary, Box, Typography
} from "@mui/material"
import React from "react"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

const MobileProductInfo = (
    {
        id,
        tabList,
        handleShowMoreCompsClick,
        value,
        isAuthorized,
        classes,
        product = {},
        modTek = {},
        modTekInformation = {},
        altNameList = []
    }
) => {
    const loading = useProductsLoading()

    if (product === undefined && loading) return <LoadingView />

    if (product === undefined) return <NotFoundView />

    return (
        <Box className={classes.mobileContent}>
            <Box id="productMain" className={classes.mobileProductMain}>
                <Box className={classes.mobileProductCard}>
                    <SwipeableTextMobileStepper product={product} productPage />
                    <Box className={classes.flexColumn}>
                        <ProductCard
                            product={product}
                            handleShowMoreCompsClick={handleShowMoreCompsClick}
                            grid
                            backGroundColor="#f0f0f0"
                            showSearchDealerLink={!isAuthorized}
                            productInfo
                            modTekInformation={modTekInformation}
                            altNameList={altNameList}
                        />
                        {!isObjectOnlyNull(modTek) && (
                            <RelatedProducts
                                id={id}
                                modTek={modTek}
                                modTekInformation={modTekInformation}
                            />
                        )}
                    </Box>
                </Box>
                <Box>
                    {
                        tabList.map((data, index) => (
                            <Accordion
                                aria-label="Product information tabs"
                                className={classes.mobileTabs}
                                key={data.title}
                                defaultExpanded={index === 0}
                                style={{ marginTop: "0px" }}
                            >
                                <AccordionSummary
                                    {...{
                                        id: `simple-tab-${index}`,
                                        "aria-controls": `simple-tabpanel-${index}`
                                    }}
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography variant="h5" gutterBottom>
                                        {data.title.toUpperCase()}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    value={value}
                                    index={index}
                                >
                                    {data.component}
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default MobileProductInfo
