import useStyles from "../styles"
import { Box } from "@mui/material"
import React from "react"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"

const NavButtonIcon = ({ right, style }) => {
    const classes = useStyles()

    return (
        <Box>
            {
                right
                    ? (
                        <KeyboardArrowRightIcon
                            classes={{
                                root: classes.iconRoot
                            }}
                            style={style}
                        />
                    )
                    : (
                        <KeyboardArrowLeftIcon
                            classes={{
                                root: classes.iconRoot
                            }}
                            style={style}
                        />
                    )
            }
        </Box>
    )
}

export default NavButtonIcon
