import { strings as Localization } from "../../lib/Localization"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import DoneIcon from "@mui/icons-material/Done"
import LocalShippingIcon from "@mui/icons-material/LocalShipping"
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation"
import { Typography } from "@mui/material"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import PrintIcon from "@mui/icons-material/Print"
import React from "react"

const getStatusText = (statusId, upperCase = false) => {
    const defaultStatus = {
        icon: <DoneIcon />,
        text: Localization.deliveryComplete
    }
    const allStatus = {
        1: {
            icon: <CheckCircleOutlineIcon />,
            text: Localization.registered
        },
        2: {
            icon: <PrintIcon />,
            text: Localization.printed
        },
        3: {
            icon: <AccessTimeIcon />,
            text: Localization.deliveryTimeCannotBeConfirmed
        },
        4: {
            icon: <TransferWithinAStationIcon />,
            text: Localization.processing
        },
        5: {
            icon: <LocalShippingIcon />,
            text: Localization.partiallyDelivered
        }
    }

    const selectedStatus = allStatus[statusId] ?? defaultStatus

    return (
        <>
            {selectedStatus.icon}
            <Typography variant="caption">
                {
                    upperCase
                        ? selectedStatus.text.toUpperCase()
                        : selectedStatus.text
                }
            </Typography>
        </>
    )
}

export default getStatusText
