import { tabletBreakpoint } from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    wrapper: {
        [theme.breakpoints.down(tabletBreakpoint)]: {
            width: "93%",
            padding: "10px 6px 10px 6px"
        }
    },
    searchInput: {
        display: "flex",
        position: "relative",
        fontFamily: "Arial",
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.25px",
        textAlign: "left",
        border: 0,
        backgroundColor: "transparent",
        "&:focus": {
            outline: "unset"
        }
    },
    searchInputWrapper: {
        position: "relative",
        flex: "1 1 100%",
        "&:after": {
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            height: "1px",
            backgroundColor: "#2D2D2D",
            content: "''",
            opacity: 0.1
        }
    }
}))
