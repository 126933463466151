import {
    desktopBreakpoint, mobileBreakpoint, mobileMenuBreakpoint, tabletBreakpoint
} from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    main: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    section1: {
        maxWidth: "100%",
        display: "flex",
        height: "auto",
        flexShrink: 0,
        backgroundColor: "#FFF",
        [theme.breakpoints.between(mobileBreakpoint, mobileMenuBreakpoint)]: {
            width: "100%"
        }
    },
    heroBoxWrapper: {
        display: "flex",
        justifyContent: "flex-end",
        flex: "0 1 1920px",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            justifyContent: "center"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            display: "none"
        }
    },
    heroBox: {
        display: "flex",
        padding: "15px 30px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "20px",
        borderRadius: "10px",
        background: "rgba(255, 255, 255, 0.90)",
        marginRight: "200px",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            padding: "15px"
        },
        [theme.breakpoints.down(tabletBreakpoint)]: {
            marginRight: "unset"
        }
    },
    heroBoxHeader: {
        color: "#FFF",
        fontFamily: "Arial",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "16px", /* 88.889% */
        letterSpacing: "0.5px",
        position: "relative",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            leadingTrim: "both",
            textEdge: "cap",
            fontSize: "8px"
        }
    },
    heroBoxTitle: {
        color: "#2D2D2D",
        fontFamily: "Rubik",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "40px" /* 100% */,
        [theme.breakpoints.down(desktopBreakpoint)]: {
            textAlign: "center",
            leadingTrim: "both",
            textEdge: "cap",
            fontSize: "18px",
            lineHeight: "0px", /* 0% */
            width: "100%"
        }
    },
    heroBoxText: {
        color: "#2D2D2D",
        fontFamily: "Rubik",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "32px" /* 160% */,
        [theme.breakpoints.down(desktopBreakpoint)]: {
            textAlign: "center",
            fontSize: "13px",
            lineHeight: "normal",
            width: "100%"
        }
    },
    videoWrapper: {
        [theme.breakpoints.down(mobileBreakpoint)]: {
            aspectRatio: "16 / 9",
            width: "100%"
        }
    },
    youtube: {
        width: "700px",
        height: "368px",
        border: 0,
        borderRadius: 6,
        [theme.breakpoints.down(1400)]: {
            width: "450px",
            height: "234px"
        },
        [theme.breakpoints.between(1400, 1700)]: {
            width: "550px",
            height: "286px"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            width: "100%",
            height: "100%"
        }
    },
    searchWrapper: {
        display: "flex",
        padding: "40px 0px 75px 0px",
        alignItems: "center",
        gap: "60px",
        width: "auto",
        maxWidth: "1400px",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            flexDirection: "column-reverse",
            maxWidth: "100%"
        }
    },
    search: {
        display: "flex",
        flexDirection: "column",
        gap: "55px",
        [theme.breakpoints.down(desktopBreakpoint)]: {
            flexDirection: "column"
        }
    },
    section2Wrapper: {
        flex: "0 0 100%",
        backgroundColor: "#F8F8F8",
        display: "flex",
        justifyContent: "center",
        paddingTop: "75px",
        paddingBottom: "75px"
    },
    section2: {
        width: "80%",
        height: "auto",
        flexShrink: 0,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingLeft: "10%",
        paddingRight: "10%",
        gap: 50,
        [theme.breakpoints.between(mobileBreakpoint, mobileMenuBreakpoint)]: {
            maxWidth: "550px",
            width: "100%",
            paddingLeft: "0%",
            paddingRight: "0%"
        },
        [theme.breakpoints.down(desktopBreakpoint)]: {
            width: "80%"
        }
    },
    section2Text: {
        maxWidth: "782px",
        display: "flex",
        flexDirection: "column",
        gap: "20px"
    },
    holders: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "30px",
        maxWidth: "1399px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            width: "100%"
        },
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            alignItems: "center",
            flexDirection: "column"
        }
    },
    section3: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down(desktopBreakpoint)]: {
            width: "80%",
            flexDirection: "column",
            paddingTop: 40,
            paddingBottom: 40
        },
        [theme.breakpoints.between(mobileBreakpoint, mobileMenuBreakpoint)]: {
            width: "50%"
        }
    },
    section3Wrappper: {
        width: "50%",
        maxWidth: "618px",
        height: "550px",
        flexShrink: 0,
        backgroundColor: "#FFF",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingLeft: "60px",
        [theme.breakpoints.down(desktopBreakpoint)]: {
            padding: 0,
            width: "100%",
            height: "auto",
            maxWidth: "unset"
        }
    },
    section3TextBox: {
        display: "inlineFlex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "5px",
        [theme.breakpoints.down(desktopBreakpoint)]: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center"
        }
    },
    customSolutionImg: {
        width: "956px",
        height: "545px",
        flexShrink: 0,
        objectFit: "cover",
        [theme.breakpoints.down(desktopBreakpoint)]: {
            width: "485px",
            height: "282px"
        },
        [theme.breakpoints.down(505)]: {
            width: "250px",
            height: "146px"
        }
    },
    section4Wrapper: {
        flex: "0 0 100%",
        backgroundColor: "#F8F8F8",
        display: "flex",
        justifyContent: "center"
    },
    section4: {
        flexShrink: 0,
        backgroundColor: "#F8F8F8",
        width: "80%",
        paddingLeft: "10%",
        paddingRight: "10%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        [theme.breakpoints.between(mobileBreakpoint, mobileMenuBreakpoint)]: {
            maxWidth: "550px",
            width: "100%",
            paddingLeft: "0%",
            paddingRight: "0%"
        }
    },
    section4News: {
        maxWidth: "822px",
        display: "inline-flex",
        paddingTop: "75px",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        [theme.breakpoints.down(desktopBreakpoint)]: {
            width: "80%",
            paddingTop: 40
        },
        [theme.breakpoints.between(mobileBreakpoint, mobileMenuBreakpoint)]: {
            width: "100%"
        }
    },
    deviceHolder: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: "60px"
    },
    vehicleHolder: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: "150px"
    },
    blackH2: {
        color: "#2D2D2D",
        fontFamily: "Rubik",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "40px" /* 133.333% */,
        [theme.breakpoints.down(tabletBreakpoint)]: {
            textAlign: "center",
            fontSize: "20px",
            lineHeight: "26.8px"
        }
    },
    blackH3Medium: {
        color: "#2D2D2D",
        fontFamily: "Arial",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "16px", /* 114.286% */
        letterSpacing: "0.5px"
    },
    blackText: {
        color: "#2D2D2D",
        fontFamily: "Arial",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px", /* 142.857% */
        letterSpacing: "0.5px",
        [theme.breakpoints.down(desktopBreakpoint)]: {
            textAlign: "center"
        }
    },
    blackH2Roboto: {
        color: "#2D2D2D",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "40px" /* 200% */,
        [theme.breakpoints.down(desktopBreakpoint)]: {
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "32px" /* 177.778% */
        }
    },
    blackH3Roboto: {
        color: "#2D2D2D",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "32px" /* 160% */,
        [theme.breakpoints.down(desktopBreakpoint)]: {
            fontSize: "16px",
            fontWeight: "500px",
            lineHeight: "normal"
        }
    },
    blackArialText: {
        color: "#2D2D2D",
        fontFamily: "Arial",
        fontSize: "17px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "24px", /* 141.176% */
        letterSpacing: "0.5px",
        [theme.breakpoints.down(desktopBreakpoint)]: {
            fontSize: "14px",
            lineHeight: "20px"/* 142.857% */
        }
    },
    holderTextBox: {
        backgroundColor: "blue",
        borderRadius: "0px 0px 6px 6px",
        background: "#FFF",
        height: "128px",
        paddingTop: 20,
        paddingBottom: 20,
        marginTop: -5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down(desktopBreakpoint)]: {
            height: "auto",
            alignItems: "flex-start",
            paddingLeft: "20px"
        }
    },
    section3Button: {
        /* M3/label/medium */
        fontFamily: "Arial",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "16px", /* 114.286% */
        letterSpacing: "0.5px",
        textTransform: "none",
        color: "#2d2d2d"
    },
    section1Button: {
        display: "flex",
        padding: "10px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "4px",
        background: "#F09057",
        textDecoration: "none"
    },
    alignTextCenter: {
        [theme.breakpoints.down(tabletBreakpoint)]: {
            textAlign: "center"
        }
    },
    thumbNailIndicator: {
        width: "30px",
        height: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    thumbNailIndicatorCircle: {
        "&.MuiButton-root": {
            width: 12.5,
            height: 12.5,
            borderRadius: "50%",
            minWidth: 0,
            padding: 0
        },
        "&.MuiButton-contained": {
            backgroundColor: "black"
        }
    },
    heroWrapper: {
        display: "flex",
        flex: "0 0 100%",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "100%",
        backgroundImage: "url(/files/ImagesStatic/Hero/1920.webp)",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        height: "351px",
        [theme.breakpoints.up(mobileMenuBreakpoint)]: {
            height: "600px"
        },
        [theme.breakpoints.between(desktopBreakpoint, 2560)]: {
            backgroundImage: "url(/files/ImagesStatic/Hero/2560.webp)"
        },
        [theme.breakpoints.up(2560)]: {
            backgroundImage: "url(/files/ImagesStatic/Hero/3840.webp)"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            backgroundImage: "url(/files/ImagesStatic/Hero/768.webp)"
        }
    },
    fadeIn: {
        animation: "$fadeIn 500ms ease-in"
    },
    newsWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "40px",
        marginBottom: "40px"
    },
    selectorWrapper: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        flex: "1 0 235px"
    },
    selectorTitle: {
        flex: "0 0 100%",
        height: "48px",
        textAlign: "center",
        fontFamily: "Arial",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "24px",
        [theme.breakpoints.down(1700)]: {
            fontSize: "16px"
        }
    },
    steeringWrapper: {
        flex: "0 0 250px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center"
    },
    steering: {
        flex: "0 0 200px",
        marginBottom: "10px"
    },
    anchorButtonStyle: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.5px",
        padding: "10px 20px 10px 20px",
        borderRadius: "4px",
        textAlign: "center",
        textDecoration: "none",
        color: "#2D2D2D",
        backgroundColor: "#F09057",
        marginTop: "10px",
        maxWidth: "60%",
        transition: "all 0.1s ease-in",
        "&:hover": {
            backgroundColor: "#ef7830"
        }
    },
    downloadAnchor: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        fontFamily: "Arial",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "16px",
        letterSpacing: "0.5px",
        textAlign: "left",
        color: "#2D2D2D",
        textDecoration: "none",
        borderBottom: "1px solid transparent",
        "&:hover": {
            borderBottom: "1px solid #F09057"
        }
    },
    "@keyframes fadeIn": {
        "0%": { opacity: 0, transform: "translateX(-15px)" },
        "100%": { opacity: 1, transform: "translateX(0)" }
    },
    fadeOut: {
        animation: "$fadeOut 500ms ease-out"
    },
    "@keyframes fadeOut": {
        "0%": { opacity: 1, transform: "translateX(0)" },
        "100%": { opacity: 0, transform: "translateX(15px)" }
    },
    readmore: {
        display: "flex",
        width: "auto",
        alignItems: "center",
        gap: "5px",
        fontFamily: "Arial",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "16px",
        letterSpacing: "0.5px",
        textAlign: "left",
        color: "#2D2D2D",
        textDecoration: "none",
        [theme.breakpoints.up(desktopBreakpoint)]: {
            display: "inline-block",
            position: "relative",
            "&:after": {
                content: "''",
                position: "absolute",
                width: "100%",
                transform: "scaleX(0)",
                height: "2px",
                bottom: 0,
                left: 0,
                backgroundColor: "#F09057",
                transformOrigin: "bottom right",
                transition: "transform 0.25s ease-out"
            },
            "&:hover:after": {
                transform: "scaleX(1)",
                transformOrigin: "bottom left"
            }
        },
        [theme.breakpoints.down(desktopBreakpoint)]: {
            borderBottom: "2px solid #F09057"
        }
    },
    readmoreParent: {
        display: "flex",
        marginTop: "8px",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            justifyContent: "center"
        }
    },
    downloadCatalog: {
        textDecoration: "none",
        [theme.breakpoints.up(desktopBreakpoint)]: {
            display: "inline-block",
            position: "relative",
            "&:after": {
                content: "''",
                position: "absolute",
                width: "100%",
                transform: "scaleX(0)",
                height: "2px",
                bottom: 0,
                left: 0,
                backgroundColor: "#F09057",
                transformOrigin: "bottom right",
                transition: "transform 0.25s ease-out"
            },
            "&:hover:after": {
                transform: "scaleX(1)",
                transformOrigin: "bottom left"
            }
        },
        [theme.breakpoints.down(desktopBreakpoint)]: {
            borderBottom: "2px solid #F09057"
        }
    },
    imageGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "5px",
        marginBottom: "16px"
    },
    imageGridItem: {
        gridColumn: "span 1",
        "&:last-child": {
            gridColumn: "span 2"
        }
    },
    imageGridItemImage: {
        display: "block",
        width: "100%",
        borderRadius: "4px"
    }
}))
