import { mobileBreakpoint } from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    content: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        flexGrow: 1,
        // backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        [theme.breakpoints.down(mobileBreakpoint)]: {
            padding: "20px"
        }
    },
    stepper: {
        flex: "0 1 1920px"
    },
    checkoutWrapper: {
        display: "flex",
        flex: "0 1 1920px",
        justifyContent: "center"
    }
}));
