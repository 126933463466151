import { useLanguage } from "../../lib/hooks/languageHooks"
import { useSelector } from "react-redux"

// Uses the user steering setting on english website,
// and defaults to LHD on swedish language.
export const useSteering = () => {
    const language = useLanguage()
    return useSelector(
        state => (
            language === "en"
                ? (state.user.steering ?? "1")
                : "1"
        )
    )
}

export const useUser = () => useSelector(state => state.user)

export const useIsUserAuthorized = () => {
    const isAuthorized = useSelector(state => Boolean(state.user.authToken))
    const item = sessionStorage.getItem("isAuthorized")

    return isAuthorized && !!item
}

export const useUserAuthToken = () => useSelector(state => state.user.authToken)

export const useUserData = () => useSelector(state => state.user.user)

export const useSelectedModTek = () => useSelector(state => state.user.selectedModTek)
