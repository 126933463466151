/* eslint-disable react/destructuring-assignment */
import useStyles from "./styles"
import ProductViewToggleButton from "../ProductViewToggleButton/ProductViewToggleButton"
import React from "react"

const ProductNavigation = props => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            {props.children}
            {/* <Tooltip title={Localization.sort}>
                <Select value={selectedSortProperty.value} onChange={(e) => onSortPropChange(e.target.value)}>
                    {sortProperties.map((sortProperty) => (
                        <MenuItem key={sortProperty.value} value={sortProperty.value}>
                            {sortProperty.name}
                        </MenuItem>
                    ))}
                </Select>
            </Tooltip>
            <Tooltip title={Localization.toggleSortOrder}>
                <IconButton onClick={onSortClick}>
                    <SortByAlphaIcon />
                </IconButton>
            </Tooltip> */ }
            {!props.noShow && <ProductViewToggleButton />}
        </div>
    )
}

export default ProductNavigation
