import { desktopBreakpoint } from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    navigationButtonBox: {
        padding: 15,
        margin: 15,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    navigationButtonButton: {
        position: "absolute",
        height: "100%",
        zIndex: 1
    },
    keyboardArrow: {
        color: "white"
    },
    thumbNailStepper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    thumbNailStepperMain: {
        display: "flex",
        justifyContent: "center",
        position: "relative"
    },
    thumbNailIndicator: {
        width: "30px",
        height: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    thumbNailIndicatorCircle: {
        width: 12.5,
        height: 12.5,
        borderRadius: "50%"
    },
    blackH3: {
        color: "#2D2D2D",
        /* H3 */
        fontFamily: "Rubik",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "32px" /* 160% */,
        [theme.breakpoints.down(desktopBreakpoint)]: {
            fontFamily: "Roboto",
            fontSize: "18px"
        }
    },
    hoverUnderline: {
        display: "inline-block",
        position: "relative",
        "&:after": {
            content: "''",
            position: "absolute",
            width: "100%",
            transform: "scaleX(0)",
            height: "2px",
            bottom: 0,
            left: 0,
            backgroundColor: "#F09057",
            transformOrigin: "bottom right",
            transition: "transform .5s ease-out"
        },
        "&.isActive:after": {
            transform: "scaleX(1)",
            transformOrigin: "bottom left"
        }
    }
}))
