/* eslint-disable import/prefer-default-export */
import { restoreNameForBrandOther } from "../../lib/helper/brandHelper"
import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const fetchCollectionsForModel = async (language, brandId) => {
    const [restoredBrandId] = restoreNameForBrandOther([brandId])
    const response = await axios.get(
        `/api/ProductInfo/GetCollectionsForBrand?language=${language}&brandId=${restoredBrandId}`
    )
    return response.data
}

export const requestCollectionsForBrand = createAsyncThunk(
    "collections/fetchForBrand",
    async params => {
        const { lang, brandId } = params
        return fetchCollectionsForModel(lang, brandId)
    }
)
