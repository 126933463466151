import { desktopBreakpoint } from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    content: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        [theme.breakpoints.down(desktopBreakpoint)]: {
            width: "90%",
            padding: "5%"
        }
    },
    capitalized: {
        textTransform: "uppercase"
    }
}))
