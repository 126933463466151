import { productAdapter } from "./adapter/productAdapter"
import { requestProduct, requestProducts, requestFilteredProducts } from "./thunk/productThunk"
import { prepareProducts } from "../components/CompareProducts/helperFunctions"
import { createSlice } from "@reduxjs/toolkit"

const slice = createSlice({
    name: "product",
    initialState: productAdapter.getInitialState({
        lastProductFetch: [],
        lastFetchItemCount: 0,
        lastFetchProperties: {
            brands: [],
            productNames: []
        },
        loading: false,
        pendingRequests: 0
    }),
    reducers: {
        reset: () => {
            productAdapter.removeAll()
        },
        deleteSearchResult: state => {
            state.lastProductFetch = []
            state.lastFetchItemCount = 0
            state.lastFetchProperties = {
                brands: [],
                productNames: []
            }
        }
    },
    extraReducers: builder => {
        // Request one
        builder.addCase(requestProduct.pending, state => {
            state.pendingRequests += 1
            state.loading = Boolean(state.pendingRequests)
        })
        builder.addCase(requestProduct.fulfilled, (state, action) => {
            state.pendingRequests -= 1
            state.loading = Boolean(state.pendingRequests)

            const pagedList = action.payload?.pagedList.response
            if (!pagedList || pagedList.list.length === 0) {
                return
            }

            const products = prepareProducts(pagedList.list, action.meta.arg.lang)
            productAdapter.addOne(state, products[0])
        })

        builder.addCase(requestProduct.rejected, state => {
            state.pendingRequests -= 1
            state.loading = Boolean(state.pendingRequests)
        })

        builder.addCase(requestProducts.pending, state => {
            state.pendingRequests += 1
            state.loading = Boolean(state.pendingRequests)
        })
        builder.addCase(requestProducts.fulfilled, (state, action) => {
            state.pendingRequests -= 1
            state.loading = Boolean(state.pendingRequests)

            const pagedList = action.payload?.pagedList.response
            if (!pagedList || pagedList.list.length === 0) {
                return
            }

            const products = prepareProducts(pagedList.list, action.meta.arg.lang)
            productAdapter.addMany(state, products)
        })
        builder.addCase(requestProducts.rejected, state => {
            state.pendingRequests -= 1
            state.loading = Boolean(state.pendingRequests)
        })

        // Request many
        builder.addCase(requestFilteredProducts.pending, state => {
            state.pendingRequests += 1
            state.loading = Boolean(state.pendingRequests)
        })
        builder.addCase(requestFilteredProducts.fulfilled, (state, action) => {
            state.pendingRequests -= 1
            state.loading = Boolean(state.pendingRequests)

            const pagedList = action.payload?.pagedList.response
            if (!pagedList) {
                return
            }

            const products = prepareProducts(pagedList.list, action.meta.arg.lang)
            state.lastProductFetch = products.map(x => x.originalId)
            state.lastFetchItemCount = pagedList.pagingHeader.totalItems
            state.lastFetchProperties = {
                brands: action.payload.brands,
                productNames: action.payload.names
            }

            productAdapter.addMany(state, products)
        })
        builder.addCase(requestFilteredProducts.rejected, state => {
            state.pendingRequests -= 1
            state.loading = Boolean(state.pendingRequests)
        })
    }
})

const { reset, deleteSearchResult } = slice.actions

export default slice.reducer

export const resetProductsCache = () => dispatch => {
    dispatch(reset())
}

export const clearSearchResult = () => dispatch => {
    dispatch(deleteSearchResult())
}
