import { createTheme } from "@mui/material"
import { grey } from "@mui/material/colors"

export default createTheme({
    props: {
        MuiButtonBase: {
            disableRipple: true
        }
    },
    palette: {
        primary: {
            main: "#F09057"
        },
        secondary: {
            main: grey[500]
        },
        contrastThreshold: 3,
        background: {
            default: "#fafafa"
        }
    },
    typography: {
        body2: {
            fontSize: "1rem"
        }
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        borderColor: "#6C6C6C"
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #2d2d2d"
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    "&.MuiSelect-standard": {
                        borderBottom: "1px solid #2d2d2d"
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: "#2d2d2d",
                    borderColor: "#2d2d2d"
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: "#2d2d2d",
                    borderColor: "#2d2d2d",
                    textDecorationColor: "#2d2d2d",
                    fontWeight: 600
                }
            }
        }
    }
})
