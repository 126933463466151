import { makeStyles } from "@mui/styles"

const POPOVER_WIDTH = 650
export default makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    paper: {
        width: POPOVER_WIDTH
    },
    spacing1: {
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1)
    },
    spacing2: {
        padding: theme.spacing(2)
    },
    flex: {
        display: "flex"
    },
    flexGrow1: {
        flexGrow: 1
    }
}))
