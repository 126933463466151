import useStyles from "./styles"
import { strings as Localization } from "../../lib/Localization"
import { setSnackbarMessage } from "../../redux/userMessageSlice"
import { useLanguage } from "../../lib/hooks/languageHooks"
import { mailRegex } from "../../lib/helper/commonHelper"
import { useRecaptchaSiteKey } from "../../redux/hook/settingsHooks"
import React, { useEffect, useRef, useState } from "react"
import {
    Tooltip, IconButton, Modal, Box, Typography, TextField, Button, CircularProgress
} from "@mui/material"
import ShareIcon from "@mui/icons-material/Share"
import { useForm } from "react-hook-form"
import ReCAPTCHA from "react-google-recaptcha"
import axios from "axios"
import { useDispatch } from "react-redux"
import CloseIcon from "@mui/icons-material/Close"

const defaultVales = {
    recaptcha: undefined
}

const ShareButton = ({ productId }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [modalOpen, setModalOpen] = useState(false)
    const recaptchaRef = useRef()
    const [loading, setLoading] = useState(false)
    const lang = useLanguage()
    const recaptchaSiteKey = useRecaptchaSiteKey()

    const {
        register, setValue, watch, handleSubmit, formState: { errors }
    } = useForm({ defaultValues: defaultVales }) /* recapthca ska in */

    const handleShareButtonClick = () => {
        setModalOpen(true)
    }

    const handleModalClose = () => {
        setModalOpen(false)
    }

    const onChangeRecaptcha = value => {
        setValue("recaptcha", value)
    }

    const onExpired = () => {
        setValue("recaptcha", undefined)
    }

    useEffect(() => {
        // when component is loaded, those can essentially be set
        setValue("productId", productId)
        setValue("language", lang)
    }, [productId, lang])

    const onSubmit = async data => {
        setLoading(true)

        let status
        try {
            const response = await axios.post(
                `/api/Users/ShareProduct?token=${data.recaptcha}`,
                data
            )
            status = response.status
        } catch (error) {
            status = error?.response?.status
        }

        setLoading(false)

        switch (status) {
            case 200:
                dispatch(setSnackbarMessage(Localization.youHaveSharedTheProduct, "success"))
                break
            case 400:
                dispatch(setSnackbarMessage(Localization.somethingWentWrongWhenSharingProduct, "warning"))
                break
            case 401:
                dispatch(setSnackbarMessage(Localization.recaptchaError, "warning"))
                break
            default:
                dispatch(setSnackbarMessage(Localization.unknownError, "error"))
        }

        setTimeout(() => {
            if (recaptchaRef.current) recaptchaRef.current.reset()
            setValue("recaptcha", undefined)
            setModalOpen(false)
        }, 500)
    }

    return (
        <>
            <Tooltip title={Localization.share}>
                <IconButton className={classes.iconButton} onClick={handleShareButtonClick}>
                    <ShareIcon className={classes.rotated} />
                </IconButton>
            </Tooltip>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <Box className={classes.paper}>
                    <Box style={{ display: "flex", flexDirection: "row" }}>
                        <Typography variant="h5">{Localization.shareThis}</Typography>
                        <Box style={{ flexGrow: 1 }} />
                        <Tooltip title={Localization.close}>
                            <IconButton onClick={() => setModalOpen(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box style={{ padding: 20, display: "flex", flexDirection: "column" }}>
                                <TextField
                                    {...register("yourEmail", { required: true, validate: value => mailRegex.test(value) })}
                                    variant="standard"
                                    label={Localization.yourEmail}
                                    error={Boolean(errors.yourEmail)}
                                />
                                <TextField
                                    {...register("toEmail", { required: true, validate: value => mailRegex.test(value) })}
                                    variant="standard"
                                    label={Localization.receiversEmail}
                                    error={Boolean(errors.toEmail)}
                                    style={{ height: 50 }}
                                />
                                <TextField
                                    {...register("message")}
                                    variant="standard"
                                    label={Localization.message}
                                    multiline
                                />
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    style={{ marginTop: 20, marginBottom: 20 }}
                                    sitekey={recaptchaSiteKey}
                                    onExpired={onExpired}
                                    onChange={onChangeRecaptcha}
                                    hl={lang}
                                />
                                <Button
                                    type="submit"
                                    disabled={loading || !watch("recaptcha")}
                                >
                                    {loading ? <CircularProgress /> : Localization.share}

                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default ShareButton
