import withdrawCookiesConsent from "../../lib/helper/gdprHelper"
import { useLanguage } from "../../lib/hooks/languageHooks"
import { strings as Localization } from "../../lib/Localization"
import { Container, Typography, useTheme } from "@mui/material"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

function WithdrawCookiesConsent() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const language = useLanguage()

    useEffect(() => {
        dispatch(withdrawCookiesConsent({ skipReload: true }))
        window.location.replace(`/${language}`)
    }, [])

    return (
        <Container
            style={{
                backgroundColor: theme.palette.background.default,
                padding: theme.spacing(6),
                paddingTop: theme.spacing(8),
                minHeight: 600
            }}
        >
            <Typography>{Localization.redirectingToStartPage}</Typography>
        </Container>
    )
}

export default WithdrawCookiesConsent
