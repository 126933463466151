import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        flexGrow: 1,
        padding: 15
    },
    button: {
        backgroundColor: "white"
    },
    centerContent: {
        display: "flex",
        justifyContent: "center"
    },
    select: {
        "&:before": {
            borderBottom: "none"
        },
        "&:after": {
            borderBottom: "none"
        }
    }
}))
