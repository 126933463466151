import { mobileMenuBreakpoint } from "../../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3)
    },
    marginTop50: {
        marginTop: 50
    },
    textSkeleton: {
        marginTop: 50,
        marginBottom: 15,
        width: "33%"
    },
    rectSkeleton: {
        height: 500
    },
    newsGrid: {
        display: "grid",
        gridTemplateColumns: "50% 50%",
        gap: "10px",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            gridTemplateColumns: "100%"
        }
    }
}))
