import { useLanguage } from "../../lib/hooks/languageHooks"
import { strings as Localization } from "../../lib/Localization"
import { Link } from "react-router-dom"
import { Button } from "@mui/material"
import React from "react"

function SearchDealerLink() {
    const language = useLanguage()

    return (
        <Button
            component={Link}
            variant="contained"
            color="primary"
            to={`/${language}/retailers`}
        >
            {Localization.whereCanIBuy}
        </Button>
    )
}

export default SearchDealerLink
