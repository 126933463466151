import { desktopBreakpoint, mobileMenuBreakpoint } from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    hero: {
        display: "flex",
        flex: "0 1 1920px",
        justifyContent: "center"
    },
    youtube: {
        aspectRatio: "16 / 9",
        flex: "0 1 1400px"
    },
    topInfo: {
        display: "flex",
        flexDirection: "column",
        flex: "0 1 857px",
        marginTop: 100,
        marginBottom: 100,
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            flex: "0 1 100%",
            marginTop: "unset",
            marginBottom: "unset",
            padding: 20
        }
    },
    topTitle: {
        fontFamily: "Rubik",
        fontSize: 30,
        fontWeight: 500,
        lineHeight: "40px",
        textAlign: "center",
        marginTop: 0,
        marginBottom: 20,
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            fontSize: 20,
            lineHeight: "26px",
            textAlign: "left",
            marginBottom: 15
        }
    },
    topText: {
        fontFamily: "Arial",
        fontSize: "17px",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: 0.5,
        textAlign: "center",
        whiteSpace: "pre-line",
        marginBottom: 20,
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            fontSize: 14,
            lineHeight: "20px",
            textAlign: "left"
        }
    },
    topAnchorWrapper: {
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            justifyContent: "flex-start"
        }
    },
    topAnchor: {
        fontFamily: "Arial",
        fontSize: 14,
        fontWeight: 700,
        lineHeight: "16px",
        letterSpacing: 0.5,
        textDecoration: "none",
        color: "#2D2D2D",
        [theme.breakpoints.up(desktopBreakpoint)]: {
            display: "inline-block",
            position: "relative",
            "&:after": {
                content: "''",
                position: "absolute",
                width: "100%",
                transform: "scaleX(0)",
                height: "2px",
                bottom: 0,
                left: 0,
                backgroundColor: "#F09057",
                transformOrigin: "bottom right",
                transition: "transform 0.25s ease-out"
            },
            "&:hover:after": {
                transform: "scaleX(1)",
                transformOrigin: "bottom left"
            }
        },
        [theme.breakpoints.down(desktopBreakpoint)]: {
            borderBottom: "2px solid #F09057"
        }
    },
    storyWrapper: {
        display: "flex",
        flex: "0 1 100%",
        backgroundColor: "#F8F8F8",
        justifyContent: "center",
        paddingTop: 100,
        paddingBottom: 100,
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            paddingTop: 30,
            paddingBottom: 30,
            paddingLeft: 20,
            paddingRight: 20
        }
    },
    story: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: "0 1 850px",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            flex: "0 1 100%",
            alignItems: "flex-start"
        }
    },
    storyTitle: {
        fontFamily: "Rubik",
        fontSize: 30,
        fontWeight: 500,
        lineHeight: "40px",
        textAlign: "center",
        marginTop: 0,
        marginBottom: 5,
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            fontSize: 20,
            lineHeight: "26px",
            textAlign: "left",
            marginBottom: 15
        }
    },
    storyText: {
        fontFamily: "Arial",
        fontSize: "17px",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: 0.5,
        textAlign: "center",
        whiteSpace: "pre-line",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            fontSize: 14,
            lineHeight: "20px",
            textAlign: "left"
        }
    }
}))
