import { createEntityAdapter } from "@reduxjs/toolkit";

export const materialAdapter = createEntityAdapter({
    selectId: material => material.id,
    sortComparer: (a, b) => a.id.localeCompare(b.id)
});

export const materialSelectors = materialAdapter.getSelectors(state => state.material)

export const { selectById, selectAll } = materialSelectors;
