import { newsArticleAdapter } from "./adapter/newsArticleAdapter";
import { requestNewsArticleById, requestNewsArticles } from "./thunk/newsArticleThunk";
import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "newsArticle",
    initialState: newsArticleAdapter.getInitialState(),
    reducers: {},
    extraReducers: builder => {
        builder.addCase(requestNewsArticleById.fulfilled, (state, action) => {
            newsArticleAdapter.addOne(state, {
                ...action.payload,
                id: `${action.meta.arg.lang}-${action.payload.id}`,
                originalId: action.payload.id
            })
        });
        builder.addCase(requestNewsArticles.fulfilled, (state, action) => {
            newsArticleAdapter.addMany(state, action.payload.map(article => ({
                ...article,
                id: `${action.meta.arg.lang}-${article.id}`,
                originalId: article.id
            }))); // meta.arg.lang = lang paramter
        });
    }
})

export default slice.reducer;
