import { isSearchQueryEmpty } from "../../lib/helper/searchHelper"
import React, { useMemo } from "react"
import { useSelector } from "react-redux"

/**
 * Combines all or the specified search queries into a single search query object.
 * @param  {...string} searchQueryTypes The name of the search queries from
 * state.search.searchQueries you want to combine into a single search query.
 * Note: Combines all available queries if left empty.
 * @returns A search query object.
 *
 * @example
 * // Set the search queries.
 * dispatch(setSearchQuery({ type: "mySearchQuery", value: { toys: ["Airplane", "Firetruck"] } }))
 * dispatch(setSearchQuery({ type: "mySecondSearchQuery", value: { toys: ["Racecar"] } }))
 *
 * // Provide a type to only fetch the value of the specified type.
 * useSearchQuery("mySearchQuery")
 * // #=> { toys:['Airplane', 'Firetruck'] }
 *
 * // Provide multiple types to get a combined value of the types specified.
 * useSearchQuery("mySearchQuery", "mySecondSearchQuery")
 * // #=> { toys:['Airplane', 'Firetruck', 'Racecar'] }
 *
 * // Providing no type will combine all available types into a single value.
 * useSearchQuery()
 * // #=> { toys:['Airplane', 'Firetruck', 'Racecar'] }
 *
 */
export const useSearchQuery = (...searchQueryTypes) => {
    const searchQueries = useSelector(state => state.search.searchQueries)

    let selectedSearchQueries
    let dependencies
    if (searchQueryTypes.length > 0) {
        selectedSearchQueries = searchQueryTypes.reduce(
            (result, type) => [...result, searchQueries[type]],
            []
        )
        dependencies = selectedSearchQueries
        selectedSearchQueries = selectedSearchQueries.filter(value => Boolean(value))
    } else {
        selectedSearchQueries = Object.values(searchQueries)
        dependencies = [searchQueries]
    }

    const memorizedSearchQuery = React.useMemo(
        () => {
            if (selectedSearchQueries.length === 1) {
                return { ...selectedSearchQueries[0] }
            }

            // Combines a list of objects into a single object, where each
            // key in each object contains an array.
            //
            // -- Example input --
            // selectedSearchQueries: [
            //     { brands: ["a"], models: ["x"] },
            //     { brands: ["b"] },
            // ]
            // result => { brands: ["a", "b"], models: ["x"] }
            const searchQuery = selectedSearchQueries.reduce(
                (previousValue, currentValue) => {
                    const result = { ...previousValue, ...currentValue }

                    Object.keys(result).forEach(key => {
                        result[key] = [
                            ...previousValue?.[key] ?? [],
                            ...currentValue?.[key] ?? []
                        ]
                    })

                    return result
                },
                {}
            )

            Object.keys(searchQuery).forEach(key => {
                // Converting to and from a Set removes the duplicates.
                searchQuery[key] = [...new Set(searchQuery[key])]
            })

            return searchQuery
        },

        // Both the warnings about missing dependencies here are indirectly
        // included in the dependencies array. If "searchQueryTypes" or
        // "selectedSearchQueries" change, so will "dependencies" since
        // it is constructed from these two variables.
        dependencies
    )

    return memorizedSearchQuery
}

export const useSearchQuery2 = (...searchQueryTypes) => {
    const searchQueries = useSelector(state => state.search2.searchQueries)

    let selectedSearchQueries
    let dependencies
    if (searchQueryTypes.length > 0) {
        selectedSearchQueries = searchQueryTypes.reduce(
            (result, type) => [...result, searchQueries[type]],
            []
        )
        dependencies = selectedSearchQueries
        selectedSearchQueries = selectedSearchQueries.filter(value => Boolean(value))
    } else {
        selectedSearchQueries = Object.values(searchQueries)
        dependencies = [searchQueries]
    }

    const memorizedSearchQuery = React.useMemo(
        () => {
            if (selectedSearchQueries.length === 1) {
                return { ...selectedSearchQueries[0] }
            }

            // Combines a list of objects into a single object, where each
            // key in each object contains an array.
            //
            // -- Example input --
            // selectedSearchQueries: [
            //     { brands: ["a"], models: ["x"] },
            //     { brands: ["b"] },
            // ]
            // result => { brands: ["a", "b"], models: ["x"] }
            const searchQuery = selectedSearchQueries.reduce(
                (previousValue, currentValue) => {
                    const result = { ...previousValue, ...currentValue }

                    Object.keys(result).forEach(key => {
                        result[key] = [
                            ...previousValue?.[key] ?? [],
                            ...currentValue?.[key] ?? []
                        ]
                    })

                    return result
                },
                {}
            )

            Object.keys(searchQuery).forEach(key => {
                // Converting to and from a Set removes the duplicates.
                searchQuery[key] = [...new Set(searchQuery[key])]
            })

            return searchQuery
        },

        // Both the warnings about missing dependencies here are indirectly
        // included in the dependencies array. If "searchQueryTypes" or
        // "selectedSearchQueries" change, so will "dependencies" since
        // it is constructed from these two variables.
        dependencies
    )

    return memorizedSearchQuery
}

/**
 * Evaluates if a searchQuery object is empty.
 * @param {*} searchQueryObject A search query object.
 * @returns Returns true if the search query object does not contain any values.
 */
export const useIsSearchQueryEmpty = searchQueryObject => useMemo(
    () => isSearchQueryEmpty(searchQueryObject),
    [searchQueryObject]
)
