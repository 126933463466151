import useStyles from "./styles"
import { strings as Localization } from "../../lib/Localization"
import { useLanguage } from "../../lib/hooks/languageHooks"
import NoIndexMetaData from "../MetaData/NoIndexMetadata"
import { NavLink } from "react-router-dom"
import { Typography } from "@mui/material"
import React from "react"

const NotFound = () => {
    const classes = useStyles()
    const language = useLanguage()

    return (
        <main className={classes.content}>
            <NoIndexMetaData />
            <Typography variant="h3">{Localization.notFound}</Typography>
            <Typography>
                {Localization.thisPageWasNotFoundGoBackTo}
                <NavLink to={`/${language}/`}>
                    {Localization.startPage}
                </NavLink>
                .
            </Typography>
        </main>
    )
}

export default NotFound
