import { tabletBreakpoint } from "../../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    steeringButton: {
        position: "relative",
        display: "flex",
        backgroundColor: "transparent",
        cursor: "pointer",
        borderRadius: "6px",
        padding: 0,
        overflow: "hidden",
        border: "1px solid #F09057",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            flex: "0 0 100%"
        }
    },
    option: {
        flex: "0 0 50%",
        position: "relative",
        zIndex: 2,
        padding: "6px",
        background: 0,
        border: 0,
        transition: "all 0.1s ease-in",
        cursor: "pointer",
        color: "#2d2d2d"
    },
    active: {
        color: "white",
        cursor: "auto"
    },
    slide: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "50%",
        right: 0,
        backgroundColor: "#F09057",
        transition: "all 0.1s ease-in",
        zIndex: 1
    },
    slideRight: {
        left: "0",
        right: "50%"
    }
}))
