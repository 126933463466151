import { createSlice } from "@reduxjs/toolkit"

const slice = createSlice({
    name: "userMessage",
    initialState: {
        snackPack: []
    },
    reducers: {
        setMessage: (state, action) => {
            state.snackPack.push(
                {
                    message: action.payload.message,
                    key: new Date().getTime(),
                    severity: action.payload.severity ?? "info",
                    type: action.payload.type ?? "snackbar",
                    autoHide: action.payload.autoHide ?? false
                }
            )
        },
        nextSnackPack: state => {
            state.snackPack = state.snackPack.slice(1)
        }
    }
})

const { setMessage, nextSnackPack } = slice.actions

export default slice.reducer

export const setSnackbarMessage = (message, severity) => dispatch => dispatch(
    setMessage({
        message,
        severity,
        autoHide: true,
        type: "snackbar"
    })
)

export const setDialogMessage = message => dispatch => dispatch(
    setMessage({
        message,
        autoHide: false,
        type: "dialog"
    })
)

export const setNextSnackPack = () => dispatch => dispatch(
    nextSnackPack()
)
