import { useLanguage } from "../../lib/hooks/languageHooks";
import { selectById } from "../adapter/materialAdapter";
import { requestMaterial } from "../thunk/materialThunk";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const useMaterial = id => {
    const dispatch = useDispatch();
    const lang = useLanguage();

    useEffect(() => {
        const params = { id, lang };
        dispatch(requestMaterial(params));
    }, [lang, id, dispatch]);

    return useSelector(state => selectById(state, `${lang}-${id}`));
}

export default useMaterial
