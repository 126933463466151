/* eslint-disable array-callback-return */
import { strings as Localization } from "../../lib/Localization"

export const persistScrollPosition = id => {
    sessionStorage.setItem("scroll-position-product-id-marker", id)
}

export const transformProductForCompare = product => {
    // flatten out the product properties
    const propertiesAsDisplayNameValue = product.properties.reduce(
        (l, { displayName, value }) => ({ ...l, [displayName.toLowerCase()]: value }),
        {}
    )

    const compatibilityListString = product.compatibleModels.map(x => (x.brand !== "," ? `${x.brand} ${x.model} ${x.years ?? ""}` : null)).join("\n")
    const placement = [...new Set(product.compatibleModels.map(x => x.placement))].join("\n")

    const newProd = product.isNew ? Localization.yes : Localization.no

    const p = {
        ...product, ...propertiesAsDisplayNameValue, compatibilityListString, newProd, placement
    }

    return p
}

export const productTransformation = products => {
    const sectionMapping = [
        {
            Description: Localization.product,
            MappedFields: [
                {
                    Description: Localization.itemNumber,
                    Field: "originalId"
                },
                {
                    Description: Localization.name,
                    Field: "name"
                },
                {
                    Description: Localization.description,
                    Field: "description"
                },
                {
                    Description: Localization.eanNumber,
                    Field: "eanNumber"
                },
                {
                    Description: Localization.placement,
                    Field: "placement",
                    DisplayDefaultValues: true
                },
                {
                    Description: Localization.fitsFor,
                    Field: "compatibilityListString",
                    DisplayDefaultValues: true
                },
                {
                    Description: Localization.newProduct,
                    Field: "newProd"
                },
                {
                    Description: Localization.technicalInformation,
                    Field: "technicalInformation"
                }
            ]
        },
        {
            Description: Localization.dimensionsAndWeight,
            MappedFields: [
                {
                    Description: Localization.width,
                    Field: "width",
                    DisplayDefaultValues: true
                },
                {
                    Description: Localization.height,
                    Field: "height",
                    DisplayDefaultValues: true
                },
                {
                    Description: Localization.depth,
                    Field: "depth",
                    DisplayDefaultValues: true
                },
                {
                    Description: Localization.weight,
                    Field: "weight",
                    DisplayDefaultValues: true
                },
                {
                    Description: Localization.volume,
                    Field: "volume",
                    DisplayDefaultValues: true
                }
            ]
        }
    ]

    const sections = []

    sectionMapping.map(s => {
        const section = {
            Description: s.Description,
            Data: []
        }

        s.MappedFields.map(mf => {
            const sectionData = {
                Description: mf.Description,
                Values: []
            }

            let values = []

            values = products.map(x => {
                if (x[mf.Field] !== undefined) { // There is a value, take it
                    return x[mf.Field]
                }
                if (mf.DisplayDefaultValues) { // Take default value
                    return Localization.notApplicable
                }
                return x[mf.Field]
            })

            sectionData.Values = values

            section.Data.push(sectionData)
        })
        sections.push(section)
    })

    return sections
}

export const prepareProducts = (products, language) => products.map(product => (
    {
        ...product,
        id: `${language}-${product.id}`,
        originalId: product.id
    }
))
