import useStyles from "../styles"
import { Box, Button } from "@mui/material"
import React from "react"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"

function NavigationButton({ onClick, rightSide, style }) {
    const classes = useStyles()
    return (
        <Button
            className={classes.navigationButtonButton}
            style={{
                left: (!rightSide) ? 0 : null,
                right: (rightSide) ? 0 : null,
                ...style
            }}
            onClick={onClick}
        >
            <Box
                bgcolor="text.disabled"
                className={classes.navigationButtonBox}
            >
                {
                    rightSide
                        ? <KeyboardArrowRight className={classes.keyboardArrow} />
                        : <KeyboardArrowLeft className={classes.keyboardArrow} />
                }
            </Box>
        </Button>
    )
}

export default NavigationButton
