import { mobileBreakpoint } from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    result: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    capitalized: {
        textTransform: "uppercase"
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column"
    },
    comparisonHeader: {
        display: "flex",
        gridGap: "10px",
        flex: "1 1 auto",
        justifyContent: "center",
        position: "relative",
        width: "100%",
        flexWrap: "wrap"
    },
    padding8: {
        padding: 8
    },
    paperWrapper: {
        display: "flex",
        flexDirection: "column",
        flex: "1 auto",
        alignItems: "flex-start"
    },
    fullWidth: {
        width: "100%"
    },
    tableCell: {
        minWidth: 112,
        fontWeight: 700,
        wordBreak: "break-word"
    },
    informations: {
        whiteSpace: "pre-line",
        verticalAlign: "top"
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 10
    },
    pdfButton: {
        flex: "0 1 auto",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            flex: "0 0 100%"
        }
    }
}))
