import useStyles from "./styles"
import { NavLink } from "react-router-dom"
import React from "react"

const NewsCard = ({ item, type, language }) => {
    const classes = useStyles()

    const urlParts = item.originalId.split("\\")
    const urlByType = type !== "device" ? `/${language}/product/${item.products?.[0]}` : `/${language}/news/${urlParts[0]}/${urlParts[1]}`

    const isVehicle = type === "vehicle"

    return (
        <NavLink to={urlByType} className={classes.item} id={item.title} end>
            <div className={classes.imageWrapper}>
                <img className={classes.image} loading="lazy" src={`${item?.images?.[0]}?&language=${language}`} alt={item?.title} />
            </div>
            <div className={classes.bottomWrapper}>
                <div className={classes.textWrapper}>
                    <span className={classes.cardTitle} style={{ display: item?.title?.length > 20 ? "flex" : "block", flexWrap: "wrap" }}>
                        <span style={{ flexGrow: item?.title?.length >= 20 ? 1 : 0, marginRight: item?.title?.length >= 20 ? "0px" : "8px" }}>{`${item?.title} ${(item?.title?.length <= 20 && isVehicle) ? item?.year : ""}`}</span>
                        {(isVehicle && item?.title?.length > 20) && <span style={{ flexGrow: item?.title?.length > 20 ? 1 : 0 }}>{item?.year}</span>}
                    </span>
                </div>
                {isVehicle
                    && (
                        <span className={classes.cardUnderTitle}>
                            {`${item?.placement} (${item?.products[0]})`}
                        </span>
                    )}
            </div>
        </NavLink>
    )
}

export default NewsCard
