import useStyles from "./styles"
import { strings as Localization } from "../../lib/Localization"
import theme from "../../theme"
import { mobileBreakpoint } from "../../lib/helper/viewportHelper"
import {
    Box, MenuItem, Select, Typography, useMediaQuery
} from "@mui/material"
import React from "react"

const DropdownPagination = ({
    values, index, setIndex, itemCountText
}) => {
    const handleSpecific = event => {
        const value = event.target.value
        setIndex(value)
    }
    const classes = useStyles()
    const isMobile = useMediaQuery(() => theme.breakpoints.down(mobileBreakpoint))

    return (
        <Box
            style={{
                display: "flex",
                alignItems: "center"
            }}
        >
            {!isMobile
                && (
                    <Typography>
                        {Localization.displaying}
                        &nbsp;&nbsp;
                    </Typography>
                )}
            <Select
                id="paginationDropdown"
                data-offset={index}
                value={(values.length > 0) ? index : ""}
                onChange={handleSpecific}
                className={classes.select}
                variant="standard"
            >
                {
                    values.map((value, currentIndex) => (
                        <MenuItem key={currentIndex} value={currentIndex}>{value}</MenuItem>
                    ))
                }
            </Select>
            <Typography id="itemCount" data-count={itemCountText}>
                &nbsp;
                {Localization.outOf}
                {" "}
                {itemCountText}
                {" "}
                {Localization.results}
                .
            </Typography>
        </Box>
    )
}

export default DropdownPagination
