import useStyles from "./styles"
import DownloadForm from "./DownloadForm/DownloadForm"
import { strings as Localization } from "../../lib/Localization"
import DynamicContent from "../DynamicContentPage/DynamicContent/DynamicContent"
import { desktopBreakpoint } from "../../lib/helper/viewportHelper"
import {
    Typography, Box, useTheme, useMediaQuery
} from "@mui/material"
import React from "react"

const FileDownload = () => {
    const classes = useStyles()
    const theme = useTheme()
    const isDesktop = useMediaQuery(t => t.breakpoints.up(desktopBreakpoint))

    return (
        <main className={classes.content}>
            <Typography variant="h3" className={classes.capitalized} gutterBottom>{Localization.fileDownload}</Typography>
            <Box style={{ display: "flex", flexDirection: isDesktop ? "row" : "column", paddingTop: theme.spacing(2) }}>
                <Box style={{ width: isDesktop ? 250 : null }}>
                    <DownloadForm />
                </Box>
                <Box style={{ width: isDesktop ? 500 : null }}>
                    <DynamicContent type="filedownload" />
                </Box>
            </Box>
        </main>
    )
}

export default FileDownload
