import ProductComparisonTable from "./components/ProductComparisonTable"
import { transformProductForCompare } from "./helperFunctions"
import useStyles from "./styles"
import { useLanguage } from "../../lib/hooks/languageHooks"
import { useUrlQuery } from "../../lib/hooks/urlQueryHooks"
import { strings as Localization } from "../../lib/Localization"
import { addToCompare, setIsOnComparePage } from "../../redux/compareProductsReducer"
import { useCompareProducts } from "../../redux/hook/compareHooks"
import { useFilteredProducts } from "../../redux/hook/productHooks"
import { productViewBreakpoint } from "../../lib/helper/viewportHelper"
import theme from "../../theme"
import { batch, useDispatch } from "react-redux"
import React, { useEffect, useMemo, useState } from "react"
import { Box, Typography, useMediaQuery } from "@mui/material"
import Button from "@mui/material/Button"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import axios from "axios"
import CircularProgress from "@mui/material/CircularProgress"
import DoneIcon from "@mui/icons-material/Done"

const CompareProducts = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const isMobile = useMediaQuery(theme.breakpoints.down(productViewBreakpoint))
    const language = useLanguage()
    const queryParams = useUrlQuery()
    const productForCompareIds = useCompareProducts()
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const filter = useMemo(
        () => ({ productIds: productForCompareIds ?? [] }),
        [productForCompareIds]
    )
    let products = useFilteredProducts(filter)
    products = products.map(p => transformProductForCompare(p))

    const getCompareUrl = () => {
        const cleanedUrl = window.location.toString().replace(window.location.search, "")
        const url = new URL(cleanedUrl)
        if (productForCompareIds && productForCompareIds.length > 0) {
            url.searchParams.set("id", productForCompareIds)
            url.searchParams.sort()
        }
        return url
    }

    const setCompareUrl = () => {
        const url = getCompareUrl()
        window.history.pushState({}, "", url)
    }

    useEffect(() => {
        const ids = (queryParams.get("id") ?? "")
            .split(",")
            .filter(id => Boolean(id))

        batch(() => {
            ids.forEach(id => {
                dispatch(addToCompare(id))
            })
            dispatch(setIsOnComparePage(true))
        })

        return () => {
            dispatch(setIsOnComparePage(false))
        }
    }, [])

    useEffect(() => {
        setCompareUrl()
    }, [productForCompareIds, language])

    const downloadComparisonReport = async () => {
        setLoading(true)
        const ids = `${products[0].originalId}-${products[1].originalId}`
        const url = `/api/ProductInfo/GetProductComparisonReport?ids=${ids}&language=${language}`
        await axios.get(url, {
            responseType: "blob"
        })
            .then(response => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))

                const link = document.createElement("a")
                link.href = downloadUrl
                link.setAttribute("download", `product-comparison-${ids}.pdf`)
                document.body.appendChild(link)
                link.click()

                link.parentNode.removeChild(link)
            })
            .catch(error => console.error("Error fetching data: ", error))
            .finally(() => {
                setLoading(false)
                setSuccess(true)
            })
    }

    const buttonIcon = () => {
        if (loading) {
            return <CircularProgress style={{ width: "20px", height: "20px" }} />
        }

        if (success) {
            return <DoneIcon />
        }

        return <PictureAsPdfIcon />
    }

    return (
        <Box className={classes.content} style={{ padding: isMobile && 0 }}>
            <Typography variant="h3" className={classes.capitalized} gutterBottom>{Localization.compareProducts}</Typography>
            <Box className={classes.buttonWrapper}>
                {products.length === 2
                    && (
                        <Button
                            disabled={loading || success}
                            className={classes.pdfButton}
                            startIcon={buttonIcon()}
                            onClick={downloadComparisonReport}
                            variant="outlined"
                        >
                            {Localization.downloadComparisonPdf}
                        </Button>
                    )}
            </Box>
            {
                products.length > 0
                    ? (
                        <Box className={classes.result} style={{ padding: isMobile && 0 }}>
                            <ProductComparisonTable products={products} />
                        </Box>
                    )
                    : (
                        <Box>
                            <Typography>{Localization.noProductsToCompare}</Typography>
                        </Box>
                    )
            }
        </Box>
    )
}

export default CompareProducts
