import ProductCardTemplate from "./Components/ProductCardTemplate"
import { strings as Localization } from "../../../lib/Localization"
import { useGetReplacementProductsQuery } from "../../../redux/services/broditWebApi"
import React from "react"

function MissingFavoriteProductCard({ productId, grid }) {
    const { data: replacementProds } = useGetReplacementProductsQuery(productId)

    const getArticleString = array => {
        const str = array.map(id => `${id}, `).toString()
        return str.substr(0, str.length - 2)
    }

    const getReplacementText = () => (
        replacementProds?.deleted === true && replacementProds?.replacementProducts?.length > 0
            ? (`${Localization.itWasReplacedByTheFollowingArticles + getArticleString(replacementProds.replacementProducts)}.`)
            : ""
    )

    const productContent = {
        images: [`${process.env.REACT_APP_IMAGE_URL}w120/${productId}.jpg`],
        description: `${Localization.thisProductIsNoLongerAvailable} ${getReplacementText()}`,
        name: "",
        originalId: `${productId}`
    }

    return (
        <ProductCardTemplate
            product={productContent}
            handleShowMoreCompsClick={false}
            listView
            grid={grid}
            onSelect={() => { }}
            restorationRef={null}
            compare={false}
            showShare={false}
            showCompare={false}
            showFavorite
            showSpeedOrder={false}
            fillEmptySpace
            displayPrice={false}
            linkToProduct="#"
        />
    )
}

export default MissingFavoriteProductCard
