/* eslint-disable import/no-cycle */
import { strings as Localization } from "../../../../lib/Localization"
import { useFilteredProducts } from "../../../../redux/hook/productHooks"
import { useGetRelatedProductsQuery } from "../../../../redux/services/broditWebApi"
import Products from "../../Products"
import useStyles from "../styles"
import React, { useMemo } from "react"
import {
    Box, Typography
} from "@mui/material"

function RelatedProducts({
    id, modTek = {}, modTekInformation, modTekId
}) {
    const { data, isFetching } = useGetRelatedProductsQuery(id)
    const classes = useStyles()
    const filter = useMemo(() => ({ productIds: data }), [data])
    const products = useFilteredProducts(filter)
    const hasProducts = products && products.length > 0
    const hasModTek = modTek && Object.keys(modTek).length > 0

    return (
        <>
            {
                (isFetching)
                && (
                    <Typography className={classes.grayText}>
                        {Localization.loading}
                        ...
                    </Typography>
                )
            }
            {
                ((!isFetching) && (hasProducts || hasModTek))
                && (
                    <Box className={classes.grayText} id="tabRelatedProducts">
                        <Products
                            products={products}
                            modTek={modTek}
                            modTekId={modTekId}
                            modTekInformation={modTekInformation}
                        />
                    </Box>
                )
            }
            {
                (!isFetching) && (!hasProducts && !hasModTek)
                && <Typography variant="h5" className={classes.grayText}>{Localization.noAccessories}</Typography>
            }
        </>
    )
}

export default RelatedProducts
