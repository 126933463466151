import { useLanguage } from "../../../lib/hooks/languageHooks"
import { useIsUserAuthorized } from "../../../redux/hook/userHooks"
import { loadCookie, saveCookie } from "../../../redux/cookieStorage"
import { mobileBreakpoint } from "../../../lib/helper/viewportHelper"
import { styled } from "@mui/styles"
import React, { useEffect, useState } from "react"
import InfoIcon from "@mui/icons-material/Info"
import CloseIcon from "@mui/icons-material/Close"
import { Link } from "react-router-dom"

const MessageBarBase = styled("div")(({ theme }) => (
    {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        padding: "16px 20px",
        backgroundColor: "#000000",
        color: "#FFFFFF",
        fontFamily: "Arial",
        gap: "8px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            padding: "16px 50px 16px 20px"
        }
    }
))

const CloseButton = styled("button")(() => (
    {
        position: "absolute",
        right: "20px",
        top: "16px",
        backgroundColor: "transparent",
        border: "unset",
        color: "#FFFFFF",
        cursor: "pointer"
    }
))

const ReadMoreLink = styled(Link)(() => (
    {
        color: "#FFFFFF",
        fontWeight: 700
    }
))

const Text = styled("div")(({ theme }) => (
    {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            flex: "0 1 100%",
            justifyContent: "center"
        }
    }
))

const MessageBar = () => {
    const [open, setOpen] = useState(false)
    const language = useLanguage()
    const isAuthorized = useIsUserAuthorized()
    const cookieClosed = loadCookie("messageClosed")
    const [message, setMessage] = useState(null)

    const fetchAnnouncementMessage = async () => {
        const response = await fetch(`/files/DynamicPages/AnnouncementMessage-${language}.json`)
        return response.json()
    }

    useEffect(() => {
        let isMounted = true

        const fetchMessage = async () => {
            const result = await fetchAnnouncementMessage()
            if (isMounted) setMessage(result)
        }

        fetchMessage()

        return () => {
            isMounted = false
        }
    }, [language])

    useEffect(() => {
        setOpen(!cookieClosed)
    }, [cookieClosed])

    const closeMessage = () => {
        saveCookie("messageClosed", 1)
        setOpen(false)
    }

    return isAuthorized && open && message && message.enabled ? (
        <MessageBarBase>
            <Text>
                <InfoIcon />
                {message.barText}
            </Text>
            <ReadMoreLink to={message.barLink.url}>{message.barLink.text}</ReadMoreLink>
            <CloseButton onClick={closeMessage} type="button"><CloseIcon /></CloseButton>
        </MessageBarBase>
    ) : null
}

export default MessageBar
