import CloseIcon from "./assets/close-icon.svg"
import useStyles from "./styles"
import ArrowForward from "./assets/arrow-forward.svg"
import { strings as Localization } from "../../../../lib/Localization"
import { useLanguage } from "../../../../lib/hooks/languageHooks"
import { useIsUserAuthorized } from "../../../../redux/hook/userHooks"
/* eslint-disable import/no-cycle */
import Menu2 from "../../../Menu2/Menu2"
import { setSearchQuery } from "../../../../redux/searchReducer"
import { signOut } from "../../../../redux/userSlice"
import {
    Button
} from "@mui/material"
import React from "react"
import { useDispatch } from "react-redux"
import { NavLink } from "react-router-dom"
import Drawer from "@mui/material/Drawer"

function MobileDrawer({ open, onClose }) {
    const classes = useStyles()
    const language = useLanguage()
    const isAuthorized = useIsUserAuthorized()
    const dispatch = useDispatch()

    const [slide, setSlide] = React.useState(0)

    const handleClose = () => {
        onClose()
        setTimeout(() => {
            setSlide(0)
        }, 500)
    }

    const handleForward = () => {
        setSlide(slide + 1)
    }

    const handleBack = () => {
        setSlide(slide - 1)
    }

    const onItemClick = id => {
        dispatch(setSearchQuery({
            type: "menu2Drawer",
            value: { collections: [id] },
            clearFilter: true
        }))
        handleClose()
    }

    const links = [
        {
            type: "button",
            path: "/",
            text: Localization.allProducts,
            onClick: handleForward
        },
        {
            type: "link",
            path: `/${language}/#news`,
            text: Localization.news
        },
        {
            type: "link",
            path: `/${language}/retailers`,
            text: Localization.findReseller
        },
        {
            type: "link",
            path: `/${language}/contact`,
            text: Localization.contactBrodit
        }
    ]

    const logOut = () => {
        handleClose()
        dispatch(signOut())
    }

    const renderLoginLogoutButton = () => {
        const element = isAuthorized
            ? <Button type="button" className={classes.loginAnchor} onClick={logOut}>{Localization.logOut}</Button>
            : <NavLink to={`/${language}/signin`} onClick={handleClose} className={classes.loginAnchor} end>{Localization.logIn}</NavLink>

        return (
            <div className={classes.drawerRow}>
                {element}
            </div>
        )
    }

    return (
        <Drawer
            open={open}
            onClose={handleClose}
            classes={{
                paper: classes.leftDrawerPaper
            }}
        >
            <div className={classes.topBar}>
                <button type="button" className={classes.closeButton} onClick={handleClose}>
                    {Localization.close}
                    <img alt="Close icon" loading="lazy" src={CloseIcon} />
                </button>
            </div>
            <div className={classes.slider} style={{ transform: `translateX(-${100 * slide}%)` }}>
                <div className={classes.slideItem}>
                    <div className={classes.slideContent}>
                        {links.map(link => (
                            link.type === "button"
                                ? (
                                    <button key={link.text} className={classes.forwardButton} type="button" onClick={link.onClick}>
                                        <span>{link.text}</span>
                                        <img loading="lazy" src={ArrowForward} alt="Arrow forward" />
                                    </button>
                                )
                                : (
                                    <NavLink
                                        key={link.text}
                                        className={classes.anchor}
                                        to={link.path}
                                        onClick={handleClose}
                                        end
                                    >
                                        {link.text}
                                    </NavLink>
                                )))}
                        {renderLoginLogoutButton()}
                    </div>
                </div>
                <div className={classes.slideItem}>
                    <div className={classes.slideContent}>
                        <Menu2
                            toggleDrawer={handleClose}
                            onItemClick={onItemClick}
                            onBackToParent={handleBack}
                        />
                    </div>
                </div>
            </div>
        </Drawer>
    )
}

export default MobileDrawer
