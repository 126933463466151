import { tabletBreakpoint } from "../../../lib/helper/viewportHelper"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import { strings as Localization } from "../../../lib/Localization"
import theme from "../../../theme"
import useStyles from "../styles"
import { Link } from "react-router-dom"
import React from "react"
import classnames from "classnames"
import SearchIcon from "@mui/icons-material/Search"
import {
    Box, IconButton, Link as SimpleLink, Tooltip, Typography, useMediaQuery
} from "@mui/material"

const TrackingButton = ({ orderNumber, shippingArray }) => {
    const classes = useStyles()
    const language = useLanguage()
    const isSmall = useMediaQuery(theme.breakpoints.down(tabletBreakpoint))

    return (
        <Box className={classes.flexItem}>
            <Tooltip title={Localization.trackShipment}>
                <Box className={classnames(classes.flexRow, classes.textCenter)}>
                    {isSmall && (
                        <Typography className={classes.gray}>
                            {`${Localization.trackShipment.toUpperCase()}:`}
                        </Typography>
                    )}
                    {(shippingArray.length === 0)
                        && (
                            <IconButton disabled>
                                <SearchIcon />
                            </IconButton>
                        )}
                    {(shippingArray.length === 1)
                        && (
                            <IconButton component={SimpleLink} href={`https://tracking.postnord.com/se/?id=${shippingArray[0]}`} target="_blank">
                                <SearchIcon />
                            </IconButton>
                        )}
                    {(shippingArray.length >= 2)
                        && (
                            <IconButton component={Link} to={`/${language}/order/${orderNumber}`}>
                                <SearchIcon />
                            </IconButton>
                        )}
                </Box>
            </Tooltip>
        </Box>
    )
}

export default TrackingButton
