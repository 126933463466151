/* eslint-disable consistent-return */
import * as actions from "../api"
import axios from "axios"

const api = ({ dispatch }) => next => async action => {
    if (action.type !== actions.apiCallBegan.type) return next(action)

    const {
        url, method, data, onStart, onSuccess, onError
    } = action.payload

    if (onStart) {
        dispatch({
            type: onStart
        })
    }

    next(action)

    try {
        const response = await axios.request({
            // baseURL: "http:://localhost:62414",
            url,
            method,
            data
        })
        // General
        dispatch(actions.apiCallSucess(response.data))
        // Specific
        if (onSuccess) {
            dispatch({
                type: onSuccess,
                payload: response.data
            })
        }
    } catch (error) {
        // General
        dispatch(actions.apiCallFailed(error.message))
        // Specific
        if (onError) {
            dispatch({
                type: onError,
                payload: error.message
            })
        }
    }
}

export default api
