import FunnelIcon from "../../../lib/icons/Funnel/FunnelIcon"
import ProductNavigation from "../ProductNavigation/ProductNavigation"
import PageHandler from "../../PageHandler/PageHandler"
import { strings as Localization } from "../../../lib/Localization"
import { useSearchQuery } from "../../../redux/hook/searchHooks"
import {
    Box, IconButton, Tooltip, Typography
} from "@mui/material"
import React, { useRef } from "react"

function FilterProductToolbar({ toggleFilter, productTotalCount, ...props }) {
    const rowRef = useRef()
    const allSearchQueriesCombined = useSearchQuery()

    const searchQueriesAsText = () => {
        const {
            brands,
            models,
            years,
            texts
        } = allSearchQueriesCombined

        const allSearchTerms = [
            texts,
            brands,
            models,
            years
        ]

        const allSearchTexts = allSearchTerms.map(
            searchTerms => (searchTerms ? searchTerms.join(" ") : "")
        )

        const result = allSearchTexts.filter(text => text !== "").join(" ")

        return result
    }

    return (
        <Box {...props}>
            <Box
                container="true"
                ref={rowRef}
                sx={{
                    width: "100%",
                    position: "fixed",
                    zIndex: 1,
                    backgroundColor: "#FFFFFF",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "8px"
                }}
            >
                <Box>
                    <Box style={{
                        display: "flex", flexWrap: "wrap", paddingLeft: 15, alignItems: "center", gap: 8
                    }}
                    >
                        <PageHandler
                            type="dropdownPagination"
                            itemCount={productTotalCount ?? 0}
                        />
                        <Typography>
                            {
                                searchQueriesAsText() !== ""
                                && `${Localization.youSearchedFor}: ${searchQueriesAsText()}.`
                            }
                        </Typography>
                    </Box>
                </Box>
                <Box style={{ marginRight: 15 }}>
                    <ProductNavigation id="product-navigation">
                        <Tooltip title={Localization.showAmount} style={{ display: "flex", alignItems: "center" }}>
                            <span>
                                <PageHandler type="list" />
                            </span>
                        </Tooltip>
                        <Tooltip title={Localization.showOrHideFilters}>
                            <IconButton onClick={toggleFilter}><FunnelIcon /></IconButton>
                        </Tooltip>
                    </ProductNavigation>
                </Box>
            </Box>
            <Box height={rowRef?.current?.clientHeight} />
        </Box>
    )
}

export default FilterProductToolbar
