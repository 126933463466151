import ContactHero from "./assets/contacthero.jpg"
import { mobileBreakpoint, tabletBreakpoint } from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontFamily: theme.fontFamily,
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: "0 40px 40px 40px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            padding: "unset",
            paddingBottom: "20px"
        }
    },
    contactCardGrid: {
        display: "grid",
        gridTemplateColumns: "350px 350px 350px",
        gap: "30px",
        justifyContent: "center",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            gridTemplateColumns: "350px 350px"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            gridTemplateColumns: "350px"
        }
    },
    contactCard: {
        borderRadius: "6px",
        overflow: "hidden",
        boxShadow: "0px 0px 20px 0px #2D2D2D1A"
    },
    contactImage: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "350px",
        width: "350px",
        backgroundColor: "#ededed"
    },
    contactInfo: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "15px 20px 20px 20px"
    },
    contactTopInfo: {
        display: "flex",
        flexDirection: "column",
        gap: "5px"
    },
    contactName: {
        fontFamily: "Roboto",
        fontSize: "18px",
        fontWeight: 400,
        color: "#2D2D2D"
    },
    contactTitle: {
        fontFamily: "Roboto",
        fontSize: "17px",
        fontWeight: 400,
        letterSpacing: "0.5px",
        color: "#2D2D2D"
    },
    contactLink: {
        fontFamily: "Arial",
        fontSize: "14px",
        fontWeight: 700,
        letterSpacing: "0.5px",
        color: "#2D2D2D",
        textDecoration: "none"
    },
    title: {
        fontFamily: "Rubik",
        fontSize: "30px",
        fontWeight: 500,
        marginBottom: "10px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            textAlign: "center",
            fontSize: "20px"
        }
    },
    locationTitle: {
        flex: "0 1 100%",
        fontFamily: "Rubik",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "32px",
        margin: 0,
        [theme.breakpoints.down(mobileBreakpoint)]: {
            fontSize: "16px"
        }
    },
    sectionText: {
        fontFamily: "Arial",
        fontSize: "17px",
        fontWeight: 400,
        letterSpacing: "0.5px",
        marginBottom: "10px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            fontSize: "14px",
            textAlign: "center",
            marginBottom: "20px"
        }
    },
    contactHero: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "40px",
        width: "100%",
        maxWidth: "1920px",
        background: `url(${ContactHero})`,
        height: "783px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            background: "unset",
            height: "auto"
        }
    },
    contactForm: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        flex: "0 0 500px",
        borderRadius: "6px",
        backgroundColor: "#F8F8F8",
        padding: "40px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            flex: "0 1 100%",
            padding: "30px"
        }
    },
    contactAddress: {
        display: "flex",
        flexWrap: "wrap",
        flex: "0 0 452px",
        backgroundColor: "#F8F8F8",
        padding: "40px",
        borderRadius: "6px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            backgroundColor: "#FFFFFF"
        }
    },
    formGrid: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "10px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            gridTemplateColumns: "1fr"
        }
    },
    textfieldWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        color: "#2D2D2D"
    },
    addressGrid: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "10px",
        color: "#2D2D2D",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            gridTemplateColumns: "1fr 1fr"
        }
    },
    half: {
        display: "flex",
        flexWrap: "wrap"
    },
    addressRow: {
        flex: "0 0 100%",
        fontFamily: "Arial",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "28px",
        letterSpacing: "0.5px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            fontSize: "14px"
        }
    },
    helpText: {
        fontFamily: "Arial",
        fontSize: "17px",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "0.5px",
        marginTop: "5px"
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap"
    },
    button: {
        padding: "10px 20px",
        fontFamily: "Arial",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "16px",
        letterSpacing: "0.5px",
        backgroundColor: "#F09057",
        border: 0,
        borderRadius: "4px"
    },
    formWrapper: {
        flex: "0 0 100%",
        display: "flex",
        justifyContent: "center",
        gap: "40px",
        flexWrap: "wrap",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            flexWrap: "wrap-reverse",
            gap: 0
        }
    },
    addressWrapper: {
        flex: "0 1 452px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            flex: "0 1 100%"
        }
    },
    addressLink: {
        fontFamily: "Arial",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "30px",
        letterSpacing: "0.5px",
        textDecoration: "none",
        color: "#2D2D2D",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            flex: "0 0 100%",
            fontSize: "14px"
        }
    },
    mobileHero: {
        width: "100%",
        display: "none",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            display: "block"
        }
    }
}))
