import { mobileMenuBreakpoint } from "../../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    publishedText: {
        marginTop: 50,
        marginBottom: 15
    },
    itemThumbnail: {
        width: "100%",
        minHeight: 500
    },
    container: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3)
    },
    pageTitle: {
        display: "flex",
        gap: 25,
        marginTop: 50,
        flexWrap: "wrap"
    },
    skeletonText: {
        marginTop: 50,
        marginBottom: 15,
        width: "33%"
    },
    skeletonRect: {
        height: 500
    },
    newsGrid: {
        display: "grid",
        gridTemplateColumns: "50% 50%",
        gap: "10px",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            gridTemplateColumns: "100%"
        }
    },
    newsCard: {
        display: "flex",
        flexDirection: "column"
    },
    link: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 100%",
        color: "#2D2D2D",
        textDecoration: "none"
    },
    textWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        background: "#FFFFFF",
        padding: "10px 16px 16px 16px",
        gap: "8px",
        borderRadius: "0 0 6px 6px"
    },
    titleWrapper: {
        display: "flex"
    },
    title: {
        fontFamily: "Rubik",
        fontWeight: 400,
        fontSize: "20px",
        borderBottom: "1px solid transparent",
        "&:hover": {
            borderBottom: "1px solid #2D2D2D"
        }
    },
    subtitle: {
        fontFamily: "Rubik",
        fontWeight: 400,
        fontSize: "16px"
    }
}))
