import { useLanguage } from "../../lib/hooks/languageHooks"
import React from "react"
import { Helmet } from "react-helmet"

function ProclipAndHoldersMetaDescription() {
    const language = useLanguage()

    const title = language === "sv"
        ? "Modellspecifika ProClipfästen och enhetshållare - Brodit"
        : "Model specific ProClip Mounts and Device Holders - Brodit"
    const description = language === "sv"
        ? "Vår tvådelade monteringslösning ger dig en skräddarsydd enhetsmontering som passar perfekt för dina behov."
        : "Our two-part mounting solution provides you with a tailored device installation perfectly suited to your needs."

    const keywords = language === "sv"
        ? "ProClipfästen, enhetshållare, Brodit"
        : "ProClip Mounts, Device Holders , Brodit"

    return (
        <Helmet>
            <title>{title}</title>
            <meta
                name="description"
                content={description}
            />
            <meta
                name="keywords"
                content={keywords}
            />
        </Helmet>
    )
}

export default ProclipAndHoldersMetaDescription
