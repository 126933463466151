import { formatPrice } from "../../../../lib/helper/numberHelper"
import { strings as Localization } from "../../../../lib/Localization"
import { Box, Typography } from "@mui/material"
import React from "react"

export function GridPrice({ displayPrice, price }) {
    const upperString = Localization.exVat.toUpperCase()
    return (
        <Box id="price">
            {displayPrice
                && (
                    <Typography>
                        {formatPrice(price.price)}
                        {" "}
                        {price.currency}
                        {" "}
                        <span style={{ fontSize: "x-small" }}>{` ${upperString}`}</span>
                    </Typography>
                )}
        </Box>
    )
}

export function ListPrice({ displayPrice, price }) {
    const upperString = Localization.exVat.toUpperCase()
    return (
        <Box id="price">
            {displayPrice
                && (
                    <Typography>
                        {formatPrice(price.price)}
                        {" "}
                        {price.currency}
                        {" "}
                        <span style={{ fontSize: "x-small" }}>{` ${upperString}`}</span>
                    </Typography>
                )}
        </Box>
    )
}

export function ModTekPrice({ displayPrice, price }) {
    const upperString = Localization.exVat.toUpperCase()
    return (
        <Box id="price">
            {displayPrice
                && (
                    <Typography>
                        {formatPrice(price.price)}
                        {" "}
                        {price.currency}
                        {" "}
                        <span style={{ fontSize: "x-small" }}>{` ${upperString}`}</span>
                    </Typography>
                )}
        </Box>
    )
}
