import useStyles from "./styles"
import NewsItem from "../NewsItem"
import { strings as Localization } from "../../../lib/Localization"
import usePage from "../../../redux/hook/pageHook"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import PageHandler from "../../PageHandler/PageHandler"
import { useGetNewsQuery } from "../../../redux/services/broditWebApi"
import { useSteering } from "../../../redux/hook/userHooks"
import LeftRightSteeringFilter from "../../AutocompleteSearch/VehicleSearchbar/LeftRightSteeringFilter"
import {
    Box, Typography, Container,
    Skeleton
} from "@mui/material"

import React from "react"

function VehicleNewsListPage() {
    const { limit } = usePage()
    const language = useLanguage()
    const steering = useSteering()
    const classes = useStyles()
    let steeringGroup
    switch (steering) {
        case "1":
            steeringGroup = "LHD"
            break
        case "2":
            steeringGroup = "RHD"
            break
        default:
            steeringGroup = "LHD"
    }

    const { data: vehicleNews, isFetching } = useGetNewsQuery({
        language,
        limit,
        groups: [steeringGroup]
    })

    return (
        <main>
            <Container className={classes.container}>
                <Box className={classes.pageTitle}>
                    <Typography variant="h5">
                        {Localization.newProductsForVehicles.toUpperCase()}
                    </Typography>
                    {
                        language === "en"
                        && <LeftRightSteeringFilter />
                    }
                </Box>
                <div className={classes.newsGrid} id="productNewsWrapper">
                    {
                        !isFetching
                        && vehicleNews.map(news => (
                            <NewsItem
                                key={news.id}
                                news={news}
                                url={`/${language}/product/${news.products?.[0]}`}
                                alt={`${Localization.product} ${news.products?.[0]}`}
                            />
                        ))
                    }
                    {
                        isFetching
                        && [...Array(limit).keys()].map(i => (
                            <Box key={i}>
                                <Skeleton
                                    variant="text"
                                    className={classes.skeletonText}
                                />
                                <Skeleton variant="rect" className={classes.skeletonRect} />
                            </Box>
                        ))
                    }
                </div>
                <PageHandler type="button" />
            </Container>
        </main>
    )
}

export default VehicleNewsListPage
