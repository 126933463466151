import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    root: {
        display: "inherit",
        flexGrow: 1,
        justifyContent: "center"
    },
    swipeableTextMobileStepper: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1
    },
    swiperBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "inherit",
        alignItems: "center"
    },
    bannerBox: {
        display: "flex",
        flexDirection: "row",
        height: "100%",
        alignItems: "flex-start"
    },
    imageBox: {
        minHeight: 110,
        margin: 2
    },
    productImage: {
        width: "100%",
        height: "100%",
        objectFit: "contain"
    },
    buttonRoot: {
        color: "#2d2d2d",
        minWidth: "inherit",
        "&:hover": {
            backgroundColor: "inherit"
        }
    },
    iconRoot: {
        borderRadius: "50%",
        padding: theme.spacing(1),
        "&:hover": {
            backgroundColor: "#8f8f8f",
            color: "#fff"
        }
    },
    navButton: {
        alignItems: "flex-end",
        maxWidth: 48
    }
}))
