import { mobileBreakpoint, tabletBreakpoint } from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    userInfoWrapper: {
        flex: "0 1 100%"
    },
    titleRow: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        fontSize: "18px",
        fontWeight: 500,
        marginBottom: "8px"
    },
    customerGrid: {
        display: "grid",
        gridTemplateColumns: "auto"
    },
    addressGrid: {
        display: "grid",
        gridTemplateColumns: "400px 400px",
        marginBottom: "100px",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            gridTemplateColumns: "300px 300px"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            gap: "20px",
            gridTemplateColumns: "auto"
        }
    }
}))
