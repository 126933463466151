import useStyles from "./styles"
import { Box } from "@mui/material"
import React, { useEffect } from "react"

const TITLE_PREFIX = "Brodit | "

const Page = ({ title, ...props }) => {
    const classes = useStyles()
    useEffect(() => {
        document.title = TITLE_PREFIX + title
        window.scrollTo(0, 0)
    }, [title])

    return (
        <Box className={classes.page}>
            {props.children}
        </Box>
    )
}

export default Page
