import { materialAdapter } from "./adapter/materialAdapter";
import { requestMaterial } from "./thunk/materialThunk";
import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "material",
    initialState: materialAdapter.getInitialState(),
    reducers: {},
    extraReducers: builder => {
        builder.addCase(requestMaterial.fulfilled, (state, action) => {
            materialAdapter.addOne(state, {
                ...action.payload,
                id: `${action.meta.arg.lang}-${action.payload.id}`,
                originalId: action.payload.id
            });
        });
    }
});

export default slice.reducer;
