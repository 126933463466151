import { productViewBreakpoint } from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    result: {
        position: "relative",
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
        minHeight: 450,
        [theme.breakpoints.down(productViewBreakpoint)]: {
            minHeight: 0
        }
    },
    notGrid: {
        display: "flex",
        flexDirection: "column",
        gap: 16
    },
    notGridLoading: {
        width: "100%",
        height: 142
    },
    circleAnimation: {
        position: "absolute",
        padding: 10,
        zIndex: 99,
        left: "50%",
        marginTop: "calc(50vh - 145px)", // 145px navbar height
        transform: "translateX(-50%)"
    },
    isGrid: {
        display: "flex",
        flexDirection: "column",
        gap: 16
    }
}))
