/* eslint-disable no-nested-ternary */
import ProductCardTemplate from "./Components/ProductCardTemplate"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import usePrice from "../../../redux/hook/priceHooks"
import { useProxyPath } from "../../../redux/hook/settingsHooks"
import { useIsUserAuthorized } from "../../../redux/hook/userHooks"
import { useGetModTekProductsQuery } from "../../../redux/services/broditWebApi"
import React from "react"

const ProductCard = (
    {
        product,
        handleShowMoreCompsClick,
        listView = false,
        grid,
        onSelect,
        restorationRef,
        backGroundColor,
        compare,
        showSearchDealerLink,
        productInfo = false,
        modTek = {},
        modTekInformation = {},
        altNameList = []
    }
) => {
    const isAuthorized = useIsUserAuthorized()
    const price = usePrice(product?.originalId)
    const displayPrice = isAuthorized && price !== undefined
    const language = useLanguage()
    const proxyPath = useProxyPath()
    const { data: modtekProduct } = useGetModTekProductsQuery(modTek?.originalId)
    const information = modtekProduct
        ? modTekInformation
        : product?.information
    const info = (information !== undefined && information !== null)
        ? Object.keys(information).length > 0
            ? modTekInformation?.[language]
            : information?.[language]
        : ""

    return (
        <ProductCardTemplate
            product={product}
            handleShowMoreCompsClick={handleShowMoreCompsClick}
            listView={listView}
            grid={grid}
            onSelect={onSelect}
            restorationRef={restorationRef}
            backGroundColor={backGroundColor}
            compare={compare}
            isAuthorized={isAuthorized}
            showSpeedOrder={isAuthorized}
            showSearchDealerLink={showSearchDealerLink}
            price={price}
            displayPrice={displayPrice}
            linkToProduct={`${proxyPath}/${language}/product/${product?.originalId}`}
            productInfo={productInfo}
            modTek={modTek}
            modTekInformation={info}
            altNameList={altNameList}
        />
    )
}

export default ProductCard
