import { mobileBreakpoint, tabletBreakpoint } from "../../../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    cardRoot: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexGrow: 1,
        height: "auto",
        "& .MuiOutlinedInput-inputMarginDense": {
            paddingTop: 5.5,
            paddingBottom: 5.5
        },
        boxShadow: "none",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            width: "auto"
        },
        [theme.breakpoints.between(mobileBreakpoint, tabletBreakpoint)]: {
            width: 400
        }
    },
    cardRootMni: {
        "& .MuiOutlinedInput-inputMarginDense": {
            paddingTop: 5.5,
            paddingBottom: 5.5
        },
        boxShadow: "none",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            width: "auto"
        },
        [theme.breakpoints.between(mobileBreakpoint, tabletBreakpoint)]: {
            width: 400
        }
    },
    media: {
        height: 0,
        paddingTop: "50.00%",
        backgroundSize: "contain"
    },
    cardContent: {
        display: "flex",
        flex: "1 0 auto",
        justifyContent: "space-between",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        flexDirection: "column",
        alignItems: "flex-start"
    },
    cardContentMini: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        padding: 0
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
    },
    flexRow: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1
    },
    flexRowMini: {
        display: "flex",
        flexDirection: "row"
    },
    bannerArea: {
        display: "flex",
        height: "auto",
        alignItems: "flex-start",
        whiteSpace: "nowrap"
    },
    rowContent: {
        display: "flex",
        flexDirection: "row"
    },
    avatarLarge: {
        width: theme.spacing(10),
        height: theme.spacing(10)
    },
    heading: {
        fontWeight: "bold",
        fontSize: 14
    },
    paperRoot: {
        marginBottom: theme.spacing(2),
        boxShadow: "none",
        padding: theme.spacing(2),
        width: "100%"
    },
    flexEnd: {
        display: "flex",
        justifyContent: "flex-end"
    },
    centerColumn: {
        alignItems: "center"
    },
    verticalAlign: {
        alignItems: "flex-start"
    },
    noDisplayLink: {
        textDecoration: "none",
        color: "#000"
    },
    addButton: {
        "& span > .MuiButton-startIcon": {
            marginRight: 0,
            marginLeft: -4
        }
    },
    swipableImageListView: {
        maxWidth: 250,
        display: "flex",
        flexBasis: 250
    },
    swipableImageModTekView: {
        maxWidth: 250,
        display: "flex"
    },
    swipableImageGridView: {
        width: "auto",
        display: "flex",
        flexBasis: 300,
        minHeight: 175,
        maxWidth: "none",
        [theme.breakpoints.only(mobileBreakpoint)]: {
            maxWidth: 400
        }
    },
    spacingTop: {
        paddingTop: theme.spacing(1)
    },
    productCard: {
        display: "flex",
        width: "100%",
        justifyContent: "center"
    },
    flexBox: {
        display: "flex"
    },
    cardFooter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
    },
    gridButtons: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    paddingBottom8: {
        paddingBottom: 8
    },
    paperBox: {
        display: "flex",
        flexDirection: "row",
        gap: 32
    },
    listImage: {
        width: "25%"
    },
    listProductNumber: {
        width: "10%"
    },
    productText: {
        width: "35%"
    },
    buttonBox: {
        width: "30%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }
}))
