import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        minHeight: "60vh"
    }
}))
