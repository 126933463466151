import useStyles from "./styles"
import MyPagesImage from "./assets/mypages.jpg"
import MessageBox from "./MessageBox/MessageBox"
import withdrawCookiesConsent from "../../lib/helper/gdprHelper"
import { strings as Localization } from "../../lib/Localization"
import { useUserData } from "../../redux/hook/userHooks"
import UserInfo from "../UserInfo/UserInfo"
import { useDispatch } from "react-redux"
import React from "react"
import {
    Link
} from "@mui/material"

const User = () => {
    const classes = useStyles()
    const userData = useUserData()
    const dispatch = useDispatch()

    return (
        <div className={classes.userPage} id="userPage">
            <MessageBox />
            <div className={classes.contentWrapper}>
                <div className={classes.left}>
                    <h3 className={classes.pageTitle}>{`${Localization.hello} ${userData.name ?? ""}`}</h3>
                    <UserInfo />
                    <div className={classes.withdrawConsent}>
                        {Localization.ifYouWantToWithdrawConsent}
                        <Link onClick={() => dispatch(withdrawCookiesConsent())}>
                            {Localization.here}
                        </Link>
                        .
                        {Localization.youMustThenAcceptTheTermsAgain}
                    </div>
                    <div>
                        {Localization.contactBroditVia}
                        <a href="mailto:info@brodit.se">info@brodit.se</a>
                        {Localization.ifYouWantToChangeAnyInformation}
                    </div>
                </div>
                <div className={classes.right}>
                    <img className={classes.image} loading="lazy" src={MyPagesImage} alt="Corvette with ProClip mounted" />
                </div>
            </div>
        </div>
    )
}

export default User
