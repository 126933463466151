import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    cartContainerRow: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: 0,
        paddingBottom: 0
    },
    content: {
        width: "100%",
        backgroundColor: "#f3f3f3" // theme.palette.background.default,
    },
    cartItemsContainer: {
        padding: 16
    },
    flexLeft: {
        justifyContent: "flex-end",
        display: "flex"
    },
    headerArea: {
        width: "100%",
        height: 100
    },
    capitalize: {
        textTransform: "uppercase"
    },
    root: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        justifyContent: "center",
        margin: theme.spacing(4)
    },
    flexRow: {
        display: "flex",
        flexDirection: "row"
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column"
    },
    marginBottomTheme2: {
        marginBottom: theme.spacing(2)
    },
    flexGrow1: {
        flexGrow: 1
    },
    flexBetween: {
        justifyContent: "space-between"
    },
    flexCenter: {
        justifyContent: "center"
    },
    half: {
        width: "50%"
    },
    orderDetails: {
        width: 250
    },
    orderSummary: {
        width: 150
    },
    summaryButtons: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        width: "50%",
        justifyContent: "center"
    },
    mobileSummaryButtons: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        justifyContent: "center"
    },
    itemsCenter: {
        alignItems: "center"
    },
    summaryMain: {
        display: "flex",
        flexDirection: "row",
        width: "60%",
        justifyContent: "space-between",
        gap: "1rem"
    },
    topButtonWrapper: {
        display: "flex",
        justifyContent: "flex-end"
    }
}))
