import { newsDateComparer } from "../../lib/helper/newsHelper";
import { createEntityAdapter } from "@reduxjs/toolkit";

export const newsArticleAdapter = createEntityAdapter({
    selectId: newsArticle => newsArticle.id,
    sortComparer: newsDateComparer
});

export const newsArticleSelectors = newsArticleAdapter.getSelectors(state => state.newsArticle)

export const { selectById, selectAll } = newsArticleSelectors;
