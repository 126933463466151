import useStyles from "./styles"
import ProductCard from "./ProductCard/ProductCard"
import MissingFavoriteProductCard from "./ProductCard/MissingFavoriteProductCard"
import ReplacementProducts from "./ReplacementProducts/ReplacementProducts"
import ModTekSelector from "./ProductCard/Components/ModTekSelector"
import { mobileBreakpoint } from "../../lib/helper/viewportHelper"
import { strings as Localization } from "../../lib/Localization"
import React, {
    useRef, useState, useEffect, createRef, useMemo
} from "react"
import {
    CircularProgress, Box, useTheme, useMediaQuery, Typography
} from "@mui/material"
import { useSelector } from "react-redux"
import { useNavigationType } from "react-router-dom"

const Products = ({
    products,
    useGridView,
    missingProducts = [],
    isLoading,
    replacementProducts,
    searchedId,
    isDeleted,
    modTek = {},
    modTekInformation = {},
    modTekId,
    altNameList = []
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint))
    const isOnlyOneCardWidth = useMediaQuery(theme.breakpoints.down(1000))

    const isGridState = useSelector(state => state.settings.productGridView)
    const isGrid = useGridView ?? (isMobile || isGridState)
    const isGridTemplateColumns = isMobile ? "" : "repeat(auto-fit, 400px)"
    const classes = useStyles()
    const navigationType = useNavigationType()
    const loading = useMemo(
        () => (isLoading),
        [isLoading]
    )
    const [productMarkerId] = useState(() => {
        if (navigationType !== "POP") { return null }

        const persistendId = sessionStorage.getItem(
            "scroll-position-product-id-marker"
        )

        sessionStorage.removeItem("scroll-position-product-id-marker")

        return persistendId || null
    })

    useEffect(() => {
        if (!productMarkerId) { window.scrollTo(0, 0) }
    }, [productMarkerId])

    const persistScrollPosition = id => {
        sessionStorage.setItem("scroll-position-product-id-marker", id)
    }

    const restorationRef = useRef(Array(products.length).fill(createRef()))

    return (
        <Box className={classes.result}>

            {loading && (
                <Box className={classes.circleAnimation}>
                    <CircularProgress />
                </Box>
            )}

            {(isGrid && Object.keys(modTek).length === 0) && (
                <Box
                    id="productWrapper"
                    sx={{
                        display: "grid",
                        justifyContent: "center",
                        gridTemplateColumns: isGridTemplateColumns,
                        gap: 8
                    }}
                >
                    {
                        missingProducts.map(id => (
                            <Box
                                key={id}
                                sx={{
                                    display: "flex",
                                    justifyContent: {
                                        xs: "center", sm: "center", md: "center", lg: "flex-start"
                                    }
                                }}
                            >
                                <MissingFavoriteProductCard productId={id} grid={isGrid} />
                            </Box>
                        ))
                    }
                    {
                        products.map((product, i) => (
                            <Box
                                id="productBox"
                                key={`${product.id}${i}`}
                                sx={{
                                    display: "flex",
                                    justifyContent: isOnlyOneCardWidth ? "center" : "flex-start"
                                }}
                            >
                                <ProductCard
                                    product={product}
                                    handleShowMoreCompsClick={null}
                                    listView
                                    grid={isGrid}
                                    onSelect={persistScrollPosition}
                                    restorationRef={restorationRef[i]}
                                    altNameList={altNameList}
                                />
                            </Box>
                        ))
                    }
                </Box>
            )}
            {(!isGrid && Object.keys(modTek).length === 0) && (
                <Box id="productWrapper" className={classes.notGrid}>
                    {
                        missingProducts.map(id => (
                            <MissingFavoriteProductCard key={id} productId={id} grid={isGrid} />
                        ))
                    }
                    {
                        products.map((product, i) => (
                            <ProductCard
                                key={`${product.id}${i}`}
                                product={product}
                                handleShowMoreCompsClick={null}
                                listView
                                grid={isGrid}
                                onSelect={persistScrollPosition}
                                restorationRef={restorationRef[i]}
                                altNameList={altNameList}
                            />
                        ))
                    }
                </Box>
            )}

            {Object.keys(modTek).length > 0 && (
                <Box className={classes.mini}>
                    <ModTekSelector
                        restorationRef={restorationRef}
                        missingProducts={missingProducts}
                        modTek={modTek}
                        modTekId={modTekId}
                        modTekInformation={modTekInformation}
                        persistScrollPosition={persistScrollPosition}
                        altNameList={altNameList}
                    />
                </Box>
            )}

            {(!loading && replacementProducts?.length > 0 && isDeleted && searchedId)
                && (
                    <ReplacementProducts
                        replacementProducts={replacementProducts}
                        searchedId={searchedId}
                    />
                )}
            {(!loading && products?.length === 0 && replacementProducts?.length === 0 && isDeleted && searchedId)
                && (
                    <Typography>
                        {`${Localization.theProduct} ${searchedId} ${Localization.isDiscontinuedAndNoLongerAvailable}.`}
                    </Typography>
                )}
            {(!loading && products?.length === 0 && replacementProducts?.length === 0 && !isDeleted && searchedId)
                && (
                    <Typography>
                        {`${Localization.theProduct} ${searchedId}, ${Localization.weCouldNotFindThisProductEtc}`}
                    </Typography>
                )}
        </Box>
    )
}
export default Products
