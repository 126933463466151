import {
    mobileBreakpoint, tabletBreakpoint, desktopBreakpoint
} from "../../../../lib/helper/viewportHelper"
import HomePageContact from "../../../../assets/homePageContact.jpg"
import HomePageContactMobile from "../../../../assets/homePageContact_mob.jpg"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    contact: {
        display: "flex",
        flex: "0 0 100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${HomePageContact})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "440px",
        [theme.breakpoints.up(desktopBreakpoint)]: {
            minHeight: "600px"
        },
        [theme.breakpoints.down(tabletBreakpoint)]: {
            minHeight: "179px",
            paddingTop: "50px",
            paddingBottom: "50px"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            backgroundImage: `url(${HomePageContactMobile})`
        }
    },
    contentWrapper: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            paddingLeft: "45px",
            paddingRight: "45px"
        }
    },
    title: {
        flex: "0 0 100%",
        fontFamily: "Roboto",
        fontSize: "30px",
        fontWeight: 700,
        textAlign: "center",
        color: "#FFFFFF",
        marginTop: 0,
        marginBottom: "5px",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            fontSize: "25px"
        }
    },
    text: {
        flex: "0 0 100%",
        fontFamily: "Arial",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "0.5px",
        textAlign: "center",
        color: "#FFFFFF",
        marginBottom: "20px",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            fontSize: "14px"
        }
    },
    anchorButtonStyle: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.5px",
        padding: "10px 20px 10px 20px",
        borderRadius: "4px",
        textAlign: "center",
        textDecoration: "none",
        color: "#2D2D2D",
        backgroundColor: "#F09057",
        marginTop: "10px",
        maxWidth: "60%",
        transition: "all 0.1s ease-in",
        "&:hover": {
            backgroundColor: "#ef7830"
        }
    }
}))
