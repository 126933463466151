import useStyles from "./styles"
import { setSnackbarMessage } from "../../redux/userMessageSlice"
import { strings as Localization } from "../../lib/Localization"
import { useLanguage } from "../../lib/hooks/languageHooks"
import {
    Paper, TextField, Button, Container, Typography, CircularProgress, Box, ListItem, IconButton,
    Alert
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import axios from "axios"

import DoneIcon from "@mui/icons-material/Done"
import InvalidIcon from "@mui/icons-material/NotInterested"
import List from "@mui/material/List"
import ListItemIcon from "@mui/material/ListItemIcon"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import Tooltip from "@mui/material/Tooltip"

const ResetPassword = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { guid } = useParams()
    const [working, setWorking] = useState(false)
    const navigate = useNavigate()
    const lang = useLanguage()
    const [tokenValid, setTokenValid] = useState(false)
    const [checkingValidity, setCheckingValidity] = useState(false)

    const [firstPassword, setFirstPassword] = useState("")
    const [secondPassword, setSecondPassword] = useState("")

    const [firstPasswordHidden, setFirstPasswordHidden] = useState(true)
    const [secondPasswordHidden, setSecondPasswordHidden] = useState(true)

    const [passwordsMatch, setPasswordsMatch] = useState(firstPassword === secondPassword)
    const [passwordLengthValid, setPasswordLengthValid] = useState(false)
    const [passwordContainsUppercase, setPasswordContainsUppercase] = useState(false)
    const [passwordContainsLowercase, setPasswordContainsLowercase] = useState(false)
    const [passwordContainsDigit, setPasswordContainsDigit] = useState(false)
    const [passwordContainsSpecialCharacter, setPasswordContainsSpecialCharacter] = useState(false)

    const defaultValues = {
        firstPassword: null,
        secondPassword: null,
        guid: null
    }

    const {
        register, handleSubmit, setValue, formState: { errors }
    } = useForm({ defaultValues: defaultValues })

    const passwordRequirements = [
        { text: Localization.passwordsMustMatch, valid: passwordsMatch },
        { text: Localization.containMinimumSixCharacters, valid: passwordLengthValid },
        { text: Localization.containUpperCaseCharacters, valid: passwordContainsUppercase },
        { text: Localization.containLowerCaseCharacters, valid: passwordContainsLowercase },
        { text: Localization.containANumber, valid: passwordContainsDigit },
        {
            text: Localization.containAFigure,
            valid: passwordContainsSpecialCharacter,
            explanation: `${Localization.examplesSpecialCharacters} !@#$%^&*?`
        }
    ]

    const formIsValid = () => (passwordsMatch
        && passwordLengthValid
        && passwordContainsUppercase
        && passwordContainsLowercase
        && passwordContainsDigit
        && passwordContainsSpecialCharacter)

    const onSubmit = data => {
        if (!formIsValid()) {
            return
        }

        (async () => {
            setWorking(true)
            let status = null

            try {
                const response = await axios.post("/api/Users/PasswordChangeRequest", {
                    token: data.guid,
                    password: data.firstPassword
                })
                status = response.data.httpStatusCode // From internal api
            } catch (error) {
                status = error?.response?.status
            }

            setWorking(false)

            switch (status) {
                case 200:
                    dispatch(setSnackbarMessage(Localization.yourPasswordHasBeenChanged, "success"))
                    navigate(`/${lang}/signin`)
                    break
                default:
                    dispatch(setSnackbarMessage(Localization.unknownError, "error"))
                    break
            }
        })()
    }

    useEffect(() => {
        setValue("guid", guid);

        (async () => {
            setCheckingValidity(true)

            try {
                const response = await axios.get(`/api/Users/CheckTokenValidity?token=${guid}`)
                setTokenValid(response.data)
            } catch (error) {
                setTokenValid(false)
            }

            setCheckingValidity(false)
        })()
    }, [])

    useEffect(() => {
        const passwordsEmpty = firstPassword.length === 0 && secondPassword.length === 0

        setPasswordsMatch(!passwordsEmpty && firstPassword === secondPassword)
        setPasswordLengthValid(firstPassword.length >= 6)
        setPasswordContainsUppercase(/\p{Lu}/u.test(firstPassword))
        setPasswordContainsLowercase(/\p{Ll}/u.test(firstPassword))
        setPasswordContainsDigit(/\d/.test(firstPassword))
        setPasswordContainsSpecialCharacter(/[!@#$%^&*?]/.test(firstPassword))
    }, [firstPassword, secondPassword])

    return (
        <main className={classes.content}>
            <Container maxWidth="xs">
                <Paper className={classes.paper}>
                    <Typography variant="h6" gutterBottom>{Localization.changePassword}</Typography>
                    <>
                        {
                            checkingValidity && <CircularProgress color="primary" />
                        }
                        {
                            (!tokenValid && !checkingValidity)
                            && <Alert severity="error">{Localization.tryResettingPasswordAgain}</Alert>
                        }
                    </>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            required
                            label={Localization.password}
                            variant="outlined"
                            error={Boolean(errors.firstPassword)}
                            type={firstPasswordHidden ? "password" : "text"}
                            className={classes.field}
                            fullWidth
                            disabled={!tokenValid}
                            onKeyUp={event => setFirstPassword(event.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        tabIndex={-1}
                                        onClick={
                                            () => setFirstPasswordHidden(!firstPasswordHidden)
                                        }
                                    >
                                        {firstPasswordHidden
                                            ? <VisibilityIcon />
                                            : <VisibilityOffIcon />}
                                    </IconButton>
                                )
                            }}
                            {...register("firstPassword", { required: true })}
                        />
                        <TextField
                            required
                            label={Localization.repeatPassword}
                            variant="outlined"
                            error={Boolean(errors.secondPassword)}
                            type={secondPasswordHidden ? "password" : "text"}
                            className={classes.field}
                            fullWidth
                            disabled={!tokenValid}
                            onKeyUp={event => setSecondPassword(event.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        tabIndex={-1}
                                        onClick={
                                            () => setSecondPasswordHidden(!secondPasswordHidden)
                                        }
                                    >
                                        {secondPasswordHidden
                                            ? <VisibilityIcon />
                                            : <VisibilityOffIcon />}
                                    </IconButton>
                                )
                            }}
                            {...register("secondPassword", { required: true })}
                        />
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            fullWidth
                            disabled={working || !tokenValid || !formIsValid()}
                        >
                            {working ? <CircularProgress /> : Localization.changePassword}
                        </Button>
                    </form>
                </Paper>
                <Box height={15} />
                <Alert color={formIsValid() ? "success" : "info"} icon={false}>
                    <p className={classes.passwordDisclaimer}>
                        {Localization.thePasswordMustAlsoComplyToTheFollowingRules}
                    </p>
                    <List dense>
                        {passwordRequirements.map(requirement => (
                            <ListItem key={requirement.text}>
                                <div className={classes.requirementWrapper}>
                                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                        {requirement.valid ? <DoneIcon /> : <InvalidIcon />}
                                    </ListItemIcon>
                                    {requirement.text}
                                </div>
                                {requirement.explanation
                                    && (
                                        <Tooltip title={requirement.explanation}>
                                            <IconButton classes={{ root: classes.helpIcon }}>
                                                <HelpOutlineIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                            </ListItem>
                        ))}
                    </List>
                </Alert>
            </Container>
        </main>
    )
}

export default ResetPassword
