import useStyles from "./styles"
import { strings as Localization } from "../../lib/Localization"
import { useUser, useUserData } from "../../redux/hook/userHooks"
import { LocalShipping, Payment, PersonOutline } from "@mui/icons-material"
import React from "react"

const UserInfo = () => {
    const user = useUser()
    const userData = useUserData()
    const classes = useStyles()

    const addresses = [
        {
            icon: <LocalShipping />,
            title: Localization.standardShippingAddress,
            rows: [
                user?.addresses?.deliveryAddress?.Row1,
                user?.addresses?.deliveryAddress?.Row2,
                user?.addresses?.deliveryAddress?.Row4
            ]
        },
        {
            icon: <Payment />,
            title: Localization.billingAddress,
            rows: [
                user?.addresses?.invoiceAddress?.Row1,
                user?.addresses?.invoiceAddress?.Row2,
                user?.addresses?.invoiceAddress?.Row4
            ]
        }
    ]

    const renderAddressField = (icon, title, rows = []) => {
        const safeTitle = title || "default-title"

        return (
            <div key={safeTitle}>
                <div className={classes.titleRow}>
                    {icon}
                    {title || "Untitled"}
                </div>
                {rows.map((row, index) => (
                    <div key={`${safeTitle}-${index}`}>{row}</div>
                ))}
            </div>
        )
    }

    return (
        <>
            <div className={classes.userInfoWrapper}>
                <div className={classes.titleRow}>
                    <PersonOutline />
                    {" "}
                    {Localization.customerDetails}
                </div>
                <div className={classes.customerGrid}>
                    <div>{`${Localization.customerNumber} ${userData?.customerNumber}`}</div>
                    <div>{`${Localization.name} ${userData?.name}`}</div>
                    <div>{`${Localization.myPagesEmail} ${userData?.email}`}</div>
                </div>
            </div>
            <div className={classes.addressGrid}>
                {addresses.map(address => renderAddressField(address.icon, address.title, address.rows))}
            </div>
        </>
    )
}

export default UserInfo
