import { productViewBreakpoint } from "../../../../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    root: {
        whiteSpace: "pre-line",
        overflowWrap: "break-word"
    },
    text: {
        width: 580,
        lineHeight: 2,
        [theme.breakpoints.down(productViewBreakpoint)]: {
            width: "auto"
        }
    }
}))
