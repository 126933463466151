import ListOptions from "../ListOptions/ListOptions"
import useStyles from "../styles"
import React from "react"
import { withStyles } from "@mui/styles"
import { Button, Menu } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

const StyledMenu = withStyles({
    paper: {
        marginTop: "5px",
        boxShadow: "0px 0px 20px 0px rgba(45, 45, 45, 0.1)"
    }
})(props => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center"
        }}
        {...props}
    />
))

const NavbarMenu = ({ mobile }) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const classes = useStyles()

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="text"
                size="large"
                onClick={handleClick}
                className={classes.productButton}
                style={{ display: "flex", alignItems: "center" }}
            >
                {/* { person icon} */}
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path d="M12.5 12.5C15.815 12.5 18.5 9.815 18.5 6.5C18.5 3.185 15.815 0.5 12.5 0.5C9.185 0.5 6.5 3.185 6.5 6.5C6.5 9.815 9.185 12.5 12.5 12.5ZM12.5 15.5C8.495 15.5 0.5 17.51 0.5 21.5V24.5H24.5V21.5C24.5 17.51 16.505 15.5 12.5 15.5Z" fill="#2D2D2D" />
                </svg>
                {!anchorEl
                    ? <ExpandMoreIcon className={classes.icon} />
                    : <ExpandLessIcon className={classes.icon} />}
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
            >
                <ListOptions button mobile={mobile} />
            </StyledMenu>
        </div>
    )
}

export default NavbarMenu
