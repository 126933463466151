import { useLanguage } from "../../../lib/hooks/languageHooks"
import { strings as Localization } from "../../../lib/Localization"
import {
    Box, List, ListItem, Typography
} from "@mui/material"
import React from "react"
import { NavLink } from "react-router-dom"

function ReplacementProducts({ replacementProducts, searchedId }) {
    const language = useLanguage()

    return (
        <Box style={{ paddingLeft: 15 }}>
            <Typography>
                {/* eslint-disable-next-line max-len */}
                {`${Localization.product} ${searchedId} ${Localization.wasReplacedByFollowingArticles}`}
            </Typography>
            <List>
                {replacementProducts?.map(productId => (
                    <ListItem>
                        <NavLink to={`/${language}/product/${productId}/`}>
                            <Typography>
                                {productId}
                            </Typography>
                        </NavLink>
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}

export default ReplacementProducts
