import { withStyles } from "@mui/styles";
import StepConnector from "@mui/material/StepConnector";

const OrderStepConnector = withStyles({
    alternativeLabel: {
        top: 22
    },
    active: {
        "& $line": {
            backgroundImage:
        "linear-gradient( 95deg,rgb(158, 158, 158) 50%,rgb(255, 152, 0) 100%)"
        }
    },
    completed: {
        "& $line": {
            backgroundImage:
        "linear-gradient( 95deg,rgb(158, 158, 158) 50%,rgb(158, 158, 158) 100%)"
        }
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 1
    }
})(StepConnector);

export default OrderStepConnector;
