import { useEffect } from "react";
import { useSelector } from "react-redux";

const useErrors = () => {
    const priceErrors = useSelector(state => state.price.errors);
    const customerOrderErrors = useSelector(state => state.customerOrder.errors);

    useEffect(() => {

    }, [priceErrors, customerOrderErrors]);

    return priceErrors.length > 0 || customerOrderErrors.length > 0;
}

export default useErrors;
