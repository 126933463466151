import useStyles from "./styles"
import { addToCompare, removeFromCompare } from "../../redux/compareProductsReducer"
import { useCompareCount } from "../../redux/hook/compareHooks"
import { strings as Localization } from "../../lib/Localization"
import { setSnackbarMessage } from "../../redux/userMessageSlice"
import React from "react"
import { IconButton, Tooltip } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import CompareIcon from "@mui/icons-material/Compare"

const CompareToggleButton = ({ productId }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const compareCount = useCompareCount()

    const addItemToCompare = () => {
        dispatch(addToCompare(productId))
    }

    const removeItemFromCompare = () => {
        dispatch(removeFromCompare(productId))
    }

    const productInCompare = useSelector(
        state => state.compareProduct.productIds.find(x => x === productId) === productId
    )

    const onClick = () => {
        if (!productInCompare) {
            if (compareCount < 2) {
                addItemToCompare()
            } else {
                dispatch(setSnackbarMessage(Localization.compareMaxTwoArticles, "warning"))
            }
        } else {
            removeItemFromCompare()
        }
    }

    return (
        <Tooltip title={Localization.compare}>
            <IconButton
                onClick={onClick}
                className={classes.iconButton}
                color={productInCompare ? "primary" : undefined}
                disabled={!(productInCompare || (compareCount < 3))}
            >
                <CompareIcon />
            </IconButton>
        </Tooltip>
    )
}

export default CompareToggleButton
