import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        minHeight: 450
    },
    paper: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(8)
    },
    field: {
        marginBottom: theme.spacing(2)
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    passwordDisclaimer: {
        margin: 0,
        padding: "8px 16px"
    },
    listItemIcon: {
        marginRight: "8px",
        minWidth: "unset"
    },
    helpIcon: {
        marginLeft: "8px",
        padding: "unset"
    },
    requirementWrapper: {
        display: "flex",
        alignItems: "center"
    }
}))
