/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import { brandsNameCompare } from "./brandHelper"
import { modelNameCompare } from "./modelHelper"
import yearCompare from "./yearHelper"

const addYearPrefix = year => {
    if (year === undefined) return ""
    if (year === "") return ""
    return year.replace(/\D/, "") > parseInt(46) // Denna går antagligen sönder 2047
        ? `19${year}`
        : `20${year}`
}

export const getYearString = yearInterval => {
    const [start, end] = yearInterval.split("-")
    const startYear = addYearPrefix(start)
    const endYear = addYearPrefix(end)
    return `${startYear}-${endYear}`
}

// Comparer for sort.
export const sortModelVersions = (a, b) => {
    let compareResult = brandsNameCompare(a.brand, b.brand)
    if (compareResult === 0) {
        compareResult = modelNameCompare(a.model, b.model)
    }
    if (compareResult === 0) {
        compareResult = yearCompare(getYearString(a.years), getYearString(b.years))
    }
    return compareResult
}

export const compatibleModelText = comp => {
    const list = [`${comp.brand} `, `${comp.model} `, comp.years ? `${getYearString(comp.years)} ` : "", comp.placement ? `(${comp.placement}) ` : ""]
    const result = list.join("").trimEnd()
    return result
}
