import { tabletBreakpoint } from "../../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    listRoot: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        maxHeight: "400px",
        overflowY: "auto"
    },
    root: {
        marginTop: 72
    },
    marginBottom: {
        marginBottom: 15
    },
    capitalized: {
        textTransform: "uppercase"
    },
    icon: {
        color: "rgba(0,0,0,0.3)",
        "&$checked": {
            color: "#7c7c7c"
        }
    },
    checked: {},
    dense: {
        paddingTop: 0,
        paddingBottom: 0
    },
    buttonRoot: {
        minWidth: "auto"
    },
    button: {
        marginLeft: theme.spacing(1),
        color: "#2d2d2d"
    },
    select: {
        width: "80%!important",
        marginLeft: "1em"
    },
    flexGrow1: {
        flexGrow: 1
    },
    productBox: {
        position: "relative",
        display: "flex",
        flexDirection: "row"
    },
    productsContainerWithFilter: {
        flex: "0 0 300px",
        overflowY: "auto",
        overflowX: "hidden",
        height: "100%",
        padding: "16px",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            flex: "unset"
        }
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "flex-end"
    }
}))
