import useStyles from "./styles"
import React from "react"
import { TextField } from "@mui/material"
import classNames from "classnames"

const CustomTextField = ({ useDefaultStyle, className, ...props }) => {
    const classes = useStyles()

    return (
        <TextField
            InputProps={{
                classes: !useDefaultStyle && { input: classes.input }
            }}
            className={classNames(classes.textField, className)}
            {...props}
        />
    )
}

export default CustomTextField
