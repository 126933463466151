import useStyles from "./styles"
import SwipeableTextMobileStepper from "../../../SwipableView"
import { Box } from "@mui/material"
import React from "react"

export function GridImage({
    listView, product, grid, onSelect, phasingOut
}) {
    const classes = useStyles()
    return (
        <Box
            className={classes.swipableImageGridView}
            style={{
                padding: "10px 10px",
                display: !listView ? "none" : "flex"
            }}
        >
            {listView
                ? (
                    <SwipeableTextMobileStepper
                        product={product}
                        listView
                        grid={grid}
                        onSelect={onSelect}
                        maxHeight="160px"
                        phasingOut={phasingOut}
                        disableSwipe
                    />
                )
                : null}
        </Box>
    )
}

export function ListImage({
    product, grid, onSelect, phasingOut
}) {
    const classes = useStyles()
    return (
        <Box
            display="flex"
            className={classes.swipableImageListView}
        >
            <SwipeableTextMobileStepper
                product={product}
                listView
                grid={grid}
                onSelect={onSelect}
                maxHeight="160px"
                bannerArea="46px"
                phasingOut={phasingOut}
            />

        </Box>
    )
}

export function ModTekImage({
    product, grid, onSelect, phasingOut
}) {
    const classes = useStyles()
    return (
        <Box
            display="flex"
            className={classes.swipableImageModTekView}
        >
            <SwipeableTextMobileStepper
                product={product}
                listView
                grid={grid}
                onSelect={onSelect}
                bannerArea="0px"
                phasingOut={phasingOut}
                modTek
            />

        </Box>
    )
}
