import { strings as Localization } from "../../../lib/Localization"
import React from "react"

function ScrollableList({ userLists = [] }) {
    const names = userLists.map(x => x[0]?.listName)
    const scrollToSection = id => {
        const element = document.getElementById(id)

        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY
            const offsetPosition = elementPosition - 200

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            })
        }
    }

    return (
        <div style={{
            display: "flex", alignItems: "center", flexWrap: "wrap", marginBottom: "8px"
        }}
        >
            <p style={{ textAlign: "center", minWidth: "70px" }}>{Localization.myLists}</p>
            <div style={{
                display: "flex", marginLeft: 4, gap: 4, flexWrap: "wrap"
            }}
            >
                {names.map(name => (
                    <span
                        tabIndex="0"
                        role="button"
                        onKeyDown={e => e.key === "Enter" && scrollToSection(name)()}
                        key={name}
                        onClick={() => scrollToSection(name)}
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                        {name}
                        {", "}
                    </span>
                ))}
                <span
                    tabIndex="0"
                    role="button"
                    onKeyDown={e => e.key === "Enter" && scrollToSection("products")()}
                    key="products"
                    onClick={() => scrollToSection("products")}
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                    {Localization.products}
                </span>
            </div>
        </div>
    )
}

export default ScrollableList
