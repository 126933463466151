import { useLanguage } from "../../lib/hooks/languageHooks"
import React from "react"
import { Helmet } from "react-helmet"

function AboutPageMetaDescription() {
    const language = useLanguage()

    const title = language === "sv"
        ? "Om Oss - Brodit"
        : "About us - Brodit"
    const description = language === "sv"
        ? "Världsledande tillverkare inom monteringslösningar för kommunikationsenheter i fordon. Brodit AB grundades år 1983 och ligger i Karlsborg, Västergötland. Brodit har 145 anställda och exporterar till 30 länder."
        : "World-leading manufacturer of mounting solutions for communication devices in vehicles. Brodit AB was founded in 1983 and is located in Karlsborg, Västergötland. Brodit has 145 employees and exports to 30 countries."

    const keywords = language === "sv"
        ? "om oss, Brodit"
        : "about us, Brodit"

    return (
        <Helmet>
            <title>{title}</title>
            <meta
                name="description"
                content={description}
            />
            <meta
                name="keywords"
                content={keywords}
            />
        </Helmet>
    )
}

export default AboutPageMetaDescription
