import { tabletBreakpoint } from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    container: {
        padding: theme.spacing(3),
        minWidth: theme.spacing(80),
        backgroundColor: theme.palette.grey[200],
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            minWidth: "80%"
        }
    },
    buttonBox: {
        display: "flex",
        justifyContent: "space-between"
    },
    buttonContainer: {
        display: "flex",
        alignItems: "flex-end"
    },
    maxWidth600: {
        maxWidth: 600
    }
}))
