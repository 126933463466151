import { selectAll } from "../adapter/newsArticleAdapter"
import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const fetchNewsArticleById = async (id, lang) => {
    const a = await axios.get(`/api/news/${id}?language=${lang}`)
    return a.data
}

const fetchNewsArticles = async ({
    lang, limit, group, includeFutureNews
}) => {
    const groupParam = (group && group !== "") ? `&group=${group}` : ""
    const response = axios.get(`/api/news?language=${lang}&limit=${limit}&includeFutureNews=${includeFutureNews}${groupParam}`)
    return response.data
}

// const fetchNewsArticle = async (slug, lang) => {
//     const a = await axios.get(`/api/Pages/GetNewsArticle?slug=${slug}&language=${lang}`);
//     return a.data;
// }

// export const requestNewsArticle = createAsyncThunk('newsArticle/fetchBySlug',
//     async (params) => {
//         const { slug, lang } = params;
//         return await fetchNewsArticle(slug, lang);
//     },{
//         condition: (params, { getState }) => {
//             const { slug } = params;
//             const existing = selectAll(getState()).some(x => x.slug === slug);
//             return !existing;
//         }
//     }
// );

export const requestNewsArticleById = createAsyncThunk(
    "newsArticle/fetchById",
    async params => {
        const { id, lang } = params
        return fetchNewsArticleById(id, lang)
    },
    {
        condition: (params, { getState }) => {
            const { id } = params
            const existing = selectAll(getState()).some(x => x.originalId === id)
            return !existing
        }
    }
)

export const requestNewsArticles = createAsyncThunk(
    "newsArticle/fetchAll",
    async params => fetchNewsArticles(params)
)
