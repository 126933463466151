import ComparisonTableSection from "./ComparisonTableSection"
import useStyles from "../styles"
import { productViewBreakpoint } from "../../../lib/helper/viewportHelper"
import theme from "../../../theme"
import {
    Box, Paper, Table, TableBody, TableContainer, useMediaQuery
} from "@mui/material"
import React from "react"

const ProductComparisonSections = ({ sections }) => {
    const classes = useStyles()
    const isMobile = useMediaQuery(theme.breakpoints.down(productViewBreakpoint))

    return (
        <>
            {
                sections.map((section, i) => (
                    <Box
                        key={`sc-${i}`}
                        className={classes.result}
                        style={{ padding: isMobile && 0 }}
                    >
                        <Box className={classes.paperWrapper}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableBody>
                                        {
                                            section.Data.map((info, index) => (
                                                <ComparisonTableSection
                                                    text={info.Description}
                                                    informations={info.Values}
                                                    index={index}
                                                    key={`ct-${index}`}
                                                />
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                ))
            }
        </>
    )
}

export default ProductComparisonSections
