import { useUserAuthToken } from "../../../redux/hook/userHooks"
import { deleteFavoriteList, setFavoriteProducts } from "../../../redux/favoriteSlice"
import { fetchModteks } from "../../../redux/thunk/productThunk"
import ProductViewToggleButton from "../../Products/ProductViewToggleButton/ProductViewToggleButton"
// eslint-disable-next-line import/no-cycle
import Products from "../../Products/Products"
import CheckIcon from "../../../assets/check.png"
import ClearIcon from "../../../assets/close.png"
import Trashcan from "../../../assets/delete.png"
import PenIcon from "../../../assets/pen.png"
import { strings as Localization } from "../../../lib/Localization"
import React, { useState, useEffect } from "react"

import { useDispatch } from "react-redux"
import axios from "axios"

import {
    Autocomplete, Box, Chip, Divider, TextField, Tooltip, Typography
} from "@mui/material"

function Userlist({
    list = [], language, classes, theme, productsArray
}) {
    const [data, setData] = useState(null)
    const [name, setName] = useState(list[0]?.listName)
    const [isEditing, setIsEditing] = useState(false)
    const [tempName, setTempName] = useState("")
    const dispatch = useDispatch()
    const authToken = useUserAuthToken()
    const idArray = list?.map(x => x.productId)
    const [selectedItems, setSelectedItems] = useState(idArray)
    const productList = productsArray

    const updateFavorites = async ({ newName, products, oldName }) => {
        const a = await axios.post(`/api/favorite/UpdateFavoriteListName?authToken=${authToken}`, { newName, products, oldName })
        dispatch(setFavoriteProducts(a.data.response))
        setIsEditing(false)
    }

    const deleteFavortiteList = async () => {
        dispatch(deleteFavoriteList(name))
        await axios.post(`/api/favorite/DeleteFavoriteListByName?authToken=${authToken}`, { name })
    }

    const handleEditToggle = () => {
        setIsEditing(true)
        setTempName(name)
    }

    const handleNameChange = event => {
        setTempName(event.target.value)
    }

    const handleSave = () => {
        setName(tempName)
        setIsEditing(false)
        const newObject = { newName: tempName, products: selectedItems, oldName: list[0]?.listName }
        updateFavorites(newObject)
    }

    const handleDelete = () => {
        deleteFavortiteList()
        setTempName("")
        setSelectedItems(idArray)
    }

    const handleCancel = () => {
        setTempName(name)
        setIsEditing(false)
        setTempName("")
        setSelectedItems(idArray)
    }

    const handleKeyDown = event => {
        if (event.key === "Enter") {
            handleEditToggle()
        }
    }

    const handleKeyDownDelete = event => {
        if (event.key === "Enter") {
            deleteFavortiteList()
        }
    }

    const handleSelect = (_event, value) => {
        const selectedOptions = value
        setSelectedItems(selectedOptions)
    }

    useEffect(() => {
        let isMounted = true

        const fetchData = async () => {
            try {
                const d = await fetchModteks(idArray, language)
                const processedData = d.map(x => ({
                    ...x,
                    originalId: x.id,
                    id: `${language}-${x.id}`
                }))

                if (isMounted) {
                    setData(processedData.sort((a, b) => a.originalId.localeCompare(b.originalId)))
                }
            } catch (error) {
                console.error("Error fetching data:", error)
            }
        }

        fetchData()
        setName(list[0]?.listName)
        setSelectedItems(idArray)

        return () => {
            isMounted = false
        }
    }, [list, language])

    return (
        <div id={list[0]?.listName}>
            <Box className={classes.listArea}>
                <Box style={{
                    margin: `0 ${theme.spacing(3)}px`,
                    marginTop: theme.spacing(6),
                    display: "flex",
                    justifyContent: "space-between"
                }}
                >
                    <div style={{ display: "flex" }}>
                        {isEditing ? (
                            <input
                                type="text"
                                placeholder={list[0]?.listName}
                                value={tempName}
                                onChange={handleNameChange}
                                style={{ height: "16px", width: "96px" }}
                            />
                        ) : (
                            <Typography onClick={handleEditToggle}>
                                {name}
                            </Typography>
                        )}
                        {isEditing
                            ? (
                                <>
                                    <Autocomplete
                                        id="favoriteSelector"
                                        onChange={handleSelect}
                                        multiple
                                        options={productList}
                                        className={classes.select}
                                        variant="standard"
                                        style={{ minWidth: "275px" }}
                                        value={selectedItems}
                                        disableCloseOnSelect
                                        clearText={Localization.clear}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true
                                                }}
                                                placeholder={Localization.productId}
                                                sx={{
                                                    "& .MuiInputBase-root": {
                                                        height: "24px"
                                                    },
                                                    "&. .MuiInputBase-root-MuiInput-root": {
                                                        marginTop: 0
                                                    },
                                                    "& .MuiInput-root .MuiInput-input": {
                                                        padding: "0px"
                                                    }
                                                }}
                                            />
                                        )}
                                        renderTags={value => (value.length > 0 ? (
                                            <Chip label={`${value.length}`} style={{ height: 24 }} />
                                        ) : null)}
                                    />
                                    <Tooltip title={Localization.save}>
                                        <Box
                                            tabIndex="0"
                                            role="button"
                                            onKeyDown={e => e.key === "Enter" && handleSave()}
                                            onClick={handleSave}
                                        >
                                            <img src={CheckIcon} alt={Localization.save} style={{ width: "24px", height: "24px" }} />
                                        </Box>
                                    </Tooltip>
                                    <Tooltip title={Localization.cancel}>
                                        <Box
                                            tabIndex="0"
                                            role="button"
                                            onKeyDown={e => e.key === "Enter" && handleCancel()}
                                            onClick={handleCancel}
                                        >
                                            <img src={ClearIcon} alt={Localization.cancel} style={{ width: "24px", height: "24px" }} />
                                        </Box>
                                    </Tooltip>
                                </>
                            )
                            : (
                                <Tooltip title={Localization.editList}>
                                    <div role="button" onClick={handleEditToggle} onKeyDown={handleKeyDown} tabIndex="0" style={{ cursor: "pointer" }}>
                                        <img
                                            src={PenIcon}
                                            style={{ marginLeft: "8px", height: "16px", width: "16px" }}
                                            alt="edit name"
                                        />
                                    </div>
                                </Tooltip>
                            )}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title={Localization.deleteList}>
                            <div role="button" onClick={handleDelete} onKeyDown={handleKeyDownDelete} tabIndex="0" style={{ cursor: "pointer", display: "flex" }}>
                                <img src={Trashcan} alt="delete button" />
                            </div>
                        </Tooltip>
                        <ProductViewToggleButton />
                    </div>
                </Box>
                <Divider />
                <Products
                    products={data ?? []}
                />
            </Box>
        </div>
    )
}

export default Userlist
