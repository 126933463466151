import { requestCustomerOrders, requestCustomerOrder } from "./thunk/customerOrderThunk"
import { createSlice } from "@reduxjs/toolkit"

const slice = createSlice({
    name: "customerOrder",
    initialState: {
        list: [],
        errors: []
    },
    reducers: {
        clearErrors: state => ({
            ...state,
            errors: []
        })
    },
    extraReducers: builder => {
        builder.addCase(requestCustomerOrders.fulfilled, (state, action) => {
            state.list = action.payload
            if (state.list === "") state.list = []
        })
        builder.addCase(requestCustomerOrders.rejected, (state, action) => {
            state.errors.push(action.payload)
        })
        builder.addCase(requestCustomerOrder.fulfilled, (state, action) => {
            state.list = action.payload
            if (state.list === "") state.list = []
        })
        builder.addCase(requestCustomerOrder.rejected, (state, action) => {
            state.errors.push(action.payload)
        })
    }
})

export default slice.reducer

const { clearErrors } = slice.actions

export const clearCustomerOrderErrors = () => dispatch => dispatch(
    clearErrors()
)
