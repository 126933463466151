import { strings as Localization } from "../../../../lib/Localization"
import NoIndexMetaData from "../../../MetaData/NoIndexMetadata"
import useStyles from "../styles"
import { useGetReplacementProductsQuery } from "../../../../redux/services/broditWebApi"
import { useLanguage } from "../../../../lib/hooks/languageHooks"
import { Typography, List, ListItem } from "@mui/material"
import { NavLink } from "react-router-dom"
import React from "react"

const NotFoundView = ({ productId }) => {
    const classes = useStyles()
    const lang = useLanguage()

    const { data: replacementResponse } = useGetReplacementProductsQuery(productId)

    return (
        <main className={classes.content}>
            <NoIndexMetaData />
            <Typography variant="h4">
                {Localization.productNotFound}
            </Typography>
            <Typography>
                {Localization.weCouldNotFindThisProductEtc}
            </Typography>

            {/* wont apply for legacy products */}
            {(replacementResponse?.deleted && replacementResponse?.replacementProducts?.length > 0)
                && (
                    <>
                        <Typography>
                            {Localization.theProductWasReplacedWithFollowingProductProducts}
                        </Typography>
                        <List>
                            {replacementResponse.replacementProducts.map(prod => (
                                <ListItem>
                                    <NavLink to={`/${lang}/product/${prod}`}>
                                        <Typography>{prod}</Typography>
                                    </NavLink>
                                </ListItem>
                            ))}
                        </List>
                    </>
                )}

        </main>
    )
}

export default NotFoundView
