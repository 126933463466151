/* eslint-disable no-return-assign */
// https://gist.github.com/vre2h/d620ad389580e788b7482245fa62b06c

// Specify reducers that should be saved to localstorage
const reducersToSave = ["settings", "gdpr"]

export const loadReducers = () => {
    try {
        const serializedReducers = localStorage.getItem("persistantReducers")
        if (!serializedReducers) return undefined
        return JSON.parse(serializedReducers)
    } catch (err) {
        console.warn(err)
        return undefined
    }
}

export const saveReducers = state => {
    const reducers = {}
    reducersToSave.forEach(key => reducers[key] = state[key])

    try {
        const serializedReducers = JSON.stringify(reducers)
        localStorage.setItem("persistantReducers", serializedReducers)
    } catch (err) {
        console.warn(err)
    }
}
