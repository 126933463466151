import useStyles from "./styles"
import { useLanguage } from "../../lib/hooks/languageHooks"
import theme from "../../theme"
import { desktopBreakpoint, mobileBreakpoint } from "../../lib/helper/viewportHelper"
import { strings as Localization } from "../../lib/Localization"
import {
    Box, Typography, useMediaQuery, Skeleton
} from "@mui/material"
import React, { useState } from "react"

function Thumbnail({
    src,
    content: contents,
    alt,
    loading,
    style,
    srcWidth,
    thumbNailId,
    title,
    home = null
}) {
    const language = useLanguage()
    const isDesktop = useMediaQuery(theme.breakpoints.up(desktopBreakpoint))
    const classes = useStyles()
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint))
    const [isActive, setIsActive] = useState(false)

    let image = contents?.find(content => content.language === language)?.image
    image = image ?? src

    const widthQuery = srcWidth !== undefined ? `?width=${srcWidth}&language=${language}` : ""

    const styleWithWidth = {
        width: "auto",
        flex: 1,
        boxShadow: "0px 0px 20px 0px rgba(45, 45, 45, 0.1)",
        minHeight: "200px",
        ...style
    }

    return (
        (!loading)
            ? (
                <Box
                    id={thumbNailId}
                    style={{ ...styleWithWidth, background: "#FFFFFF" }}
                    onMouseEnter={() => setIsActive(true)}
                    onMouseLeave={() => setIsActive(false)}
                >
                    <div style={{ textDecoration: "none", backgroundColor: "#FFF" }}>
                        <img
                            style={{ width: "100%", borderRadius: "6px 6px 0px 0px" }}
                            loading="lazy"
                            src={`${image}${widthQuery}`}
                            alt={alt}
                        />
                        {home !== null && (
                            <Box
                                style={{
                                    display: "flex",
                                    width: isDesktop ? "350px" : "300px",
                                    padding: "5px 20px 20px 20px",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    gap: "10px",
                                    borderRadius: "0px 0px 6px 6px",
                                    backgroundColor: "#FFF",
                                    minHeight: 40
                                }}
                            >
                                <Typography
                                    className={classes.blackH3}
                                    style={{ height: "64px" }}
                                    variant="h3"
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    className={`${classes.hoverUnderline} ${isActive ? "isActive" : ""}`}
                                    style={{
                                        color: "#2D2D2D",
                                        fontFamily: "Arial",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        lineHeight: "16px", /* 114.286% */
                                        letterSpacing: "0.5px",
                                        borderBottom: isMobile ? "solid #F09057 2px" : "",
                                        paddingBottom: "3px"
                                    }}
                                >
                                    {Localization.seeProduct}
                                </Typography>
                            </Box>
                        )}
                    </div>
                </Box>
            )
            : <Skeleton variant="rect" animation="wave" style={styleWithWidth} height="400px" />
    )
}

export default Thumbnail
