import SwipeableMobileStepper from "../SwipableView"
import { strings as Localization } from "../../lib/Localization"
import React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"

export default function ImageDialog({
    open, index, handleClose = f => f, product
}) {
    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
            <DialogContent style={{ overflow: "visible" }}>
                <SwipeableMobileStepper
                    product={product}
                    isDialog
                    dialogIndex={index}
                    maxHeight="100%"
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    {Localization.close}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
