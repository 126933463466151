import useStyles from "./styles"
import { useSteering, useUserAuthToken, useIsUserAuthorized } from "../../../redux/hook/userHooks"
import { useSetPreferredSteeringMutation } from "../../../redux/services/broditWebApi"
import { changeSteering } from "../../../redux/userSlice"
import React from "react"
import { useDispatch } from "react-redux"

function LeftRightSteeringFilter({ mobileSearchArea }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const steering = useSteering()

    const isUserAuthorized = useIsUserAuthorized()
    const authToken = useUserAuthToken()

    const [setPreferredSteering] = useSetPreferredSteeringMutation()

    const handleButtonPress = preferredSteering => {
        if (isUserAuthorized) {
            setPreferredSteering({
                preferredSteering: preferredSteering,
                authToken: authToken
            })
        }

        dispatch(changeSteering(preferredSteering))
    }

    return (
        <div className={classes.steeringButton} id="LDHRHD" style={{ flex: mobileSearchArea && "1" }}>
            <div className={`${classes.slide} ${steering === "1" ? classes.slideRight : ""}`} />
            <button
                type="button"
                onClick={() => handleButtonPress("1")}
                className={`${classes.option} ${steering === "1" ? classes.active : ""}`}
            >
                LHD
            </button>
            <button
                type="button"
                onClick={() => handleButtonPress("2")}
                className={`${classes.option} ${steering === "2" ? classes.active : ""}`}
            >
                RHD
            </button>
        </div>
    )
}

export default LeftRightSteeringFilter
