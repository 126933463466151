import { modelAdapter } from "./adapter/modelAdapter"
import { requestModel, requestModelsByBrand } from "./thunk/modelThunk"
import { createSlice } from "@reduxjs/toolkit"

// Then, handle actions in your reducers:
const modelSlice = createSlice({
    name: "model",
    initialState: modelAdapter.getInitialState({
        lastModelFetch: []
    }),
    reducers: {},
    extraReducers: builder => {
        builder.addCase(requestModel.fulfilled, (state, action) => {
            modelAdapter.addOne(state, action.payload)
        })
        builder.addCase(requestModelsByBrand.fulfilled, (state, action) => {
            state.lastModelFetch = action.payload.map(x => x.id)
            modelAdapter.addMany(state, action.payload)
        })
    }
})

export default modelSlice.reducer
