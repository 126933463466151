import { makeStyles } from "@mui/styles"

export default makeStyles(() => ({
    root: {
        whiteSpace: "nowrap",
        boxSizing: "border-box"
    },
    rotated: {
        writingMode: "vertical-lr",
        textOrientation: "mixed",
        transform: "rotate(180deg)",
        paddingBottom: 5,
        paddingRight: 1,
        paddingTop: 5,
        marginLeft: 5,
        marginRight: 5
    },
    text: {
        textTransform: "uppercase",
        color: "#fff",
        fontSize: 8
    }
}))
