import { makeStyles } from "@mui/styles"

export default makeStyles(() => ({
    root: {
        flex: "0 1 100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    homePage: {
        width: "100%"
    }
}))
