import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    capitalized: {
        textTransform: "uppercase"
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column"
    },
    marginBottom: {
        marginBottom: 40
    },
    flexRow: {
        display: "flex",
        flexDirection: "row"
    },
    flexEnd: {
        justifyContent: "flex-end",
        marginLeft: 50
    },
    spaceEven: {
        justifyContent: "space-evenly"
    },
    orderLineContent: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2)
    },
    listItemGutters: {
        padding: 0
    },
    externalLinkIcon: {
        padding: 4,
        verticalAlign: "middle"
    }
}))
