import { resetCurrentPage } from "../pageReducer"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useNavigationType } from "react-router-dom"

const usePage = () => {
    const page = useSelector(state => state.page)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const navigationType = useNavigationType()

    const returnObj = { limit: 0, offset: 0, userSelectedLimit: 0 }

    useEffect(() => {
        if (navigationType !== "POP") dispatch(resetCurrentPage())
    }, [dispatch, navigationType])

    if (navigate.action === "POP" && page.action === "leave") {
        returnObj.limit = page.prevLimit
        returnObj.offset = page.prevOffset
    } else if (navigate.action === "POP" && page.action === "load_more") {
        returnObj.limit = page.limit
        returnObj.offset = page.offset
    } else if (navigate.action === "POP" && page.action === "none") {
        returnObj.limit = page.prevLimit
        returnObj.offset = page.prevOffset
    } else {
        returnObj.limit = page.limit
        returnObj.offset = page.offset
    }

    returnObj.userSelectedLimit = page.userSelectedLimit
    return returnObj
}

export default usePage
