import { setShouldSyncCart } from "../../../redux/cartSlice"
import { useIsUserAuthorized, useUserAuthToken } from "../../../redux/hook/userHooks"
import { useGetCartQuery, useUpdateCartMutation } from "../../../redux/services/cartApi"
import { useState, useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

const CartHandler = () => {
    const dispatch = useDispatch()
    const cart = useSelector(state => state.cart)
    const authToken = useUserAuthToken()
    const isAuthorized = useIsUserAuthorized()
    const [savedCartTimeout, setSavedCartTimeout] = useState(null)
    const saveTimeoutDuration = 1500

    const [updateCartOnServer, _result] = useUpdateCartMutation()
    const { isLoading: isLoadingCart } = useGetCartQuery(
        { authToken },
        { skip: !isAuthorized }
    )

    useEffect(() => {
        if (cart.shouldSync) {
            if (savedCartTimeout) {
                clearTimeout(savedCartTimeout)
                setSavedCartTimeout(null)
            }

            if (isAuthorized && !isLoadingCart) {
                setSavedCartTimeout(
                    setTimeout(
                        () => {
                            updateCartOnServer({
                                authToken: authToken,
                                cart
                            })
                        },
                        saveTimeoutDuration
                    )
                )
            }

            dispatch(setShouldSyncCart(false))
        }
    }, [cart, authToken, isAuthorized, isLoadingCart, cart.shouldSync])

    return null
}

export default CartHandler
