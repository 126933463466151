import Menu2 from "./Menu2"
import useStyles from "./styles"
import { setSearchQuery } from "../../redux/searchReducer"
import React from "react"
import { useDispatch } from "react-redux"

function Menu2Drawer({ toggleDrawer, open, onClose }) {
    const dispatch = useDispatch()
    const classes = useStyles()

    const onItemClick = id => {
        dispatch(setSearchQuery({
            type: "menu2Drawer",
            value: { collections: [id] },
            clearFilter: true
        }))
        toggleDrawer()
    }

    const openClass = open ? classes.open : ""

    return (
        <div
            className={`${classes.drawer} ${openClass}`}
        >
            <Menu2
                toggleDrawer={toggleDrawer}
                onItemClick={onItemClick}
                onClose={onClose}
            />
        </div>
    )
}

export default Menu2Drawer
