import { eraseCookie } from "../../redux/cookieStorage"
import ReactGA from "react-ga4"

export const startGoogleAnalytics = () => {
    if (process.env.REACT_APP_GA_TRACKING_ID) {
        ReactGA.initialize(
            process.env.REACT_APP_GA_TRACKING_ID
            // ,{
            //     debug: true,
            //     // titleCase: false,
            //     gaOptions: {
            //         // userId: 123,
            //         siteSpeedSampleRate: 100
            //     }
            // }
        )
    }
}

export const stopGoogleAnalytics = () => {
    const cookieIdName = process.env.REACT_APP_GA_TRACKING_ID.replace(/.*?\-/, "")
    eraseCookie(`_ga_${cookieIdName}`)
    eraseCookie("_ga")
    eraseCookie("_gat")
    eraseCookie("_gid")
}

export const sendGAEventProductSearch = () => {
    ReactGA.event(
        {
            category: "product",
            action: "search",
            label: "Searched on products"
        }
    )
}

export const sendGAEventProductSearchViaLink = () => {
    ReactGA.event(
        {
            category: "product",
            action: "search-using-link",
            label: "Searched on products using a link"
        }
    )
}

export const sendGAEventSignedIn = () => {
    ReactGA.event(
        {
            category: "user",
            action: "sign-in",
            label: "Signed in"
        }
    )
}

export const sendGAEventSignInUnauthorized = () => {
    ReactGA.event(
        {
            category: "user",
            action: "sign-in-unauthorized",
            label: "Sign in failed due to being unauthorized"
        }
    )
}

export const sendGAEventMaintenanceSignIn = () => {
    ReactGA.event(
        {
            category: "user",
            action: "sign-in-blocked-by-maintenance",
            label: "Blocked sign in attempt due to maintenance"
        }
    )
}

export const sendGAEventErrorOnSignIn = () => {
    ReactGA.event(
        {
            category: "user",
            action: "sign-in-error",
            label: "Error on signing in"
        }
    )
}
