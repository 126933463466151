import useStyles from "../../styles"
import {
    AppBar, Box, Tabs, Skeleton
} from "@mui/material"

import React from "react"
import classnames from "classnames"

const DesktopLoading = () => {
    const classes = useStyles()
    return (
        <Box className={classes.content}>
            <Box>
                <Box id="productMain" className={classes.productMain}>
                    <Box id="productImageGrid" className={classnames(classes.halfWidth, classes.justifyCenter)}>
                        <Skeleton variant="rect" animation="wave" className={classes.bigLoading} />
                    </Box>
                    <Box className={classes.halfWidth}>
                        <Skeleton variant="rect" animation="wave" className={classes.bigLoading} />
                    </Box>
                    <Box className={classes.sideBoard} />
                </Box>
                <Box className={classes.tabRoot}>
                    <AppBar position="static" className={classes.appBar}>
                        <Tabs value={0} aria-label="simple tabs example" className={classes.tabs} classes={{ indicator: classes.indicator }}>
                            <Skeleton variant="rect" animation="wave" className={classes.smallLoading} />
                            <Skeleton variant="rect" animation="wave" className={classes.smallLoading} />
                            <Skeleton variant="rect" animation="wave" className={classes.smallLoading} />
                            <Skeleton variant="rect" animation="wave" className={classes.smallLoading} />
                        </Tabs>
                    </AppBar>
                    <Skeleton variant="rect" animation="wave" className={classes.contentSkeleton} />
                </Box>
            </Box>
        </Box>
    )
}
export default DesktopLoading
