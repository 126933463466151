import { createSlice } from "@reduxjs/toolkit"

export const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        productGridView: true,
        proxyPath: "",
        recaptchaSiteKey: "",
        isShowingNavbarSearch: false
    },
    reducers: {
        toggleProductGridView: state => ({
            ...state,
            productGridView: !state.productGridView
        }),
        setPP: (state, action) => {
            state.proxyPath = action.payload
        },
        setRCSK: (state, action) => {
            state.recaptchaSiteKey = action.payload
        },
        setShowNavbarSearch: (state, action) => {
            state.isShowingNavbarSearch = action.payload
        }
    }
})

export default settingsSlice.reducer

const {
    toggleProductGridView, setPP, setRCSK, setShowNavbarSearch: setShowNavbarSearchAction
} = settingsSlice.actions

export const toggleProductGridViewSetting = () => dispatch => dispatch(toggleProductGridView())

export const setProxyPath = proxyPath => dispatch => dispatch(
    setPP(proxyPath)
)

export const setRecaptchaSiteKey = sitekey => dispatch => dispatch(
    setRCSK(sitekey)
)

export const setShowNavbarSearch = shouldShow => dispatch => dispatch(
    setShowNavbarSearchAction(shouldShow)
)
