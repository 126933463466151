import ScrollTopButton from "./components/ScrollTopButton"
import DownloadIcon from "./assets/dl-icon.svg"
import { useLanguage } from "../../lib/hooks/languageHooks"
import {
    midSizeBreakpoint, mobileBreakpoint, mobileMenuBreakpoint, desktopBreakpoint
} from "../../lib/helper/viewportHelper"
import { useIsUserAuthorized } from "../../redux/hook/userHooks"
import React, { useState, useEffect, useRef } from "react"
import { styled } from "@mui/styles"
import { Link } from "react-router-dom"
import { useMediaQuery } from "@mui/material"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"

const Root = styled("div")(({ theme }) => (
    {
        display: "flex",
        justifyContent: "center",
        background: theme.palette.primary.main
    }
))

const StickyMenu = styled("div")(({ theme, authorized, language }) => (
    {
        zIndex: 2,
        display: "flex",
        position: "sticky",
        top: (!authorized) ? "137px" : (language === "sv") ? "165px" : "168px",
        justifyContent: "center",
        alignItems: "center",
        background: "#FFFFFF",
        padding: "12px 24px",
        boxShadow: "0px 0px 14.6px 0px #2D2D2D1A",
        borderRadius: "6px",
        marginTop: "30px",
        [theme.breakpoints.down(1200)]: {
            display: "none"
        }
    }
))

const MenuLink = styled(Link)(() => (
    {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: "17px",
        letterSpacing: "0.5px",
        textDecoration: "none",
        color: "#2D2D2D",
        transition: "0.1s all ease-in",
        "&:hover": {
            color: "#ef7830"
        }
    }
))

const Separator = styled("div")(() => (
    {
        width: "1px",
        height: "15px",
        backgroundColor: "#6C6C6C",
        margin: "0 20px"
    }
))

const HeroWrapper = styled("div")(() => (
    {
        display: "flex",
        flex: "0 1 100%",
        maxWidth: "100%",
        justifyContent: "center"
    }
))

const HeroImage = styled("img")(() => (
    {
        width: "100%",
        maxWidth: "100%"
    }
))

const HeroPicture = styled("picture")(() => (
    {
        flex: "0 1 1920px",
        maxWidth: "100%"
    }
))

const PageWrapper = styled("div")(() => (
    {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        flex: "0 1 100%",
        backgroundColor: "#FFFFFF"
    }
))

const TextSection = styled("div")(({ theme, authorized }) => (
    {
        display: "flex",
        flex: "0 1 100%",
        backgroundColor: "#FFFFFF",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "90px",
        paddingBottom: "120px",
        scrollMarginTop: !authorized ? "181px" : "213px",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            scrollMarginTop: "118px",
            padding: "40px"
        }
    }
))

const InnerWrapper = styled("div")(() => (
    {
        display: "flex",
        flexDirection: "column",
        flex: "0 1 800px"
    }
))

const PageTitle = styled("h1")(({ theme }) => (
    {
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: "30px",
        color: "#2D2D2D",
        textAlign: "center",
        margin: "unset",
        [theme.breakpoints.down(midSizeBreakpoint)]: {
            fontSize: "28px"
        }
    }
))

const TextParagraph = styled("p")(({ theme }) => (
    {
        fontFamily: "Rubik",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "32px",
        color: "#2D2D2D",
        textAlign: "center",
        margin: "8px 0",
        [theme.breakpoints.down(midSizeBreakpoint)]: {
            fontSize: "14px",
            lineHeight: "20px"
        },
        "& a": {
            color: "#2D2D2D",
            fontWeight: 500,
            textDecoration: "none"
        }
    }
))

const Grid = styled("div")(({ theme }) => (
    {
        display: "grid",
        justifyContent: "center",
        flex: "0 1 100%",
        gridTemplateColumns: "450px 450px 450px",
        gap: "20px",
        padding: "80px",
        backgroundColor: "#F8F8F8",
        [theme.breakpoints.down(midSizeBreakpoint)]: {
            gridTemplateColumns: "450px 450px",
            gap: "5px"
        },
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            gridTemplateColumns: "350px 350px",
            gap: "5px",
            padding: "40px 0"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            gridTemplateColumns: "repeat(2, 170px)",
            gap: "5px"
        }
    }
))

const GridItem = styled(Link)(({ backgroundimage, index, theme }) => (
    {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        borderRadius: "10px",
        height: "350px",
        backgroundImage: `url(${backgroundimage})`,
        backgroundSize: "cover",
        textDecoration: "none",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            height: "270px"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            height: index === 0 || index === 5 ? "200px" : "132px",
            gridColumn: index === 0 || index === 5 ? "span 2" : "span 1",
            justifyContent: index === 0 || index === 5 ? "center" : "flex-start"
        },
        "&:hover div": {
            backgroundColor: "#ef7830"
        }
    }
))

const GridLink = styled("div")(({ theme }) => (
    {
        backgroundColor: "#F09057",
        borderRadius: "4px",
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: "17px",
        letterSpacing: "0.5px",
        padding: "10px 16px",
        textTransform: "none",
        textDecoration: "none",
        color: "#2D2D2D",
        marginBottom: "16px",
        transition: "0.1s all ease-in",
        "&:hover": {
            backgroundColor: "#ef7830"
        },
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            fontSize: "11px",
            padding: "4px 10px",
            marginBottom: "10px",
            marginLeft: "10px"
        }
    }
))

const LinkWrapper = styled("div")(({ theme }) => (
    {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "30px",
        marginTop: "30px",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            gap: "10px"
        }
    }
))

const TextSectionLink = styled(Link)(({ theme }) => (
    {
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: "14px",
        letterSpacing: "0.5px",
        padding: "10px 16px",
        textTransform: "none",
        textDecoration: "none",
        color: "#2D2D2D",
        border: "1px solid #2D2D2D",
        transition: "0.1s all ease-in",
        "&:hover": {
            border: "1px solid #F09057"
        },
        [theme.breakpoints.down(midSizeBreakpoint)]: {
            fontSize: "14px",
            padding: "7px 14px"
        }
    }
))

const DownloadLink = styled(Link)(({ theme }) => (
    {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: "14px",
        letterSpacing: "0.5px",
        textDecoration: "none",
        color: "#2D2D2D",
        transition: "0.1s all ease-in",
        borderBottom: "1px solid transparent",
        paddingBottom: "4px",
        [theme.breakpoints.down(midSizeBreakpoint)]: {
            fontSize: "14px"
        },
        [theme.breakpoints.up(desktopBreakpoint)]: {
            position: "relative",
            "&:after": {
                content: "''",
                position: "absolute",
                width: "100%",
                transform: "scaleX(0)",
                height: "2px",
                bottom: 0,
                left: 0,
                backgroundColor: "#F09057",
                transformOrigin: "bottom right",
                transition: "transform 0.25s ease-out"
            },
            "&:hover:after": {
                transform: "scaleX(1)",
                transformOrigin: "bottom left"
            }
        }
    }
))

const FiftyFiftyWrapper = styled("div")(() => (
    {
        display: "flex",
        justifyContent: "center",
        flex: "0 0 100%",
        backgroundColor: "#F8F8F8"
    }
))

const FiftyFiftyShrinkWrapper = styled("div")(() => (
    {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        flex: "0 1 1600px"
    }
))

const FiftyFifty = styled("div")(({ theme, reverse, authorized }) => (
    {
        position: "relative",
        display: "flex",
        flex: "0 1 1920px",
        justifyContent: "center",
        flexDirection: !reverse ? "row" : "row-reverse",
        scrollMarginTop: !authorized ? "181px" : "213px",
        "&:nth-child(even)": {
            flexDirection: !reverse ? "row-reverse" : "row"
        },
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            scrollMarginTop: "118px",
            flexWrap: "wrap",
            padding: "30px 20px"
        }
    }
))

const FiftyFiftyItem = styled("div")(({ theme }) => (
    {
        display: "flex",
        flexWrap: "wrap",
        flex: "0 1 50%",
        justifyContent: "center",
        paddingLeft: "60px",
        paddingRight: "60px",
        [theme.breakpoints.down(midSizeBreakpoint)]: {
            paddingLeft: "20px",
            paddingRight: "20px"
        },
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            flex: "0 1 100%",
            paddingLeft: "unset",
            paddingRight: "unset"
        }
    }
))

const InfoWrapper = styled("div")(({ theme }) => (
    {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            flex: "0 0 100%"
        }
    }
))

const FiftyFiftyImage = styled("img")(({ theme }) => ({
    flex: "0 1 50%",
    maxWidth: "50%",
    aspectRatio: "1.66  / 1",
    [theme.breakpoints.down(mobileMenuBreakpoint)]: {
        flex: "0 0 100%",
        maxWidth: "100%",
        borderRadius: "4px",
        marginBottom: "16px"
    }
}))

const Title = styled("h2")(({ theme }) => (
    {
        margin: "unset",
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: "30px",
        color: "#2D2D2D",
        [theme.breakpoints.down(midSizeBreakpoint)]: {
            fontSize: "20px"
        }
    }
))

const Subtitle = styled("span")(({ theme }) => (
    {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: "14px",
        letterSpacing: "0.5px",
        color: "#2D2D2D",
        [theme.breakpoints.down(midSizeBreakpoint)]: {
            fontSize: "12px"
        }
    }
))

const Text = styled("p")(({ theme }) => (
    {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: "17px",
        [theme.breakpoints.down(midSizeBreakpoint)]: {
            fontSize: "14px"
        }
    }
))

const ItemList = styled("div")(() => (
    {
        display: "flex",
        flexWrap: "wrap",
        gap: "8px",
        paddingBottom: "16px"
    }
))

const ListItem = styled("div")(({ theme }) => (
    {
        display: "flex",
        alignItems: "center",
        flex: "0 0 100%",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: "17px",
        [theme.breakpoints.down(1400)]: {
            fontSize: "14px"
        }
    }
))

const BulletWrapper = styled("div")(({ theme }) => (
    {
        display: "flex",
        flex: "0 0 15px",
        height: "100%",
        [theme.breakpoints.down(1400)]: {
            flex: "0 0 12px",
            width: "12px"
        }
    }
))

const ListBullet = styled("div")(({ theme }) => (
    {
        flex: "0 0 15px",
        height: "15px",
        width: "15px",
        backgroundColor: "#F09057",
        marginTop: "2px",
        borderRadius: "1px",
        [theme.breakpoints.down(1400)]: {
            flex: "0 0 12px",
            height: "12px",
            width: "12px"
        }
    }
))

const ListItemLabel = styled("span")(({ theme }) => (
    {
        marginLeft: "20px",
        [theme.breakpoints.down(1400)]: {
            marginLeft: "15px"
        }
    }
))

const InfoLinkWrapper = styled("div")(() => (
    {
        marginTop: "10px"
    }
))

const InfoLink = styled(Link)(({ theme }) => (
    {
        backgroundColor: "#F09057",
        borderRadius: "4px",
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: "14px",
        letterSpacing: "0.5px",
        padding: "10px 16px",
        textTransform: "none",
        textDecoration: "none",
        color: "#2D2D2D",
        marginBottom: "16px",
        transition: "0.1s all ease-in",
        "&:hover": {
            backgroundColor: "#ef7830"
        },
        [theme.breakpoints.down(midSizeBreakpoint)]: {
            fontSize: "14px"
        }
    }
))

const Video = styled("video")(({ theme }) => (
    {
        flex: "0 0 100%",
        maxWidth: "100%",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            flex: "0 1 100%",
            maxWidth: "100%",
            marginBottom: "16px"
        }
    }
))

const VideoWrapper = styled("div")(({ theme }) => (
    {
        position: "relative",
        display: "flex",
        flex: "0 0 50%",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            flex: "0 1 100%"
        }
    }
))

const PlayButton = styled("button")(({ theme }) => (
    {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        border: 0,
        backgroundColor: "#f09057",
        transition: "0.1s background ease-in",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ef7830"
        },
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            display: "none"
        }
    }
))

const CategoriesPage = () => {
    const [data, setData] = useState()
    const [videoPlaying, setVideoPlaying] = useState(false)
    const videoRef = useRef(null)
    const language = useLanguage()
    const isMobile = useMediaQuery(`(max-width:${mobileBreakpoint}px)`)
    const isAuthorized = useIsUserAuthorized()

    const fetchData = async () => {
        const response = await fetch(`/files/DynamicPages/CategoriesPage-${language}.json`)
        return response.json()
    }

    const refreshData = () => {
        fetchData().then(response => setData(response))
    }

    useEffect(() => {
        refreshData()
    }, [language])

    useEffect(() => {
        const hash = window.location.hash.substring(1)
        const element = document.getElementById(hash)

        if (element) {
            element.scrollIntoView({
                block: "start",
                behavior: "smooth"
            })
        }
    }, [data])

    const findAndScrollToElementById = id => {
        const element = document.getElementById(id)

        element.scrollIntoView({
            block: "start",
            behavior: "smooth"
        })
    }

    const renderStickyMenu = (menu, index) => (
        <StickyMenu authorized={isAuthorized ? "true" : undefined} key={`${menu.type}-${index}`} language={language}>
            {menu.items.map((item, i) => {
                if (i < menu.items.length - 1) {
                    return (
                        <React.Fragment key={`menu-${i}-${item.text}`}>
                            <MenuLink to="#" onClick={() => findAndScrollToElementById(item.targetId)}>{item.text}</MenuLink>
                            <Separator />
                        </React.Fragment>
                    )
                }
                return <MenuLink key={`menu-${index}-${item.text}`} to="#" onClick={() => findAndScrollToElementById(item.targetId)}>{item.text}</MenuLink>
            })}
        </StickyMenu>
    )

    const renderGridItem = (item, index) => (
        <GridItem onClick={() => findAndScrollToElementById(item.targetId)} key={`griditem-${item.title}`} backgroundimage={`/files/ImagesStatic/Category/grid${isMobile ? "/mobile" : ""}/${item.image}`} index={index}>
            <GridLink src="#">{item.title}</GridLink>
        </GridItem>
    )

    const renderGrid = (grid, index) => (
        <Grid key={`grid-${index}`}>
            {grid.items.map((item, i) => renderGridItem(item, i))}
        </Grid>
    )

    const renderTextSectionWithLinks = (textSection, index) => (
        <TextSection authorized={isAuthorized ? "true" : undefined} id={textSection.id} key={`ts-${index}`}>
            <InnerWrapper>
                <PageTitle>{textSection.title}</PageTitle>
                <TextParagraph dangerouslySetInnerHTML={{ __html: textSection.text }} />
                {textSection.links
                    && (
                        <LinkWrapper>
                            {textSection.links.map(
                                link => (
                                    <TextSectionLink
                                        onClick={() => findAndScrollToElementById(link.targetId)}
                                        key={`${textSection.title}-${link.title}`}
                                        to="#"
                                    >
                                        {link.title}
                                    </TextSectionLink>
                                )
                            )}
                        </LinkWrapper>
                    )}
                {textSection.downloadLink && (
                    <LinkWrapper>
                        <DownloadLink target="_blank" to={textSection.downloadLink.url}>
                            {textSection.downloadLink.text}
                            <img loading="lazy" src={DownloadIcon} alt="download icon" />
                        </DownloadLink>
                    </LinkWrapper>
                )}
            </InnerWrapper>
        </TextSection>
    )

    const renderFiftyFifty = (item, reverse, index) => (
        <FiftyFifty authorized={isAuthorized ? "true" : undefined} reverse={reverse ? "true" : undefined} id={item.id} key={`${index}-${item.id}`}>
            {item.embedVideoUrl
                ? (
                    <VideoWrapper>
                        <Video
                            onPlay={() => setVideoPlaying(true)}
                            onPause={() => setVideoPlaying(false)}
                            onEnded={() => { videoRef.current.currentTime = 0 }}
                            ref={videoRef}
                            controls
                            loading="lazy"
                            src={`/files/ImagesStatic/Category/${item.embedVideoUrl}`}
                        />
                        {!videoPlaying
                            && (
                                <PlayButton
                                    id="playpause"
                                    type="button"
                                    data-state="play"
                                    onClick={() => videoRef.current.play()}
                                >
                                    <PlayArrowIcon />
                                </PlayButton>
                            )}
                    </VideoWrapper>
                )
                : <FiftyFiftyImage loading="lazy" src={`/files/ImagesStatic/Category/${item.image}`} alt={item.title} />}
            <FiftyFiftyItem>
                <InfoWrapper>
                    <Subtitle>{item.subtitle}</Subtitle>
                    <Title>{item.title}</Title>
                    <Text>
                        {item.text}
                    </Text>
                    <ItemList>
                        {item.usps.map(usp => (
                            <ListItem key={`${item.title}-${usp}`}>
                                <BulletWrapper>
                                    <ListBullet />
                                </BulletWrapper>
                                <ListItemLabel>{usp}</ListItemLabel>
                            </ListItem>
                        ))}
                    </ItemList>
                    {item.link && <InfoLinkWrapper><InfoLink to={item.link.url}>{item.link.text}</InfoLink></InfoLinkWrapper>}
                </InfoWrapper>
            </FiftyFiftyItem>
        </FiftyFifty>
    )

    const renderComponent = (component, i) => {
        switch (component.type) {
            case "stickyMenu":
                return renderStickyMenu(component, i)
            case "grid":
                return renderGrid(component, i)
            case "textSection":
                return renderTextSectionWithLinks(component, i)
            case "fiftyFifty":
                return (
                    <FiftyFiftyWrapper key={`${component.type}-${i}`}>
                        <FiftyFiftyShrinkWrapper>
                            {component.items.map((item, index) => renderFiftyFifty(item, component.reverse, index))}
                        </FiftyFiftyShrinkWrapper>
                    </FiftyFiftyWrapper>
                )
            default:
                return <div>Unknown component</div>
        }
    }

    return (
        <Root>
            <ScrollTopButton />
            <PageWrapper>
                <HeroWrapper>
                    <HeroPicture fetchpriority="high">
                        <source media="(max-width: 769px)" srcSet="/files/ImagesStatic/Category/hero/hero-mob.jpg" />
                        <HeroImage id="hero" src="/files/ImagesStatic/Category/hero/hero-desktop.jpg" alt="Tablet folding keyboard" />
                    </HeroPicture>
                </HeroWrapper>
                {data && data.map((component, i) => renderComponent(component, i))}
            </PageWrapper>
        </Root>
    )
}

export default CategoriesPage
