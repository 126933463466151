import { tabletBreakpoint } from "../../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    menuItem: {
        display: "flex",
        justifyContent: "flex-end",
        // backgroundColor: '#fff',
        "&.active": {
            "& .MuiListItemIcon-root": {
                color: "#fff"
            }
        },
        "&:hover": {
            backgroundColor: "#f3f3f3"
        }
    },
    menuItemIcon: {
        color: "#97c05c"
    },
    button: {
        justifyContent: "flex-start",
        color: "#404040"
    },
    icon: {
        display: "inline-block",
        transform: "rotate(-90deg)"
    },
    openTransform: {
        transform: "rotate(-90deg)"
    },
    categoryArea: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: "8px",
        width: "100%",
        [theme.breakpoints.up(tabletBreakpoint)]: {
            justifyContent: "flex-start"
        }
    },
    catalog: {
        fontFamily: "Arial",
        fontWeight: "400",
        fontSize: "14px"
    },
    bold: {
        fontWeight: "bold!important"
    },
    hidden: {
        visibility: "hidden"
    }
}))
