import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    wrapper: {
        display: "flex",
        alignItems: "center"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    infoArea: {
        marginBottom: 50
    },
    listArea: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2)
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column"
    }
}))
