import { midSizeBreakpoint, mobileBreakpoint } from "../../lib/helper/viewportHelper"
import { strings as Localization } from "../../lib/Localization"
import { useLanguage } from "../../lib/hooks/languageHooks"
import React, { useState, useEffect } from "react"
import {
    useTheme, useMediaQuery,
    Autocomplete, TextField
} from "@mui/material"
import { styled } from "@mui/styles"

const PageWrapper = styled("div")(({ theme }) => (
    {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#f8f8f8",
        [theme.breakpoints.down(midSizeBreakpoint)]: {
            paddingLeft: "20px",
            paddingRight: "20px"
        }
    }
))

const Content = styled("div")(({ theme }) => (
    {
        display: "flex",
        flex: "0 1 1260px",
        backgroundColor: "#F8F8F8",
        flexWrap: "wrap",
        justifyContent: "center",
        paddingTop: "80px",
        paddingBottom: "80px",
        [theme.breakpoints.down(midSizeBreakpoint)]: {
            paddingTop: "20px",
            paddingBottom: "20px"
        }
    }
))

const TitleWrapper = styled("div")(({ theme }) => (
    {
        display: "flex",
        flexWrap: "wrap",
        flex: "0 1 1260px"
    }
))

const Title = styled("h1")(({ theme }) => (
    {
        flex: "0 1 1260px",
        fontFamily: "Rubik",
        fontSize: "30px",
        fontWeight: 500,
        lineHeight: "40px",
        textAlign: "left",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            fontSize: "20px",
            textAlign: "center"
        }
    }
))

const Dropdown = styled(Autocomplete)(({ theme }) => (
    {
        flex: "0 1 300px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            flex: "0 1 100%"
        }
    }
))

const Grid = styled("div")(({ theme }) => (
    {
        flex: "0 1 100%",
        display: "grid",
        gridTemplateColumns: "400px 400px 400px",
        gap: "30px",
        marginTop: "30px",
        marginBottom: "30px",
        [theme.breakpoints.down(midSizeBreakpoint)]: {
            gridTemplateColumns: "400px 400px"
        },
        [theme.breakpoints.down(900)]: {
            gridTemplateColumns: "1fr 1fr"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            gridTemplateColumns: "1fr"
        }
    }
))

const EComGrid = styled(Grid)(({ theme }) => (
    {
        margin: 0
    }
))

const GridItem = styled("div")(({ theme }) => (
    {
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        boxShadow: "0px 0px 20px 0px #2D2D2D1A",
        padding: "30px"
    }
))

const GridItemTextRow = styled("div")(({ theme }) => (
    {
        fontFamily: "Arial",
        fontSize: "17px",
        fontWeight: 400,
        letterSpacing: "0.5px",
        color: "#2D2D2D",
        marginBottom: "5px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            fontSize: "14px"
        }
    }
))

const GridItemPhone = styled("div")(({ theme }) => (
    {
        fontFamily: "Arial",
        fontSize: "16px",
        fontWeight: 700,
        letterSpacing: "0.5px",
        color: "#2D2D2D",
        marginTop: "10px",
        marginBottom: "8px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            fontSize: "14px"
        }
    }
))

const GridItemLink = styled("a")(({ theme }) => (
    {
        position: "relative",
        fontFamily: "Arial",
        fontSize: "16px",
        fontWeight: 700,
        letterSpacing: "0.5px",
        color: "#2D2D2D",
        textDecoration: "none",
        paddingBottom: "2px",
        "&:after": {
            content: "''",
            position: "absolute",
            width: "100%",
            transform: "scaleX(0)",
            height: "2px",
            bottom: 0,
            left: 0,
            backgroundColor: "#F09057",
            transformOrigin: "bottom right",
            transition: "transform 0.25s ease-out"
        },
        "&:hover:after": {
            transform: "scaleX(1)",
            transformOrigin: "bottom left"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            fontSize: "14px"
        }
    }
))

const RetailersPage = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint))
    const language = useLanguage()
    const [dealersCountry, setDealersCountry] = useState([])
    const [countries, setCountries] = useState([])
    const [selectedCountry, setSelectedCountry] = useState("")

    const [dealersCity, setDealersCity] = useState([])
    const [cities, setCities] = useState([])
    const [selectedCity, setSelectedCity] = useState("")
    const [webshopDealers, setWebshopDealers] = useState([])

    const fetchDealerByCountry = async () => {
        const response = await fetch("/files/DynamicPages/DealersCountry.json")
        return response.json()
    }

    const fetchDealerByCity = async () => {
        const response = await fetch("/files/DynamicPages/DealersCity.json")
        return response.json()
    }

    const renderGridItem = (name, address, phone, url) => (
        <GridItem>
            <GridItemTextRow>{name}</GridItemTextRow>
            <GridItemTextRow>{address}</GridItemTextRow>
            <GridItemPhone>{phone}</GridItemPhone>
            <GridItemLink href={`//${url}`} target="_blank">{url}</GridItemLink>
        </GridItem>
    )

    useEffect(() => {
        fetchDealerByCountry().then(result => {
            setDealersCountry(result)
            setCountries(result.map(item => item.country))
        })

        fetchDealerByCity().then(result => {
            setDealersCity(result)
            setCities(result.filter(item => item.city !== "").map(item => item.city))
            setWebshopDealers(result.flatMap(city => city.dealers.filter(dealer => dealer.webShop !== "")))
        })
    }, [language])

    return (
        <PageWrapper>
            <Content>
                <TitleWrapper>
                    <Title>{Localization.findLocalRetailer}</Title>
                    {language === "en"
                        ? (
                            <Dropdown
                                onChange={(e, val) => setSelectedCountry(val)}
                                options={countries}
                                value={selectedCountry}
                                renderInput={params => (
                                    <TextField {...params} variant="standard" />
                                )}
                            />
                        )
                        : (
                            <Dropdown
                                onChange={(e, val) => setSelectedCity(val)}
                                options={cities.sort()}
                                value={selectedCity}
                                renderInput={params => (
                                    <TextField {...params} variant="standard" />
                                )}
                            />
                        )}
                </TitleWrapper>
                <Grid>
                    {language === "en" && selectedCountry
                        && dealersCountry
                            .find(item => item.country === selectedCountry).dealers
                            .map(dealer => renderGridItem(dealer.Company, selectedCountry, dealer.Phone, dealer.Webpage))}

                    {language === "sv" && selectedCity
                        && dealersCity
                            .find(item => item.city === selectedCity).dealers
                            .map(dealer => renderGridItem(
                                dealer.commonName ? dealer.commonName : dealer.organization,
                                dealer.visitAddress ? dealer.visitAddress : `${dealer.postalAddress}, ${dealer.zip} ${selectedCity}`,
                                dealer.phone,
                                dealer.website
                            ))}
                </Grid>
                {language === "sv" && webshopDealers && (
                    <>
                        <TitleWrapper>
                            <Title>{Localization.shopOnline}</Title>
                        </TitleWrapper>
                        <EComGrid>
                            {webshopDealers.map(dealer => renderGridItem(
                                dealer.commonName ? dealer.commonName : dealer.organization,
                                null,
                                dealer.phone,
                                dealer.website
                            ))}
                        </EComGrid>
                    </>
                )}
            </Content>
        </PageWrapper>
    )
}
export default RetailersPage
