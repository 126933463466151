import { modelNameCompare } from "../../lib/helper/modelHelper";
import { createEntityAdapter } from "@reduxjs/toolkit";

export const modelAdapter = createEntityAdapter({
    selectId: model => model.id,
    sortComparer: (a, b) => modelNameCompare(a.name, b.name)
});

export const modelSelectors = modelAdapter.getSelectors(state => state.model);

export const { selectById, selectAll } = modelSelectors;
