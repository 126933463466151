import { tabletBreakpoint } from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    userPage: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        flexWrap: "wrap"
    },
    contentWrapper: {
        display: "flex",
        flex: "0 1 1920px",
        fontFamily: "Arial",
        padding: "20px 40px",
        gap: "20px"
    },
    left: {
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        flex: "0 0 60%",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            flex: "0 0 100%"
        }
    },
    right: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flex: "1 1 0px",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            display: "none"
        }
    },
    pageTitle: {
        fontSize: "24px"
    },
    withdrawConsent: {
        flex: "0 1 100%"
    },
    image: {
        flex: "1 1 0px",
        width: "0px",
        borderRadius: "5px"
    }
}))
