import { makeStyles } from "@mui/styles"

const underlineColor = "#7c7c7c"

export default makeStyles(() => ({
    input: {
        textTransform: "uppercase",
        "& .MuiInputBase-root": {
            maxHeight: "16px!important"
        },
        "& .MuiAutocomplete-clearIndicator": {
            display: "none"
        }
    },
    textField: {
        "& .MuiInputBase-root": {
            maxHeight: "16px!important"
        },
        "& input::placeholder": {
            fontSize: "15px"
        },
        // input label when focused
        "& label.Mui-focused": {
            color: underlineColor
        },
        // focused color for input with variant='standard'
        "& .MuiInput-underline:after": {
            borderBottomColor: underlineColor
        },
        // focused color for input with variant='filled'
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: underlineColor
        },
        // focused color for input with variant='outlined'
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: underlineColor
            }
        },
        "& .MuiInput-root": {
            paddingBottom: "5px!important"
        },
        "& .MuiAutocomplete-popupIndicator": {
            marginRight: "-5px!important"
        },
        "& .MuiAutocomplete-inputRoot": {
            flexWrap: "nowrap",
            paddingRight: "0px!important",
            color: "#2D2D2D!important",
            leadingTrim: "both!important",
            textEdge: "cap!important",
            fontFamily: "Arial!important",
            fontSize: "15px!important",
            fontStyle: "normal!important",
            fontWeight: "400!important",
            lineHeight: "20px!important", /* 133.333% */
            letterSpacing: "0.25px!important"
        }
    }
}))
