import {
    desktopBreakpoint, mobileBreakpoint, mobileMenuBreakpoint, tabletBreakpoint
} from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

const menuWidth = "fit-content"

export default makeStyles(theme => ({
    appBar: {
        boxShadow: "none!important",
        width: "auto",
        maxWidth: "100%",
        [theme.breakpoints.up(mobileBreakpoint)]: {
            marginLeft: 0
        },
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            width: "100%"
        },
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    topBox: {
        display: "flex",
        justifyContent: "center",
        padding: "5px 20px",
        gap: "20px",
        background: "#F8F8F8"
    },
    topBoxTextWrapper: {
        flex: "0 1 1920px",
        height: "18px",
        display: "flex",
        justifyContent: "flex-end",
        columnGap: "16px",
        alignItems: "center"
    },
    topBoxText: {
        color: "#2D2D2D",
        fontFamily: "Arial",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "18px" /* 128.571% */
    },
    image: {
        height: "99px",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            width: "121px",
            height: "64px"
        }
    },
    removeButtonStandardStyling: {
        [theme.breakpoints.down(desktopBreakpoint)]: {
            minWidth: "0px!important",
            padding: "0px!important",
            maxWidth: 28,
            maxHeight: 28,
            "& .MuiButton-endIcon": {
                [theme.breakpoints.down(desktopBreakpoint)]: {
                    marginLeft: "0px!important",
                    marginRight: "0px!import"
                }
            }
        }
    },
    productButton: {
        display: "flex",
        alignItems: "center",
        color: "var(--black, #2D2D2D)",
        fontFamily: "Arial",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "20px", /* 133.333% */
        letterSpacing: "0.25px",
        textTransform: "none!important",
        border: 0,
        backgroundColor: "transparent",
        cursor: "pointer"
    },
    mobileMenuButton: {
        justifyContent: "flex-start",
        display: "flex"
    },
    iconWrapper: {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "row"
    },
    loggedIn: {
        display: "flex",
        paddingRight: "0px",
        alignItems: "flex-end",
        gap: "10px"
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column"
    },
    desktopSearchAreaBox: {
        display: "flex",
        paddingRight: "0px",
        alignItems: "center",
        gap: "29px"
    },
    flexWrap: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    toolbar: {
        display: "flex",
        flex: "0 1 100%",
        justifyContent: "center",
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: "#FFFFFF",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            justifyContent: "space-between",
            paddingTop: 15,
            paddingBottom: 15
        }
    },
    logo: {
        display: "flex",
        paddingLeft: "0px",
        paddingTop: "5px",
        paddingBottom: "5px",
        flexDirection: "column",
        gap: "10px",
        marginLeft: "47"
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: menuWidth,
        color: "#404040",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            width: "auto"
        }
    },
    badgePos: {
        transform: "scale(1) translate(60%, 0%)"
    },
    badge: {
        height: "20px",
        minWidth: "20px",
        borderRadius: "50%",
        padding: 0,
        color: "#FFF"
    },
    leftRightFilter: {
        width: 75,
        height: 60,
        paddingLeft: 4,
        marginTop: 4
    },
    flexRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "40px"
    },
    searchArea: {
        display: "flex",
        flexDirection: "row",
        gap: 40,
        [theme.breakpoints.down(desktopBreakpoint)]: {
            alignItems: "center",
            gap: 16
        }
    },
    mobileSearchArea: {
        display: "flex",
        flexDirection: "column",
        gap: 20,
        alignItems: "flex-end"
    },
    flag: {
        width: 38,
        height: 38
    },
    icon: {
        display: "inline-block",
        pointerEvents: "none"
    },
    openTransform: {
        transform: "rotate(180deg)"
    },
    hoverUnderline: {
        display: "inline-block",
        position: "relative",
        "&:after": {
            content: "''",
            position: "absolute",
            width: "100%",
            transform: "scaleX(0)",
            height: "2px",
            bottom: 0,
            left: 0,
            backgroundColor: "#F09057",
            transformOrigin: "bottom right",
            transition: "transform 0.25s ease-out"
        },
        "&:hover:after": {
            transform: "scaleX(1)",
            transformOrigin: "bottom left"
        }
    },
    iconButton: {
        "&.MuiButton-root": {
            minWidth: 0
        }
    },
    loginIcon: {
        marginLeft: 5
    },
    slider: {
        display: "flex",
        // position: "absolute",
        left: 0,
        right: 0,
        transition: "all 0.2s ease-in-out"
    },
    slideItem: {
        flex: "0 0 100%"
    },
    slideContent: {
        display: "flex",
        flexDirection: "column",
        flex: "0 0 100%",
        justifyContent: "flex-end"
    },
    topBar: {
        display: "flex",
        padding: "20px",
        justifyContent: "flex-end",
        fontFamily: "Arial",
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "18px",
        textAlign: "left"
    },
    closeButton: {
        display: "flex",
        alignItems: "center",
        appearance: "none",
        gap: "5px",
        border: 0,
        backgroundColor: "transparent",
        cursor: "pointer"
    },
    loginButton: {
        display: "flex",
        padding: "10px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "4px",
        backgroundColor: "#F09057",
        color: "#2D2D2D",
        /* M3/label/medium */
        fontFamily: "Arial",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "16px", /* 114.286% */
        letterSpacing: "0.5px",
        transition: "all 0.1s ease-in",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#ef7830"
        }
    },
    innerToolbarWrapper: {
        display: "flex",
        justifyContent: "space-between",
        flex: "0 1 1920px",
        paddingLeft: "16px",
        paddingRight: "16px",
        alignItems: "center"
    },
    plainButton: {
        display: "flex"
    },
    navWrapper: {
        display: "flex",
        flex: "0 1 1920px",
        justifyContent: "space-between",
        alignItems: "center"
    }
}))
