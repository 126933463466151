import { createSlice } from "@reduxjs/toolkit"

export const favoriteSlice = createSlice({
    name: "favorite",
    initialState: {
        favoriteProducts: [],
        shouldSync: false
    },
    reducers: {
        setAllFavoriteProducts: (state, action) => {
            state.favoriteProducts = action.payload
            state.shouldSync = true
        },
        addFavoriteProduct: (state, action) => {
            state.favoriteProducts.push(action.payload)
            state.shouldSync = true
        },
        removeFavoriteProduct: (state, action) => {
            state.favoriteProducts = state.favoriteProducts.filter(
                product => product.productId !== action.payload
            )
            state.shouldSync = true
        },
        setShouldSync: (state, action) => {
            state.shouldSync = action.payload
        },
        removeFavoriteList: (state, action) => {
            state.favoriteProducts = state.favoriteProducts.filter(
                product => product.listName !== action.payload
            )
            state.shouldSync = true
        }
    }
})

export default favoriteSlice.reducer

const {
    setAllFavoriteProducts, addFavoriteProduct, removeFavoriteProduct, setShouldSync, removeFavoriteList
} = favoriteSlice.actions

export const addToFavoriteProducts = productId => dispatch => dispatch(
    addFavoriteProduct(productId)
)

export const removeFromFavoriteProducts = productId => dispatch => dispatch(
    removeFavoriteProduct(productId)
)

export const setFavoriteProducts = productIds => dispatch => dispatch(
    setAllFavoriteProducts(productIds)
)

export const setShouldSyncFavorites = shouldSync => dispatch => dispatch(
    setShouldSync(shouldSync)
)

export const deleteFavoriteList = listName => dispatch => dispatch(
    removeFavoriteList(listName)
)
