/* eslint-disable import/no-cycle */
import useStyles from "./styles"
import { GridTitle, ListTitle, ModTekTitle } from "./ProductTitle"
import { GridPrice, ListPrice, ModTekPrice } from "./ProductPrice"
import { GridProductNumber, ListProductNumber } from "./ProductNumber"
import { GridImage, ListImage, ModTekImage } from "./ProductImage"
import { GridButtons, ListButtons } from "./ProductButtons"
import { GridDescription, ListDescription, ModTekDescription } from "./ProductDescription"
import SearchDealerLink from "../../../SearchDealer/SearchDealerLink"
import SpeedOrder from "../../../SpeedOrder/SpeedOrder"
import CompatibleModels from "../../ProductInfo/components/CompatibleModels/CompatibleModels"
import theme from "../../../../theme"
import { productViewBreakpoint } from "../../../../lib/helper/viewportHelper"
import { useUserData } from "../../../../redux/hook/userHooks"
import {
    Box,
    Card,
    CardContent,
    Paper,
    Typography,
    useMediaQuery
} from "@mui/material"
import classNames from "classnames"
import React, { useEffect } from "react"
import { NavLink } from "react-router-dom"

function ProductCardTemplate(
    {
        product,
        handleShowMoreCompsClick,
        listView,
        grid,
        onSelect,
        restorationRef,
        backGroundColor,
        compare,
        price,
        linkToProduct,
        displayPrice = false,
        showSpeedOrder = false,
        showShare = true,
        showCompare = true,
        showFavorite = true,
        showReport = true,
        showSearchDealerLink = false,
        productInfo,
        modTek = {},
        modTekInformation = "",
        altNameList = []
    }
) {
    const classes = useStyles()
    const userData = useUserData()
    const isSmall = useMediaQuery(theme.breakpoints.down(productViewBreakpoint))
    let cartContentMinHeight = listView ? 250 : 415
    let cardMaxWidth = grid ? 400 : "auto"
    if (compare && isSmall) cardMaxWidth = 300
    if (productInfo) cartContentMinHeight = "auto"

    useEffect(() => {
        if (!restorationRef) return

        restorationRef.current.scrollIntoView({ behavior: "auto", block: "center" })
    })

    return (
        <Box
            id={`productCard-${product?.id}`}
            className={classes.productCard}
            sx={{ maxWidth: cardMaxWidth, justifyContent: Object.keys(modTek).length > 0 ? "flex-start" : "center" }}
        >
            {(grid && Object.keys(modTek).length === 0)
                && (
                    <Card
                        component={Box}
                        id="card"
                        className={classes.cardRoot}
                        sx={{
                            backgroundColor: `${backGroundColor}`
                        }}
                    >
                        <GridImage
                            phasingOut={displayPrice
                                ? price.phasingOut
                                : false}
                            product={product}
                            grid={grid}
                            listView={listView}
                            onSelect={onSelect}
                        />
                        <CardContent
                            component={Box}
                            id="cardContent"
                            className={classes.cardContent}
                            sx={{
                                minHeight: cartContentMinHeight
                            }}
                        >
                            <Box className={classes.flexRow}>
                                <Box
                                    className={classNames(
                                        classes.flexColumn,
                                        !listView && classes.spacingTop
                                    )}
                                    sx={!listView && !product?.description
                                        ? { justifyContent: "center" }
                                        : null}
                                >
                                    <GridTitle
                                        listView={listView}
                                        to={linkToProduct}
                                        onClick={() => onSelect(product?.id)}
                                        product={product}
                                        altNameList={altNameList}
                                    />
                                    <GridDescription
                                        description={product?.description}
                                    />
                                    <CompatibleModels
                                        compatibleModels={product?.compatibleModels}
                                        handleShowMoreCompsClick={handleShowMoreCompsClick}
                                    />
                                    <GridDescription
                                        modTekInformation={modTekInformation}
                                    />
                                </Box>
                            </Box>
                            <Box className={classes.flexBox}>
                                <Box>
                                    <GridPrice
                                        price={price}
                                        displayPrice={displayPrice}
                                    />
                                </Box>
                            </Box>
                            <Box className={classes.flexBox}>
                                <Box className={classes.paddingBottom8}>
                                    {
                                        showSpeedOrder
                                        && <SpeedOrder articleNumber={product?.originalId} />
                                    }
                                    {
                                        showSearchDealerLink
                                        && <SearchDealerLink />
                                    }
                                </Box>
                            </Box>
                            <Box className={classes.cardFooter}>
                                <Box>
                                    <GridProductNumber originalId={product?.originalId} />
                                </Box>
                                <Box>
                                    <Box className={classes.gridButtons}>
                                        <GridButtons
                                            product={product}
                                            compare={compare}
                                            showShare={showShare}
                                            showCompare={showCompare}
                                            showFavorite={showFavorite}
                                            showReport={showReport}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                )}
            {(!grid && Object.keys(modTek).length === 0) && (
                <Paper className={classes.paperRoot}>
                    <Box className={classes.paperBox}>
                        <Box className={classes.listImage}>
                            <ListImage
                                product={product}
                                onSelect={onSelect}
                                phasingOut={displayPrice ? price.phasingOut : false}
                            />
                        </Box>
                        <Box className={classes.listProductNumber}>
                            <ListProductNumber originalId={product?.originalId} />
                        </Box>
                        <Box className={classes.productText}>
                            <NavLink
                                to={linkToProduct}
                                className={classes.noDisplayLink}
                                onClick={() => onSelect(product?.id)}
                                end
                            >
                                <ListTitle
                                    name={product?.name}
                                    product={product}
                                    altNameList={altNameList}
                                />
                                <ListDescription
                                    description={product?.description}
                                />
                                <CompatibleModels
                                    compatibleModels={product?.compatibleModels}
                                    handleShowMoreCompsClick={null}
                                />
                                <ListDescription
                                    modTekInformation={modTekInformation}
                                />
                            </NavLink>
                        </Box>
                        <Box className={classes.buttonBox}>
                            <Box>
                                <ListPrice
                                    price={price}
                                    displayPrice={displayPrice}
                                />
                                {
                                    showSpeedOrder
                                    && <SpeedOrder articleNumber={product?.originalId} />
                                }
                            </Box>
                            <Box className={classes.flexEnd}>
                                <ListButtons
                                    product={product}
                                    compare={compare}
                                    showShare={showShare}
                                    showCompare={showCompare}
                                    showFavorite={showFavorite}
                                    showReport={showReport}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            )}
            {Object.keys(modTek).length > 0 && (
                <Card
                    component={Box}
                    id="miniCard"
                    sx={{
                        backgroundColor: `${backGroundColor}`,
                        display: "flex",
                        flexDirection: "column",
                        margin: "0.25em",
                        padding: "16px",
                        width: "280px"
                    }}
                >
                    <Box display="flex">
                        <ModTekImage
                            maxWidth="200px"
                            flex="1"
                            maxHeight="120px"
                            phasingOut={displayPrice
                                ? price.phasingOut
                                : false}
                            product={product}
                            grid={grid}
                            listView={listView}
                            onSelect={onSelect}
                        />
                        <CardContent
                            flex="1"
                            component={Box}
                            id="cardContentMini"
                            className={classes.cardContentMini}
                        >
                            <Box className={classes.flexRowMini}>
                                <Box
                                    className={classNames(
                                        classes.flexColumn,
                                        !listView
                                    )}
                                    sx={{ maxHeight: "140px", overflowY: "auto" }}
                                >
                                    <NavLink
                                        to={linkToProduct}
                                        onClick={() => onSelect(product?.id)}
                                        className={classes.noDisplayLink}
                                        end
                                    >
                                        <Typography gutterBottom>
                                            #
                                            {product?.originalId}
                                        </Typography>
                                    </NavLink>

                                    <ModTekTitle
                                        listView={listView}
                                        to={linkToProduct}
                                        onClick={() => onSelect(product?.id)}
                                        product={product}
                                        altNameList={altNameList}
                                    />
                                    {
                                        modTekInformation.length > 0
                                        && (
                                            <ModTekDescription
                                                sideModule
                                                modTekInformation={modTekInformation}
                                            />
                                        )
                                    }
                                </Box>
                            </Box>
                        </CardContent>
                    </Box>
                    {
                        userData.customerNumber && (
                            <Box>
                                <Box style={{ display: "flex", flexDirection: "column" }}>
                                    <ModTekPrice
                                        price={price}
                                        displayPrice={displayPrice}
                                    />
                                    {
                                        showSpeedOrder
                                        && <SpeedOrder articleNumber={product?.originalId} />
                                    }
                                </Box>
                            </Box>
                        )
                    }

                </Card>
            )}
        </Box>
    )
}

export default ProductCardTemplate
