// Need to use the React-specific entry point to import createApi
import { addPrices } from "../priceSlice"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const priceApi = createApi({
    reducerPath: "priceApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api/ProductInfo/" }),
    endpoints: builder => ({
        getPrices: builder.query({
            query: ({ authToken, ids }) => ({
                url: "GetPrices",
                method: "POST",
                body: {
                    authToken,
                    ids
                }
            }),

            async onQueryStarted({ _authToken, _ids }, { dispatch, queryFulfilled }) {
                const { data: prices } = await queryFulfilled
                dispatch(addPrices(prices))
            }
        })
    })
})

export const { useGetPricesQuery } = priceApi
