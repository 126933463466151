import { makeStyles } from "@mui/styles"

export default makeStyles(() => ({
    slider: {
        display: "flex",
        left: 0,
        right: 0,
        transition: "all 0.2s ease-in-out"
    },
    slideItem: {
        flex: "0 0 100%"
    },
    slideContent: {
        display: "flex",
        flexDirection: "column",
        flex: "0 0 100%",
        justifyContent: "flex-end"
    },
    topBar: {
        display: "flex",
        padding: "20px",
        justifyContent: "flex-end",
        fontFamily: "Arial",
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "18px",
        textAlign: "left"
    },
    closeButton: {
        display: "flex",
        alignItems: "center",
        appearance: "none",
        gap: "5px",
        border: 0,
        backgroundColor: "transparent",
        cursor: "pointer",
        fontFamily: "Arial",
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "18px",
        textAlign: "left",
        color: "#2d2d2d"
    },
    forwardButton: {
        border: 0,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-end",
        background: "transparent",
        fontFamily: "Arial",
        fontSize: "17px",
        fontWeight: 700,
        lineHeight: "20px",
        letterSpacing: "0.25px",
        textAlign: "left",
        paddingTop: "7px",
        paddingBottom: "7px",
        paddingRight: "20px",
        cursor: "pointer",
        color: "#2D2D2D"
    },
    anchor: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: "7px",
        paddingBottom: "7px",
        paddingRight: "20px",
        fontFamily: "Arial",
        fontSize: "17px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.25px",
        textAlign: "left",
        color: "#2D2D2D",
        textDecoration: "none"
    },
    leftDrawerPaper: {
        width: "100%",
        maxWidth: "600px",
        overflowX: "hidden"
    },
    drawerRow: {
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "20px",
        paddingLeft: "20px",
        paddingTop: "5px",
        paddingBottom: "5px"
    },
    loginAnchor: {
        borderRadius: "4px",
        backgroundColor: "#F09057",
        border: 0,
        padding: "10px 14px 10px 14px",
        fontFamily: "Arial",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "16px",
        letterSpacing: "0.5px",
        textAlign: "left",
        textDecoration: "none",
        color: "#2D2D2D"
    }
}))
