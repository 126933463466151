import useStyles from "./VehicleNewsListPage/styles"
import Thumbnail from "../Thumbnail"
import { Typography } from "@mui/material"
import { Link } from "react-router-dom"
import React from "react"

function NewsItem({
    news, url, alt, isDevice = false, ...props
}) {
    const classes = useStyles()

    const getTitle = () => {
        let title = ""

        title += news.title ? news.title : ""
        title += news.year ? ` ${news.year}` : ""

        return title
    }

    const getSubtitle = () => {
        let subtitle = ""

        subtitle += news.placement ? news.placement : ""
        subtitle += news.products.length > 0 ? ` (${news.products[0]})` : ""

        return subtitle
    }

    return (
        <div className={classes.newsCard} {...props}>
            <Typography
                variant="h6"
                className={classes.publishedText}
            >
                {new Date(news.dateTime).toLocaleDateString()}
            </Typography>
            <Link className={classes.link} to={url}>
                <Thumbnail
                    href={url}
                    src={news.images?.[0]}
                    alt={alt}
                    className={classes.itemThumbnail}
                    width="100%"
                    srcWidth="1500"
                />
                <div className={classes.textWrapper}>
                    <div className={classes.titleWrapper}>
                        <div className={classes.title}>
                            {getTitle()}
                        </div>
                    </div>
                    {!isDevice && <div className={classes.subtitle}>{getSubtitle()}</div>}
                </div>
            </Link>
        </div>
    )
}
export default NewsItem
