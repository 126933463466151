import { priceAdapter } from "./adapter/priceAdapter";
import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "price",
    initialState: priceAdapter.getInitialState({
        errors: []
    }),
    reducers: {
        clearAll: state => {
            priceAdapter.removeAll(state);
        },
        clearErrors: state => ({
            ...state,
            errors: []
        }),
        add: (state, action) => {
            priceAdapter.addMany(state, action.payload);
        }
    }
});

export default slice.reducer;

const { clearAll, clearErrors, add } = slice.actions;

export const clearPrices = () => dispatch => dispatch(
    clearAll()
)

export const clearPriceErrors = () => dispatch => dispatch(
    clearErrors()
)

export const addPrices = prices => dispatch => dispatch(
    add(prices)
)
