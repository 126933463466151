/* eslint-disable jsx-a11y/control-has-associated-label */
import useStyles from "./styles"
import { checkMonitorArticles } from "../../lib/helper/userHelper"
import { useLanguage } from "../../lib/hooks/languageHooks"
import { strings as Localization } from "../../lib/Localization"
import { addToCart } from "../../redux/cartSlice"
import { setSnackbarMessage } from "../../redux/userMessageSlice"
import React, { useRef, useState } from "react"
import { useDispatch } from "react-redux"
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart"
import CloseIcon from "@mui/icons-material/Close"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import CircularProgress from "@mui/material/CircularProgress"
import WarningIcon from "@mui/icons-material/Warning"
import DoneIcon from "@mui/icons-material/Done"

const QuickShop = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const lang = useLanguage()
    const ref = useRef()
    const [isOpen, setIsOpen] = useState(false)
    const [quantity, setQuantity] = useState(null)
    const [sku, setSku] = useState("")
    const [addState, setAddState] = useState("ready")

    const parse = i => parseInt(i, 10)

    const increaseQuantity = () => {
        setQuantity(quantity + 1)
    }

    const decreaseQuantity = () => {
        if (quantity && quantity >= 0) {
            setQuantity(quantity - 1)
        } else {
            setQuantity(null)
        }
    }

    const setFail = () => {
        setAddState("error")
        setTimeout(() => setAddState("ready"), 1000)
    }

    const setSuccess = () => {
        setAddState("success")
        setQuantity(null)
        setSku("")
        ref.current?.focus()
        setTimeout(() => {
            setAddState("ready")
        }, 1000)
    }

    const onSubmit = e => {
        e.preventDefault()
        checkMonitorArticles(lang, [sku], result => {
            if (!result.includes(sku) && quantity > 0) {
                const q = parse(quantity)
                dispatch(addToCart(sku, q))
                dispatch(setSnackbarMessage(`${Localization.itemNumber} ${sku} ${Localization.hasBeenAddedToCart}`, "success"))
                setSuccess()
            } else if (quantity === 0) {
                dispatch(setSnackbarMessage(Localization.articleCouldNotBeAddedQuantityZero, "error"))
                setFail()
            } else {
                dispatch(setSnackbarMessage(`${Localization.itemNumber} ${sku} ${Localization.couldNotBePlacedInCart}`, "error"))
                setFail()
            }
        }, setAddState("loading"))
    }

    const icon = () => (isOpen ? <CloseIcon style={{ color: "#2D2D2D", width: "18px" }} /> : <AddShoppingCartIcon style={{ color: "#2D2D2D", width: "18px" }} />)

    const addIcon = () => {
        const iconStyle = { width: "16px", height: "16px" }

        switch (addState) {
            case "ready":
                return <ShoppingCartIcon style={iconStyle} />
            case "loading":
                return <CircularProgress style={iconStyle} />
            case "error":
                return <WarningIcon style={iconStyle} />
            case "success":
                return <DoneIcon style={iconStyle} />
            default:
                return <ShoppingCartIcon style={iconStyle} />
        }
    }

    const inputsDisabled = addState === "loading"

    return (
        <form action="#" onSubmit={onSubmit}>
            <div className={classes.quickshopWrapper}>
                <div className={`${classes.quickshopContent} ${isOpen && classes.open}`}>
                    <input ref={ref} required disabled={inputsDisabled} className={classes.quickshopInput} type="number" placeholder={Localization.itemNumber} value={sku} onChange={e => setSku(e.target.value)} />
                    <button tabIndex={-1} disabled={inputsDisabled || quantity <= 0} className={classes.roundButton} onClick={decreaseQuantity} type="button"><RemoveCircleIcon /></button>
                    <input required disabled={inputsDisabled} className={classes.quantityInput} width={20} min="0" type="number" placeholder="0" value={quantity !== null ? quantity : ""} onChange={e => setQuantity(e.target.value)} />
                    <button tabIndex={-1} disabled={inputsDisabled} className={classes.roundButton} onClick={increaseQuantity} type="button"><AddCircleIcon /></button>
                    <button tabIndex={-1} disabled={inputsDisabled || quantity <= 0 || !sku} type="submit" className={classes.submitButton}>
                        {addIcon()}
                    </button>
                </div>
                <button className={classes.quickshopButton} type="button" onClick={() => setIsOpen(!isOpen)}>{icon()}</button>
            </div>
        </form>
    )
}

export default QuickShop
