import useStyles from "./styles"
import { formatAmount, formatPrice } from "../../../lib/helper/numberHelper"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import ExternalLinkIcon from "../../../lib/icons/ExternalLink/ExternalLinkIcon"
import { strings as Localization } from "../../../lib/Localization"
import { useUser } from "../../../redux/hook/userHooks"
import MobileProductCardOrder from "../../Order/ProductCardOrder/MobileProductCardOrder"
import getStatusText from "../functions"
import {
    Box, Button, Link as SimpleLink, List, ListItem, Typography
} from "@mui/material"
import {
    ArrowBack, CheckCircleOutline, ConfirmationNumber,
    CreditCard, Notes, PersonOutline, Receipt, ThumbUp
} from "@mui/icons-material"
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn"
import LocalShippingIcon from "@mui/icons-material/LocalShipping"
import classnames from "classnames"
import React from "react"
import { Link } from "react-router-dom"

const MobileOrderInfo = ({ customerOrder, isOrderConfirmation }) => {
    const classes = useStyles()
    const lang = useLanguage()
    const user = useUser()

    const getDate = a => {
        const date = new Date(a)
        return date.toLocaleDateString()
    }

    const findComment = position => customerOrder.Rows.find(
        x => x.Position === position && x.Type === 4 && x.TextType === 7
    )?.Text

    return (
        <Box>
            {isOrderConfirmation ? (
                <>
                    <ThumbUp />
                    <Typography variant="h4" gutterBottom>{Localization.orderConfirmation}</Typography>
                </>
            ) : (
                <Box className={classes.flexRow}>
                    <Box>
                        <AssignmentTurnedInIcon />
                        <Typography variant="h4" className={classes.capitalized} gutterBottom>
                            {Localization.order}
                            {" "}
                            {customerOrder.OrderNumber}
                        </Typography>
                    </Box>
                    <Box className={classnames(classes.flexColumn, classes.flexEnd)}>
                        {getStatusText(customerOrder.Status)}
                    </Box>
                </Box>
            )}
            {isOrderConfirmation ? (
                <Box>
                    <Typography className={classes.capitalized}>
                        {Localization.thankYouForOrder}
                    </Typography>
                    <Typography variant="caption">{Localization.orderIsProcessing}</Typography>
                </Box>
            ) : (
                null
            )}
            <Box className={classnames(classes.flexRow, classes.spaceEven)}>
                <Box className={classes.flexColumn}>
                    <Box className={classnames(classes.flexColumn, classes.marginBottom)}>
                        {isOrderConfirmation ? (
                            <>
                                <CheckCircleOutline />
                                <Typography className={classes.capitalized} variant="caption">
                                    {Localization.checkOrderDetails}
                                </Typography>
                            </>
                        ) : null}
                    </Box>
                    <Box className={classnames(classes.flexColumn, classes.marginBottom)}>
                        <ConfirmationNumber />
                        <Typography>
                            {Localization.orderNumber}
                            :
                            {" "}
                            {customerOrder.OrderNumber}
                        </Typography>
                        <Typography>
                            {Localization.orderDate}
                            :
                            {" "}
                            {getDate(customerOrder.OrderDate)}
                        </Typography>
                    </Box>
                    <Box className={classnames(classes.flexColumn, classes.marginBottom)}>
                        <Receipt />
                        <Typography className={classes.capitalized}>
                            {Localization.summary}
                        </Typography>
                        <Typography>
                            {Localization.numberOfOrderLines}
                            :
                            {" "}
                            {formatAmount(customerOrder.TotalOrderLines)}
                        </Typography>
                        <Typography>
                            {Localization.totalQuantity}
                            :
                            {" "}
                            {formatAmount(customerOrder.TotalProducts)}
                        </Typography>
                        <Typography>
                            {Localization.total}
                            {" "}
                            {user?.currency}
                            :
                            {" "}
                            {formatPrice(customerOrder.TotalSum)}
                        </Typography>
                    </Box>
                    <Box className={classnames(classes.flexColumn, classes.marginBottom)}>
                        <Notes />
                        <Typography>
                            {Localization.messageToBrodit}
                            :
                            {" "}
                            {
                                customerOrder.Rows.filter(
                                    x => x.Position === null && x.Type === 4
                                )[0]?.Text
                            }
                        </Typography>
                        <Typography>
                            {Localization.purchaseOrder}
                            :
                            {" "}
                            {customerOrder.YourOrderNumber}
                        </Typography>
                        <Typography>
                            {Localization.orderPlacedBy}
                            :
                            {" "}
                            {customerOrder.YourReference}
                        </Typography>
                        <Typography>
                            {Localization.orderEmail}
                            :
                            {" "}
                            {customerOrder.Email}
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.flexColumn}>
                    <Box className={classnames(classes.flexColumn, classes.marginBottom)}>
                        <PersonOutline />
                        <Typography className={classes.capitalized}>
                            {Localization.shippingAddress}
                        </Typography>
                        {customerOrder.DeliveryAddress ? (
                            <>
                                <Typography>{customerOrder.DeliveryAddress.Row1}</Typography>
                                <Typography>{customerOrder.DeliveryAddress.Row2}</Typography>
                                <Typography>{customerOrder.DeliveryAddress.Row4}</Typography>
                                <Typography>{customerOrder.DeliveryAddress.Row5}</Typography>
                            </>
                        ) : null}
                    </Box>
                    {(!isOrderConfirmation && customerOrder.Shipments.length > 0)
                        && (
                            <Box className={classnames(classes.flexColumn, classes.marginBottom)}>
                                <LocalShippingIcon />
                                <Typography className={classes.capitalized}>
                                    {Localization.trackShipment}
                                </Typography>
                                <List>
                                    {customerOrder.Shipments.map((shipment, i) => (
                                        <ListItem
                                            key={i}
                                            classes={{
                                                gutters: classes.listItemGutters
                                            }}
                                        >
                                            <SimpleLink
                                                href={`https://tracking.postnord.com/se/?id=${shipment}`}
                                                target="_blank"
                                            >
                                                {Localization.parcel}
                                                {" "}
                                                #
                                                {i + 1}
                                                <ExternalLinkIcon
                                                    className={classes.externalLinkIcon}
                                                />
                                            </SimpleLink>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        )}
                    <Box className={classnames(classes.flexColumn, classes.marginBottom)}>
                        <CreditCard />
                        <Typography className={classes.capitalized}>
                            {Localization.billingAddress}
                        </Typography>
                        {customerOrder.InvoiceAddress ? (
                            <>
                                <Typography>{customerOrder.InvoiceAddress.Row1}</Typography>
                                <Typography>{customerOrder.InvoiceAddress.Row2}</Typography>
                                <Typography>{customerOrder.InvoiceAddress.Row4}</Typography>
                                <Typography>{customerOrder.InvoiceAddress.Row5}</Typography>
                            </>
                        ) : null}
                    </Box>
                    <Box className={classnames(classes.flexColumn, classes.marginBottom)}>
                        {isOrderConfirmation && (
                            <Button component={Link} to={`/${lang}`} color="primary" variant="contained">
                                {Localization.continueShopping}
                            </Button>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box className={classnames(classes.flexRow, classes.spaceEven)}>
                {!isOrderConfirmation && (
                    <Button color="primary" variant="contained" startIcon={<ArrowBack />} component={Link} to={`/${lang}/orders`}>
                        {Localization.backToOrderList}
                    </Button>
                )}
            </Box>
            <Box className={classes.orderLineContent}>
                <List>
                    {customerOrder.Rows.filter(x => x.Type === 1).map((row, i) => (
                        <MobileProductCardOrder
                            key={i}
                            id={row.Number}
                            price={row.UnitPrice}
                            currency={user?.currency}
                            quantity={row.Quantity}
                            deliveredQuantity={!isOrderConfirmation ? row.DeliveredQuantity : undefined}
                            isModifiable={false}
                            index={i}
                            text={findComment(row.Position)}
                        />
                    ))}
                </List>
            </Box>
        </Box>
    )
}

export default MobileOrderInfo
