import Instruction from "../components/Instruction"
import useStyles from "../styles"
import { Box } from "@mui/material"
import React from "react"

const Instructions = ({ text, images }) => {
    const classes = useStyles()
    return (
        <Box className={classes.instructionRoot} id="tabInstructions">
            {text
                && <Instruction text={text} images={images} />}
        </Box>
    )
}

export default Instructions
