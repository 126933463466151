import { useSelector } from "react-redux";

const useCompareCount = () => useSelector(state => state.compareProduct.productIds).length ?? 0

const useCompareProducts = () => useSelector(state => state.compareProduct.productIds)

const useIsCompareOverviewVisible = () => useSelector(state => state.compareProduct.showOverview)

const useIsOnComparePage = () => useSelector(state => state.compareProduct.onComparePage)

export {
    useCompareCount,
    useCompareProducts,
    useIsCompareOverviewVisible,
    useIsOnComparePage
};
